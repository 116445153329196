// utils
// import config from 'use/Config'

import { LoginService } from 'services/login.service'
import axiosInstance from './axios'

export function jwtDecode(token) {
    const base64Url = token?.split('.')[1]
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
    )

    return JSON.parse(jsonPayload)
}

// ----------------------------------------------------------------------

export const isValidToken = (token) => {
    if (!token) {
        return false
    }

    const decoded = jwtDecode(token)
    const currentTime = Date.now() / 1000
    return decoded.exp > currentTime
}

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
    let expiredTimer

    const currentTime = Date.now()

    // Test token expires after 10s
    const timeLeft = exp * 1000 - currentTime

    clearTimeout(expiredTimer)

    expiredTimer = setTimeout(async () => {
        try {
            const refreshToken = localStorage.getItem('refreshToken')
            const accessToken = localStorage.getItem('token')
            const { data: refreshResponse } = await LoginService.refreshToken({ accessToken, refreshToken })
            setSession(refreshResponse.token, refreshResponse.refreshToken)
        } catch (error) {
            localStorage.removeItem('token')
            localStorage.removeItem('isWebsite')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('email')
            localStorage.clear()
            window.location.href = 'login'
        }
    }, timeLeft)
}

// ----------------------------------------------------------------------

export const setSession = (token, refreshToken) => {
    if (token) {
        localStorage.setItem('isWebsite', true)
        localStorage.setItem('token', token)
        localStorage.setItem('refreshToken', refreshToken)
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
        const { exp } = jwtDecode(token)
        tokenExpired(exp)
    } else {
        localStorage.removeItem('isWebsite')
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')

        delete axiosInstance.defaults.headers.common.Authorization
    }
}
