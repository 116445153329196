import config from 'use/Config'
import mrcFetch from 'util/authFetch'
export function addPatientToEpic(code, state) {
    // const search = window.location.search
    // const params = new URLSearchParams(search)
    // const code = params.get('code')
    // const ehrhospital = params.get('state')
    const profileId = localStorage.getItem('selectedProfileId')
    if (!profileId) return
    let raw = JSON.stringify({
        token: code,
        // patientId: patientId,
        // ProfileId: localStorage.getItem('profileid'),
        ProfileId: profileId,
        EHRHospital: state,
    })

    let requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: raw,
        redirect: 'follow',
    }
    return mrcFetch(`${config.mrc.baseApiUrl}/EpicEHR/ProcessEpicData`, requestOptions)
        .then((response) => response.text())
        .then((result) => localStorage.removeItem('selectedProfileId'))
    // .catch((error) => console.log('error', error))
}
