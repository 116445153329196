import axiosInstance from 'util/axios'
export const DependentService = {
    getAllDependents: async () => {
        try {
            const response = await axiosInstance.get(`/Dependent/get-dependents`)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    getDependent: async (dependentId) => {
        try {
            const response = await axiosInstance.get(`/Dependent/get-dependent?dependentId=${dependentId}`)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    addDependent: async (body) => {
        try {
            const response = await axiosInstance.post(`/Dependent/add-dependent`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    updateDependent: async (body) => {
        try {
            const response = await axiosInstance.put(`/Dependent/update-dependent`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    removeDependent: async (dependentId) => {
        try {
            const response = await axiosInstance.delete(`/Dependent/remove-dependent?dependentId=${dependentId}`)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
}
