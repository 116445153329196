import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import Loader from 'components/Loader/Loader'
import SideBar from 'components/layout/SideBar/SideBar'
import { GetProviderByProfileIdAPI } from 'services/Services'
import shareIcon from 'assets/icons/shareIcon.png';
import maleIcon from 'assets/profiles/male.png';
import './Appointments.scss'

class Appointments extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            value: [],
        }
    }

    sideClose = () => {
        this.setState({ size: false })
    }

    sideOpen = () => {
        this.setState({ size: true })
    }

    goToThisPage = async (item, pid) => {
        this.props.history.push(`/home/${item}/${pid}`)
        let profileid = localStorage.getItem('profileid')
        await GetProviderByProfileIdAPI(profileid)
    }

    componentDidMount() {
        this.getProviderByProfileId()
    }

    getProviderByProfileId = async () => {
        let profileid = localStorage.getItem('profileid')
        this.setState({ ProviderName: this.props.match.params.pname })
        this.setState({ ProviderId: this.props.match.params.pid })
        const data = await GetProviderByProfileIdAPI(profileid)
        if (data) {
            const value = data
            if (value.message === 'No Data Found') {
                await this.setState({ value: [], loading: false })
            } else {
                await this.setState({ value: data[0], loading: false })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <div className="_container">
                        <SideBar title="Appointments" />
                        <div className="container-right">
                            <div className="main-title">Appointments</div>
                            <div className="white-container">
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="_container appointments">
                <SideBar title="appointments" />
                <div className="container-right">
                    <div className="main-title">Appointments</div>
                    <div className="white-container">
                        <Grid container spacing={3}>
                            {this.state.value && this.state.value.length > 0
                                ? this.state.value.map((item, i) => {
                                      return (
                                          <Grid item xs={3}>
                                              <Paper
                                                  className="hand"
                                                  onClick={() => {
                                                      this.sideClose()
                                                      this.goToThisPage('jason', item.providerId)
                                                  }}
                                              >
                                                  <div
                                                      className="flex"
                                                      style={{
                                                          alignItems: 'center',
                                                      }}
                                                  >
                                                      <img
                                                          src={maleIcon}
                                                          className="profile-pic"
                                                          alt="profile-pic"
                                                      />
                                                      <div
                                                          style={{
                                                              marginLeft: 10,
                                                          }}
                                                      >
                                                          <h1 className="profile-name"> {item.providerName}</h1>
                                                          <h4 className="category-name"> {item.providerType}</h4>
                                                      </div>
                                                  </div>
                                                  <div
                                                      style={{
                                                          display: 'flex',
                                                          justifyContent: 'space-between',
                                                          alignItems: 'center',
                                                          marginTop: 10,
                                                      }}
                                                  >
                                                      <div>
                                                          <h1 className="profile-name"> Address</h1>
                                                          <h1 className="category-name"> {item.providerAddress}</h1>
                                                      </div>
                                                      <img
                                                          src={shareIcon}
                                                          alt="provider"
                                                          style={{
                                                              width: 40,
                                                              height: 40,
                                                              position: 'relative',
                                                              top: 20,
                                                              left: 20,
                                                              resizeMode: 'cover',
                                                          }}
                                                      />
                                                  </div>
                                              </Paper>
                                          </Grid>
                                      )
                                  })
                                : null}
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}

export default Appointments
