import React from 'react'
import clsx from 'clsx'

const Wrapper = ({ columns = 3, children, textCenter = true }) => {
    return (
        <div className={clsx(`col-lg-${columns}`, { 'text-center': textCenter })} style={{paddingLeft:0}}>
            <div className="my-2 mx-auto">{children && children}</div>
        </div>
    )
}

export default Wrapper
