import axiosInstance from 'util/axios'
export const LoginService = {
    signup: async (body) => {
        try {
            const response = await axiosInstance.post(`/Login/UserRegister`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },

    login: async (body) => {
        try {
            const response = await axiosInstance.post(`/Login/login`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },

    refreshToken: async (body) => {
        try {
            const response = await axiosInstance.post(`/Login/refresh`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },

    confirmationCode: async (body) => {
        try {
            const response = await axiosInstance.post(`/Login/confirm-user`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },

    resendVerificationCode: async (email) => {
        try {
            const response = await axiosInstance.post(`/Login/resend-verification-code?email=${email}`)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },

    forgotPassword: async (email) => {
        try {
            const response = await axiosInstance.get(`/Login/forgot-password?email=${email}`)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },

    changePassword: async (body) => {
        try {
            const response = await axiosInstance.post(`/Login/change-forgot-password`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },

    getUser: async (email) => {
        if (!email) return
        try {
            if (!axiosInstance.defaults.headers.common.Authorization) {
                const token = localStorage.getItem('token')
                axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
            }
            const response = await axiosInstance.get(`/Login/Getuser?email=${email}`)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    deleteAccount: async () => {
        try {
            if (!axiosInstance.defaults.headers.common.Authorization) {
                const token = localStorage.getItem('token')
                axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
            }
            const response = await axiosInstance.get(`/Profile/delete-notification`)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
}
