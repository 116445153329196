import React from 'react'

function PrivacyPolicy() {
    return (
        <div className="scrollableContent">
            <div>
                <b>Introduction</b>
                <p>
                    MatchRite Care, LLC (“MatchRite,” we,” “our,” or “us”) respects the privacy of your information.
                    This Privacy Policy is designed to assist you in understanding how we collect, use, and safeguard
                    your information. This Privacy Policy applies to individuals who access this website and our mobile
                    application (collectively, “Sites”) and any of our online, and where required, offline services
                    (collectively, “Services”).
                </p>
                <p>
                    From time to time, we may change this Privacy Policy. If we do, we will post an amended version on
                    this webpage. Please review this Privacy Policy periodically. In addition, following a change to
                    this Privacy Policy, we will opt-in all users (including all Medicare beneficiaries) to receive the
                    updated Privacy Policy via their email of choice or a pop-up screen within our platform.
                </p>
                <p>
                    For more information as to our use of cookies and other tracking technologies, please see our Cookie
                    Policy.
                </p>
                <p>
                    THIS SERVICE IS OFFERED FROM THE UNITED STATES (“US”). For individuals located outside of the US,
                    please note that MatchRite is a US based company. If you use the Services, all information,
                    including personal information, will be transferred to MatchRite in the US and as described in this
                    Privacy Policy.
                </p>
                <b>Note on HIPAA</b>
                <p>
                    As part of engaging with the Services, you may engage with the Services in a manner regulated by the
                    Health Insurance Portability and Accountability Act (“HIPAA”), such as by providing protected health
                    information (“PHI”) through the Services. This Privacy Policy is not intended for compliance with
                    HIPAA, nor should you take such notice as a reflection of your rights under HIPAA. If you are a
                    patient reviewing this Privacy Notice, please contact your provider for information regarding your
                    medical records and/or HIPAA privacy disclosures.
                </p>
                <p>
                    In certain circumstances, MatchRite is considered a business associate as that term is defined under
                    HIPAA. In such circumstances, MatchRite enters into a business associate agreement (“BAA”) with
                    covered entities which outlines its compliance obligations under HIPAA.
                </p>
                <p>This Privacy Policy covers the following topics:</p>
                <ol className='policyList'>
                    <li>
                        <a href="#1">Personal Data MatchRite Collects</a>
                    </li>
                    <li>
                        <a href="#2">How MatchRite Uses Your Personal Data</a>
                    </li>
                    <li>
                        <a href="#3">How MatchRite Shares Your Personal Data</a>
                    </li>
                    <li>
                        <a href="#4">Consent Practices</a>
                    </li>
                    <li>
                        <a href="#5">International Data Transfers</a>
                    </li>
                    <li>
                        <a href="#6">Universal Opt-Out Mechanisms and “Do Not Track” Signals</a>
                    </li>
                    <li>
                        <a href="#7">Security</a>
                    </li>
                    <li>
                        <a href="#8">Third Party Links</a>
                    </li>
                    <li>
                        <a href="#9">Children’s Privacy</a>
                    </li>
                    <li>
                        <a href="#10">Your Personal Data and Your Rights – Europe and United Kingdom Only</a>
                    </li>
                    <li>
                        <a href="#11">Your Personal Data and Your Rights – United States Only</a>
                    </li>
                    <li>
                        <a href="#12">Retentions</a>
                    </li>
                    <li>
                        <a href="#13">Accessibility</a>
                    </li>
                    <li>
                        <a href="#14">How to Contact Us</a>
                    </li>
                </ol>
            </div>
            <div id="1">
                <u>1. Personal Data MatchRite Collects</u>
                <p>
                    We collect personal data from you through your use of the Sites and Services. Personal data is
                    information that is linked or reasonably linkable to an identified or identifiable individual. We
                    collect the following types of personal data:
                </p>
                <p>
                    Personal Data You Provide
                    <p>
                        Depending on how you interact with our Sites and Services, we will collect the following
                        personal data that you voluntarily provide to us for the following purposes:
                    </p>
                </p>
                <ul>
                    <li style={{ listStyle: 'square' }}>
                        Register as a Patient. If you register as a patient, you will provide us with your name, email
                        address, veteran status, provider history, case management details, medical records, and other
                        health data you choose to provide.
                    </li>
                    <li style={{ listStyle: 'square' }}>Compensating You. When you elect to provide information</li>
                    <li style={{ listStyle: 'square' }}>
                        Request a Provider Demo. If you are a provider and request a demo, you will provide us with your
                        organization name, your name, email address, phone number, and any other information you choose
                        to provide.
                    </li>
                    <li style={{ listStyle: 'square' }}>
                        Request a Tribal or Government Demo. If you submit a request for a tribal or governmental demo,
                        you will provide us with information about your tribe, agency, or organization, your name, email
                        address, phone number, and any other information you choose to provide.
                    </li>
                    <li style={{ listStyle: 'square' }}>
                        Request a Health Plan Demo. If you submit a request for a health plan demo, you will provide us
                        with your name, email address, phone number, and any other information you choose to provide.
                    </li>
                    <li style={{ listStyle: 'square' }}>
                        Request a Research Demo. If you submit a request for a research demo, you may submit your name,
                        email address, phone number, and any other information you choose to provide.
                    </li>
                    <li style={{ listStyle: 'square' }}>
                        Login to Your Health Plan Research Kit. To access your Research Kit content, you will provide
                        your secure login credentials.
                    </li>
                    <li style={{ listStyle: 'square' }}>
                        Submit an Interview Request. To submit an interview request, you will provide your name,
                        organization (if applicable), title within your organization, phone number, email address, and
                        information about the interview.
                    </li>
                    <li style={{ listStyle: 'square' }}>
                        Submit a Speaker Request. To submit a speaker request, you will provide your name, phone number,
                        email address, organization (if applicable), title within your organization, information about
                        event and speaking engagement.
                    </li>
                    <li style={{ listStyle: 'square' }}>
                        Opt-in to Research. To receive invitations from providers seeking medical records for research,
                        you will provide us with your name, email address, and medical records relevant to each
                        respective study.
                    </li>
                    <li style={{ listStyle: 'square' }}>
                        Contacting You. So that we can contact you regarding the Sites and Services, you may provide us
                        with your email address.
                    </li>
                </ul>
                <p>
                    Certain jurisdictions differentiate special categories or sensitive data as personal information
                    requiring more protection and scrutiny. Given the nature of the Services, personal information you
                    provide may be considered sensitive or included in a special category of data depending on your
                    jurisdiction. MatchRite will not collect special categories of data from you unless you have
                    explicitly consented to provide that personal data to us. You may withdraw such consent at any time.
                    Our consent process is described in more detail in Section 5. Consent Practices below.
                </p>
                <p>
                    In certain circumstances and if you have a patient or research account, you may use your account to
                    access, correct, or view certain personal data we have collected, and which is associated with your
                    account. Where you are unable to undergo such activities through your account or if you do not have
                    a patient or research account, you may contact us at{' '}
                    <a href="mailto:privacy@matchritecare.com">privacy@matchritecare.com</a>.
                </p>
                <p>Personal Data as You Navigate the Sites</p>
                <p>
                    Companies frequently automatically collect certain personal data through your use of websites and a
                    website’s use of cookies and other tracking technologies. Such information can include the
                    computer's IP address, domain name (e.g., .com, .gov, or .edu), software details, and information on
                    pages visited is often saved in cookies so that the organization may develop and store user profiles
                    of website visitors. If a website uses cookies, the organization may be able to collect even more
                    information, such as your browsing patterns, which include other sites you've visited. When you use
                    the Sites, we may also use cookies or similar technology to collect extra data, including:
                </p>
                <ul>
                    <li style={{ listStyle: 'square' }}>
                        Your IP address, which is a numerical code to identify your device, together with the country,
                        region, or city where you are based.
                    </li>
                    <li style={{ listStyle: 'square' }}>
                        Your geolocation data. Your IP address can be used to find information about the latitude,
                        longitude, altitude of your device, its direction of travel, your GPS data, and data about
                        connection with local Wi-Fi equipment.
                    </li>
                    <li style={{ listStyle: 'square' }}>
                        Information on how you interact with our services. For example, the pages on the Sites you
                        access, the frequency of access, and what you click on while on the Sites.
                    </li>
                    <li style={{ listStyle: 'square' }}>
                        Your browsing history of the content you have visited on the Sites, including how you were
                        referred to the Sites via other websites.
                    </li>
                    <li style={{ listStyle: 'square' }}>
                        Details of your computer, mobile, TV, tablet, or other devices. For example, the unique device
                        ID, unique vendor or advertising ID and browsers used to access our content.
                    </li>
                </ul>
                <p>
                    To learn more about how we use cookies and to manage your cookie settings, please see our Cookie
                    Policy.
                    <p>
                        Personal Data We Collect About You from Other Sources
                        <p>
                            In some cases, we may receive personal data about you from other sources. This includes
                            government entities, advertising networks, data brokers, operating systems and platforms,
                            mailing list providers, social networks, and advertising and marketing partners.
                        </p>
                    </p>
                </p>
            </div>
            <div id="2">
                <u>2. How MatchRite Uses Your Personal Data</u>
                <p>
                    In addition to the purposes stated above, we may use all the personal data we collect in accordance
                    with applicable law such as to:
                    <ul>
                        <li style={{ listStyle: 'square' }}>Provide the Sites and Services to you;</li>
                        <li style={{ listStyle: 'square' }}>
                            Provide certain, specific Services to you, as enumerated in this Privacy Notice or in other
                            places on the Site/Services;
                        </li>
                        <li style={{ listStyle: 'square' }}>
                            Process transactions or as may be necessary to support the operation of the Service, such as
                            for billing, account maintenance, and record-keeping purposes;
                        </li>
                        <li style={{ listStyle: 'square' }}>Respond to your requests or inquiries;</li>
                        <li style={{ listStyle: 'square' }}>
                            Distribute communications relevant to your use of the Service, such as system updates or
                            information about your use of the Service;
                        </li>
                        <li style={{ listStyle: 'square' }}>
                            Maintain and improve our Sites and Services with customers or vendors;
                        </li>
                        <li style={{ listStyle: 'square' }}>
                            Protect the security and integrity of our Sites and Services;
                        </li>
                        <li style={{ listStyle: 'square' }}>
                            Investigate, prevent, or take action regarding illegal activities, suspected fraud,
                            situations involving potential threats to the physical safety of any person, or violations
                            of our Terms of Use, and to otherwise fulfill our legal obligations;
                        </li>
                        <li style={{ listStyle: 'square' }}>
                            Monitor compliance with and enforce this Privacy Policy and any applicable agreements and
                            policies;
                        </li>
                        <li style={{ listStyle: 'square' }}>Defend our legal rights and the rights of others;</li>
                        <li style={{ listStyle: 'square' }}>Fulfill any other purposes for which you provide it;</li>
                    </ul>
                    For any purpose that is reasonably necessary to or compatible with the original purpose for which we
                    collected the personal data as disclosed to you; and Comply with applicable law.
                </p>
            </div>
            <div id="3">
                <u>3. How does MatchRite Share your Data with Third Party Processors</u>
                <p>
                    We may provide any of the described categories of Personal Data to MatchRite employees, consultants,
                    affiliates or other businesses or persons for the purpose of processing such information on our
                    behalf in order to provide the Service to you. To ensure that your Personal Data receives an
                    adequate level of protection, we have put in place appropriate procedures with the or third parties
                    service providers we share your Personal Data with to ensure that your Personal Data is treated by
                    those service providers in a way that is consistent with and which respects the applicable laws on
                    data security and privacy. Third parties receiving personal information may include:
                    <p>Our affiliates and related companies;</p>
                    <p>
                        Business partners, contractors, and channel partners, which are not considered affiliated
                        companies.
                        <p>
                            Third-party vendors or service providers providing crucial services and necessary to provide
                            the Service;
                            <p>
                                Third-party consultants for patient engagement, customer management platforms Certain
                                third parties as described in Cookie Notice;
                                <p>
                                    Government agencies or other parties in response to legal process or other requests,
                                    where permitted by law;
                                    <p>
                                        With other persons or third parties with whom you have requested that we share
                                        information.
                                    </p>
                                </p>
                            </p>
                        </p>
                    </p>
                    <p>
                        Except as described above, we will not share your personal information with other, third-party
                        companies for their commercial or marketing use without your consent or except as part of a
                        specific program or feature for which you have the ability to opt-in or opt-out.
                    </p>
                    <p>
                        In addition, we may release personal information:
                        <ul>
                            <li style={{ listStyle: 'none' }}>
                                (i) to the extent we have a good-faith belief that such action is necessary to comply
                                with any applicable law;
                            </li>
                            <li style={{ listStyle: 'none' }}>
                                (ii) to enforce any provision of the Terms of Use, protect ourselves against any
                                liability, defend ourselves against any claims, protect the rights, property and
                                personal safety of any user, or protect the public welfare;
                            </li>
                            <li style={{ listStyle: 'none' }}>
                                (iii) when disclosure is required to maintain the security and integrity of the Service,
                                or to protect any user’s security or the security of other persons, consistent with
                                applicable laws
                            </li>
                            <li style={{ listStyle: 'none' }}>
                                (iv) to respond to a court order, subpoena, search warrant, or other legal process, to
                                the extent permitted and as restricted by law;
                            </li>
                            <li style={{ listStyle: 'none' }}>
                                (v) in the event that we go through a business transition, such as a merger,
                                divestiture, acquisition, liquidation or sale of all or a portion of our assets; or
                            </li>
                            <li style={{ listStyle: 'none' }}>
                                (vi) for an additional purpose that is directly related to the original purpose for
                                which the personal data was collected.
                            </li>
                        </ul>
                    </p>
                </p>
            </div>
            <div id="4">
                <u>4. Consent Practices</u>
                <p>
                    Sensitive Data or Special Categories
                    <p>
                        Some of the Personal Data we collect may be considered sensitive or fall under special
                        categories depending on your jurisdiction. For example, medical information you provide when you
                        register with the Services as a patient, or your status as a veteran, may be considered
                        sensitive. Prior to providing this type of information, you will be prompted to consent to our
                        collection and subsequent processing of such information. Patients will be prompted with such
                        consent at the point of login to their secure account. Where you have provided us with consent
                        to use your sensitive Personal Data, you can withdraw such consent at any time.
                        <p>
                            Where applicable, providers are expected to execute their own consent practices. Please
                            contact your provider(s) with questions about their privacy consent practices.
                        </p>
                    </p>
                    <p>
                        Selling and Sharing Activity
                        <p>
                            The nature of the Services involves certain processing activities that may be considered a
                            “sale” or a “share” under certain privacy laws. We do not sell, rent, or otherwise disclose
                            personal information collected by the Sites and Services to third parties in the ordinary
                            course of business, unless we have obtained your consent and authorization to do so. You may
                            withdraw your consent for selling and sharing at any time.
                        </p>
                    </p>
                    <p>
                        Marketing Information
                        <p>
                            We engage a third-party marketing provider. Our marketing provider may retain your personal
                            information to keep you apprised of recent product developments or new product and service
                            offerings. Any personal information you provide will be stored on a secure, internal server
                            accessible only by the marketing provider. We will not sell or share this information with
                            any other company.
                        </p>
                        <p>
                            In order for us to send direct marketing communications, and other related profiling that
                            helps us offer you relevant products and services, including deciding whether to offer you
                            certain products and services, we will collect your consent prior to sending you marketing
                            communications consistent with applicable law in your jurisdiction. We will send marketing
                            communications to you by SMS, email, phone, post, social media and other digital channels
                            (for example, using Facebook Custom Audiences and Google Custom Match). At any time, you may
                            request that your personal information be removed from our marketing records, at which point
                            you will receive no further marketing communications from us. You can exercise your right to
                            opt-out of these marketing communications in a variety of ways. For example, if you would
                            like to opt-out of emails, you may do so through the “unsubscribe” link included in each of
                            our marketing emails. If you would like to opt-out of SMS marketing communications, you can
                            reply “STOP” to any SMS you receive from us. You may also contact us as described herein.
                            When you unsubscribe from email marketing communications, please note that we will keep your
                            email address to ensure that we do not send you any marketing in future.
                        </p>
                    </p>
                </p>
            </div>
            <div id="5">
                <u>5. International Data Transfers</u>
                <p>
                    We operate internationally and transfer information to the United States for the purposes described
                    in this policy. The United States may have privacy and data protection laws that differ from, and
                    are potentially less protective than, the laws of your country. Your Personal Data can be subject to
                    access requests from governments, courts, or law enforcement in the United States according to the
                    laws of the United States.
                    <p>
                        For any transfers of Personal Data from the European Economic Area (EEA), Switzerland or the
                        United Kingdom that we make to other entities as described in this Privacy Policy, we use
                        appropriate safeguards to ensure for the lawful processing and transfer of the Personal Data,
                        including, when appropriate, the use of standard contractual clauses approved by the European
                        Commission.
                    </p>
                </p>
            </div>
            <div id="6">
                <u>6. Universal Opt-Out Mechanisms and “Do Not Track” Signals</u>
                <p>
                    The Sites recognize the Global Privacy Control (“GPC”) signal. If you are using a browser setting or
                    plug-in that sends an opt-out preference signal to each website you visit, we will treat that as a
                    valid request to opt out. To download and use a browser supporting the GPC browser signal, click
                    here:{' '}
                    <a href="https://globalprivacycontrol.org/orgs" target="_blank" rel="noreferrer">
                        https://globalprivacycontrol.org/orgs
                    </a>
                    . If you choose to use the GPC signal, you will need to turn it on for each supported browser or
                    browser extension you use.
                    <p>
                        Some internet browsers incorporate a “Do Not Track” feature that signals to websites you visit
                        that you do not want to have your online activity tracked. Given that there is not a uniform way
                        that browsers communicate the “Do Not Track” signal, the Sites do not currently interpret,
                        respond to or alter their practices when they receive/it receives “Do Not Track” signals.
                    </p>
                </p>
            </div>
            <div id="7">
                <u>7. Security</u>
                <p>
                    We maintain security measures to protect the personal data we collect and store from loss, misuse,
                    destruction, or unauthorized access. However, no security measure or modality of data transmission
                    over the Internet is 100% secure. Although we strive to use commercially acceptable means to protect
                    your personal data, we cannot guarantee absolute security. We are not responsible for any
                    interception or interruption of any communications through the internet or for changes or
                    unauthorized access to or losses or unauthorized use of data.
                    <p>
                        Users of the Service are responsible for maintaining the security of any password, username, or
                        other form of authentication involved in obtaining access to password protected or secure areas
                        of the Service. In order to protect you and your information, we may suspend your use of any of
                        the Service, without notice, pending an investigation, if any breach of security is suspected.
                    </p>
                </p>
            </div>
            <div id="8">
                <u>8. Third Party Links</u>
                <p>
                    The Sites and Services may contain links that will let you leave the Sites and access another
                    website. For example, you may access your Veteran’s Affairs (VA) account or your Medicare account
                    through the Sites. The Sites also contain links to social media platforms, such as LinkedIn,
                    Facebook, and Twitter. Linked websites are not under our control. We accept no responsibility or
                    liability for these other websites.
                    <p>
                        In addition, we may allow other companies, called third-party ad servers or ad networks, to
                        serve advertisements within the Service. These third-party ad servers or ad networks use
                        technology to send, directly to your device, the advertisements and links that appear on the
                        Service. They automatically receive your device ID and IP address when this happens. They may
                        also use other technologies (such as cookies, JavaScript, or Web Beacons) to measure the
                        effectiveness of their advertisements and to personalize the advertising content you see. You
                        should consult the respective privacy policies of these third-party ad servers or ad networks
                        for more information on their practices and for instructions on how to opt-out of certain
                        practices. This Privacy Notice does not apply to them, and we cannot control their activities.
                    </p>
                </p>
            </div>
            <div id="9">
                <u>9. Children’s Privacy</u>
                <p>
                    The Sites and Services are not intended for children under 16 years of age. We do not knowingly
                    collect, use, or disclose personal data from children under 16. Any information MatchRite may obtain
                    on minors through the Site and Services is collected from parents and/or guardians and is processed
                    consistent with this Privacy Policy and applicable legislation.
                </p>
            </div>
            <div id="10">
                <u>10. Your Personal Data and Your Rights – Europe and the United Kingdom Only</u>
                <p>
                    <p>
                        If you are in a country in the European Economic Area (EEA) or in the United Kingdom, you are
                        entitled to the following explanation of the legal bases we rely on to process your Personal
                        Data and a description of your privacy rights.
                    </p>
                    <b>Legal Bases for Processing Your Personal Data</b>
                    <p>
                        The legal basis for collecting and using the Personal Data described above will depend on the
                        Personal Data concerned and the specific context in which we collect it. Possible legal bases
                        include:
                    </p>
                    <ul>
                        <li style={{ listStyle: 'none' }}>Consent</li>
                        <li style={{ listStyle: 'none' }}>Legitimate Interests</li>
                        <li style={{ listStyle: 'none' }}>To Perform a Contract</li>
                        <li style={{ listStyle: 'none' }}>To Enable Us to Comply with a Legal Obligation</li>
                        <li style={{ listStyle: 'none' }}>Necessary for the Exercise or Defense of Legal Claims</li>
                    </ul>
                    <p>
                        If you have any questions about or need further information concerning the legal basis on which
                        we collect and use your Personal Data for any specific processing activity, please contact us
                        using the “How to Contact Us” section herein.
                    </p>
                    <b>Your Rights</b>
                    <ul style={{ listStyleType: '- ' }}>
                        <li style={{ listStyle: 'none' }}>Access, update, or delete your Personal Data.</li>
                        <li style={{ listStyle: 'none' }}>Rectify Your Personal Data.</li>
                        <li style={{ listStyle: 'none' }}>
                            Restrict Our Use of Your Personal Data. You have the right to ask us to place a restriction
                            on our use of your Personal Data if one of the following applies to you:
                            <ul>
                                <li style={{ listStyle: 'decimal' }}>
                                    You contest the accuracy of the information that we hold about you, while we verify
                                    its accuracy
                                </li>
                                <li style={{ listStyle: 'decimal' }}>
                                    We have used your information unlawfully, but you request us to restrict its use
                                    instead of erasing it{' '}
                                </li>
                                <li style={{ listStyle: 'decimal' }}>
                                    We no longer need the information for the purpose for which we collected it, but you
                                    need it to deal with a legal claim; or
                                </li>
                                <li style={{ listStyle: 'decimal' }}>
                                    You have objected to us using your information, while we check whether our
                                    legitimate grounds override your right to object.
                                </li>
                            </ul>
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Object to Our Use of Your Personal Data. You have the right to object to our use of your
                            Personal Data where our reason for using it is based on our legitimate interests or your
                            consent (rather than when the reason for using it is to perform an obligation due to you
                            under a contract with us).
                        </li>
                        <li style={{ listStyle: 'none' }}>Withdraw Consent.</li>
                    </ul>
                    <p>
                        Please note, even if you make a request for deletion, we may need to retain certain information
                        for legal or administrative purposes, such as record keeping, maintenance of opt-out
                        requirements, defending or making legal claims, or detecting fraudulent activities. We will
                        retain information in accordance with the “How Long Is Your Personal Data Kept” section below.
                        <p>
                            If you do exercise a valid right to have your Personal Data deleted, please keep in mind
                            that deletion by third parties to whom the information has been provided might not be
                            immediate and that the deleted information may persist in backup copies for a reasonable
                            period (but will not be available to others).
                        </p>
                        <p>
                            To exercise any of these rights, please contact us as described in the “How to Contact Us”
                            section below.
                        </p>
                        <b>Make a Complaint</b>
                        <p>
                            If you have any concerns or complaints regarding our processing of your Personal Data,
                            please contact us as described in the “How to Contact Us” section below and we will do our
                            best to answer any question and resolve any complaint to your satisfaction.
                        </p>
                        <p>
                            If, for whatever reason, you feel we do not meet the standards you expect of us, you are
                            also entitled to make a complaint to your local supervisory authority:
                        </p>
                        <ul>
                            <li style={{ listStyle: 'none' }}>EU Data Protection Authorities (DPAs)</li>
                            <li style={{ listStyle: 'none' }}>
                                Swiss Federal Data Protection and Information Commissioner (FDPIC)
                            </li>
                            <li style={{ listStyle: 'none' }}>Information Commissioner’s Office (United Kingdom)</li>
                        </ul>
                        <b>How Long Is Your Personal Data Kept?</b>
                        <p>
                            We will retain your Personal Data for as long as necessary to fulfill the purposes for which
                            we collect it and as set out in this Privacy Policy and for the purpose of satisfying any
                            legal, accounting, or reporting requirements that apply to us.
                        </p>
                    </p>
                </p>
            </div>

            <div id="11">
                <u>11. Your Personal Data and Your Rights – United States Only</u>
                <p>
                    United States Privacy Laws
                    <p>
                        Depending on the state in which you reside, your state may have privacy legislation that
                        regulates how certain businesses collect and process your personal information. However,
                        MatchRite is not currently subject to such United States privacy laws due to MatchRite’s size
                        and revenue. Further, certain processing activity that MatchRite conducts would be subject to
                        exemptions (such as for HIPAA-regulated data) to the extent United States privacy laws do apply.
                        By using the Services, you acknowledge that MatchRite may not be subject the particular privacy
                        law in your state or jurisdiction. By using the service, you further acknowledge that you may
                        not be able to avail yourself of all privacy rights under the privacy laws in your state.
                    </p>
                    <p>Notice to Nevada Residents</p>
                    <p>
                        Nevada law allows Nevada residents to opt-out of the sale of certain types of personal
                        information. Subject to a number of exceptions, Nevada law defines “sale” to mean the exchange
                        of certain types of personal information for monetary consideration to another person. If you
                        are a Nevada resident and our processing of your Personal Data constitutes a sale under Nevada
                        law, you may submit a verified request to opt-out of such sales. Opt-out requests may be sent to
                        privacy@matchrite.com.
                    </p>
                    <p>Notice to California Residents</p>
                    <p>Shine the Light Law</p>
                    <p>
                        We do not disclose personal information obtained through our Sites or Services to third parties
                        for their direct marketing purposes. Accordingly, we have no obligations under California Civil
                        Code § 1798.83.]
                    </p>
                </p>
            </div>
            <div id="12">
                <u>12. Retention</u>
                <p>
                    We retain your Personal Information for as long as necessary to fulfill the purposes for which we
                    collect it, such as to provide you with the service you have requested, and for the purpose of
                    satisfying any legal, accounting, contractual, or reporting requirements that apply to us. For all
                    data and records obtained, used, and stored by us, we:
                    <br />
                    <br />
                    <p>
                        Carry out periodical reviews of the data retained, checking purpose, continued validity,
                        accuracy, and requirement to retain.
                        <p>
                            Establish periodical reviews of data retained.
                            <p>
                                Establish and verify retention periods for the data, with special consideration given to
                                the requirements of the Company, the type of personal data the purpose of processing,
                                the lawful basis for processing, and the categories of data subjects.
                            </p>
                        </p>
                    </p>
                </p>
            </div>
            <div id="13">
                <u>13. Accessibility</u>
                <p>
                    We are committed to ensuring this Privacy Policy is accessible to individuals with disabilities. If
                    you wish to access this Privacy Policy in an alternative format, please contact us as described
                    below.
                </p>
            </div>
            <div id="14">
                <u>14. How to Contact Us.</u>
                <p>
                    You may contact us with questions about this Privacy Policy via email to{' '}
                    <a href="mailto:privacy@matchritecare.com">privacy@matchritecare.com</a>.
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy
