import config from 'use/Config'
import mrcFetch from 'util/authFetch'

export function eclinicalPatientToken(EHRClientId, EHRGetTokenUrl) {
    let tokenFetchUrl = ''
    let HospitalClientId = ''
    const search = window.location.search
    const params = new URLSearchParams(search)
    const code = params.get('code')
    const state_hospital = params.get('state') || localStorage.getItem('EHREndPointId')

    tokenFetchUrl = EHRGetTokenUrl
    HospitalClientId = EHRClientId

    console.log({ tokenFetchUrl })
    console.log({ HospitalClientId })

    let datatok = code
    let patient = '123'
    let ehrhospital = state_hospital
    return addPatientToEClinical(datatok, patient, ehrhospital)
}

// export function addPatientToEClinical(token, patientId, ehrhospital) {
export function addPatientToEClinical(code, state) {
    // const search = window.location.search
    // const params = new URLSearchParams(search)
    // const token = params.get('code')
    // const ehrhospital = params.get('state')
    const raw = JSON.stringify({
        token: code,
        // patientId: patientId,
        // ProfileId: localStorage.getItem('profileid'),
        ProfileId: localStorage.getItem('selectedProfileId'),
        EHRHospital: state,
    })

    let requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: raw,
        redirect: 'follow',
    }
    return mrcFetch(`${config.mrc.baseApiUrl}/EclinicalEHR/ProcessEclinicalData`, requestOptions)
        .then((response) => response.text())
        .then((result) => localStorage.removeItem('selectedProfileId'))
    // .catch((error) => console.log('error', error))
}
