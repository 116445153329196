import React from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'inline-block'
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  // fontSize: '1.50rem',
  marginBottom: 8,
  // color: theme.palette.primary.darker,
  color: '#143650',
  fontFamily: "Montserrat",
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '35px',
  fontStyle: 'normal',
  // textAlign: left;
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: '0 0 8px',
  borderColor: theme.palette.grey[600],
  opacity: 1,
}));

const FormGroupHeader = ({ children, ...other }) => {
  return (
    <StyledBox {...other}>
      <StyledTypography>
        {children}
      </StyledTypography>
      <StyledDivider />
      {/* <StyledDivider sx={{ width: '80px' }} /> */}
    </StyledBox>
  )
}

export default FormGroupHeader
