import config from 'use/Config'
import axios from 'axios'
import axiosInstance from 'util/axios'

export async function veteranAuthenticationToken(code, state) {
    const url = `${config.veteranCredentials.baseSandboxUrl}oauth2/veteran-verification/v1/token`
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    const auth = {
        username: config.veteranCredentials.clientId,
        password: config.veteranCredentials.stateCode,
    }
    const params = new URLSearchParams()
    params.append('grant_type', 'authorization_code')
    // params.append('client_id', config.veteranCredentials.clientId)
    params.append('code_verifier', config.veteranCredentials.codeVerifier)
    params.append('code', code)
    params.append('redirect_uri', config.veteranCredentials.redirectUrl)
    try {
        const { status, data } = await axios.post(url, params, {
            headers,
            auth,
        })
        if (status === 200) {
            localStorage.setItem('veteran-token', data.access_token)
            return getVeteranRecord(data.access_token)
        } else {
            // console.log("status", status);
            // console.log("data", data);
            localStorage.removeItem('veteran-token')
        }
    } catch (error) {
        console.error(error)
        localStorage.removeItem('veteran-token')
    }
}
const verificationHeaders = (token) => {
    return {
        Authorization: `Bearer ${token}`,
    }
}

// Either returns veteran status from veteran verification api or removes access token from local storage
const getVeteranStatus = async (token) => {
    const url = `${config.veteranCredentials.baseSandboxUrl}${config.veteranCredentials.veteranVerificationPrefix}/status`
    try {
        const { status, data } = await axios.get(url, {
            headers: verificationHeaders(token),
        })
        if (status === 200) {
            return data.data.attributes
            // setVeteranStatus(
            //     `Confirmation Status: ${
            //         data.data.attributes.veteran_status === 'confirmed' ? 'Verified' : 'Not Verified'
            //     }`
            // )
        } else {
            localStorage.removeItem('veteran-token')
        }
    } catch (error) {
        console.error(error)
        localStorage.removeItem('veteran-token')
    }
}

// Either returns veteran history from veteran verification api and formats it to be displayed as "cards" or removes access token from local storage
const getVeteranHistory = async (token) => {
    const url = `${config.veteranCredentials.baseSandboxUrl}${config.veteranCredentials.veteranVerificationPrefix}/service_history`
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: verificationHeaders(token),
        })
        const { data } = await response.json()
        if (response.status === 200) {
            // setFirstName(data[0].attributes.first_name)
            // setLastName(data[0].attributes.last_name)
            // const serviceBlock = {
            //   0: [
            //     {
            //       label: "Branch: ",
            //       value: data[0].attributes.branch_of_service
            //     },
            //     {
            //       label: "Start Date: ",
            //       value: data[0].attributes.start_date
            //     },
            //     {
            //       label: "End Date: ",
            //       value: data[0].attributes.end_date
            //     },
            //     {
            //       label: "Pay Grade: ",
            //       value: data[0].attributes.pay_grade
            //     }
            //   ]
            // }
            const serviceBlock = [
                {
                    branch_of_service: data[0].attributes.branch_of_service,
                    start_date: data[0].attributes.start_date,
                    end_date: data[0].attributes.end_date,
                    pay_grade: data[0].attributes.pay_grade,
                },
            ]

            // const dischargeBlock = {
            //   0: [
            //     {
            //       label: "Discharge Status: ",
            //       value: data[0].attributes.discharge_status
            //     },
            //     {
            //       label: "Separation Reason: ",
            //       value: data[0].attributes.separation_reason
            //     }
            //   ]
            // }
            const dischargeBlock = [
                {
                    discharge_status: data[0].attributes.discharge_status,
                    separation_reason: data[0].attributes.separation_reason,
                },
            ]

            const deploymentBlock = data[0].attributes.deployments
            return { serviceBlock, deploymentBlock, dischargeBlock }
            // const deploymentBlock = data[0].attributes.deployments?.map((deployment) => {
            //   const result = Object.keys(deployment).map(key => {
            //     let label = key === 'start_date' ? 'Deployment Start: ' :
            //       key === 'end_date' ? 'Deployment End: ' :
            //         key === 'location' ? 'Deployment Location: ' : ''
            //     return {
            //       label: label,
            //       value: deployment[key]
            //     }
            //   })
            //   return result.flat()
            // })
            // setService(serviceBlock)
            // setDeployment(deploymentBlock)
            // setDischarge(dischargeBlock)
            // storeVeteranHistory('service', serviceBlock)
            // storeVeteranHistory('deployment', deploymentBlock)
            // storeVeteranHistory('discharge', dischargeBlock)
        } else {
            // console.log("getVeteranHistory status & data", veteranStatus, data);
            localStorage.removeItem('veteran-token')
        }
    } catch (error) {
        console.error(error)
        localStorage.removeItem('veteran-token')
    }
}

// Either returns veteran disability rating from veteran verification api and formats it to be displayed as a "card" or removes access token from local storage
const getVeteranDisability = async (token) => {
    const url = `${config.veteranCredentials.baseSandboxUrl}${config.veteranCredentials.veteranVerificationPrefix}/disability_rating`
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: verificationHeaders(token),
        })
        if (response.status === 200) {
            const { data } = await response.json()
            // const disabilityBlock = data

            // const disabilityBlock = data.attributes.individual_ratings
            // const disabilityBlock = data.attributes.individual_ratings.map((disability) => {
            //   const result = Object.keys(disability).map(key => {
            //     let label = key === 'rating_percentage' ? 'Rating: ' :
            //       key === 'decision' ? 'Decision: ' :
            //         key === 'effective_date' ? 'Effective Date: ' : ''
            //     return {
            //       label: label,
            //       value: key === 'rating_percentage' ? `${disability.rating_percentage} %` : disability[key]
            //     }
            //   })
            //   return result.flat()
            // })
            // setDisability(disabilityBlock)
            // storeVeteranHistory('disability', disabilityBlock)
            return data
        } else {
            // const { data } = await response.json();
            // console.log("getVeteranDisability:status and data", veteranStatus, data);
            localStorage.removeItem('veteran-token')
        }
    } catch (error) {
        console.error(error)
        localStorage.removeItem('veteran-token')
    }
}

export const getVeteranRecord = async (token) => {
    const history = await getVeteranHistory(token)
    const disability = await getVeteranDisability(token)
    if (disability?.attributes) await updateVeteranDisabilityProfile(disability)
    const status = await getVeteranStatus(token)
    const payload = {
        history,
        disability: disability.attributes,
        status,
    }
    // console.log('payload', payload)
    return payload
}

const updateVeteranDisabilityProfile = async (body) => {
    const profileId = localStorage.getItem('profileid')
    try {
        const response = await axiosInstance.post(`/Profile/add-disability-data`, {
            disabilityDataJson: JSON.stringify(body.attributes),
            profileId,
            id: body.id,
        })
        return response
    } catch (error) {
        console.error('An error occurred:', error)
        throw error
    }
}

export const getPatientVeteranDisability = async (profileId) => {
    try {
        const response = await axiosInstance.get(`/Profile/get-disability-data?profileId=${profileId}`)
        return response
    } catch (error) {
        console.error('An error occurred:', error)
        throw error
    }
}
