import React from 'react'
// import { setLocalItem } from 'services/Services'
import { UserRegisterAPI } from 'services/Services'
// import { GetuserAPI, UserRegisterAPI } from 'services/Services'
import popupNotification from 'components/Notification/popupNotification'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
// import { onError } from './Utils/errorLib'
import { LoginService } from 'services/login.service'
import { setSession } from 'util/auth.util'
// import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr'
// import config from 'use/Config'
import { useSearchField } from 'hoc/SearchFieldProvider'

const date = moment().format()

const defaultState = {
    firstName: '',
    lastName: '',
    phone: '',
    gender: 'male',
    preferredLanguage: 'English',
    relationShip: 'self',
    email: '',
    password: '',
    loading: false,
    emailValid: true,
    passwordValid: true,
    firstNameValid: true,
    lastNameValid: true,
    phoneValid: true,
    preferredLanguageValid: true,
    relationShipValid: true,
    genderValid: true,
    getPatientProfile: '',
    token: '',
    confirmPassword: '',
    newUser: null,
    confirmationCode: '',
    isSendingCode: false,
    isCodeSent: false,
    isConfirming: false,
    isConfirmed: false,
    agree: false,
    showConfirmPassword: false,
    showPassword: false,
    userEmailVerified: true,
    resendCodeLoading: false,
}
const useLogin = () => {
    const history = useHistory()
    const location = useLocation()
    const { getAllRecords, getDependents } = useSearchField()

    const [state, updateState] = React.useState(defaultState)

    const setState = (nextState) => {
        updateState((prevState) => ({ ...prevState, ...nextState }))
    }

    const resetState = () => {
        setState(defaultState)
    }

    React.useEffect(() => {
        setState(defaultState)
    }, [location.pathname])

    const createProfile = async (email) => {
        let myHeaders = new Headers()
        myHeaders.append('content-type', 'application/json')

        let raw = JSON.stringify({
            userObjectId: date,
            isActive: true,
            userTypeId: 1,
            email: email,
            phone: '',
            createdDate: date,
        })

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        }

        const result = await UserRegisterAPI(requestOptions)

        if (result) {
            // cerner???
            localStorage.setItem('cerner', 0)
            localStorage.setItem('profileid', result.profileId)
            getUser(email)
            history.push('/home/myprofile')
        }
    }

    // Function to retrieve profile data
    const getUser = async (email) => {
        // let requestOptions = {
        //     method: 'GET',
        //     redirect: 'follow',
        // }
        // const token = localStorage.getItem('token')

        // const result = await GetuserAPI(email, requestOptions)
        // const { status, data: result } = await axios.get(`${config.mrc.baseApiUrl}/Login/Getuser?email=` + email, {
        //     headers: {
        //         'content-type': 'application/json',
        //         authorization: `Bearer ${token}`,
        //     },
        // })
        if (!state.email) return
        const { data: result } = await LoginService.getUser(state.email)

        if (result?.message === '1') {
            // setLocalItem('profileData', result, true)
            localStorage.setItem('profileid', result.profile.id)
            localStorage.setItem('email', state.email)
            localStorage.setItem('fullName', `${result.profile.firstName} ${result.profile.lastName}`)
            history.push('/home/myprofile')
        } else {
            createProfile(email)
        }

        setState({ getPatientProfile: result })
    }
    // login
    const signIn = async () => {
        try {
            setState({ loading: true })
            const payload = {
                email: state.email,
                password: state.password,
            }
            const { data } = await LoginService.login(payload)
            // startEhrConnection()
            // setEhrConnection(ehrConnection)
            setSession(data?.token, data?.refreshToken)
            setState({ token: data?.token, loading: false })
            await getUser(state.email)
            // setTimeout(() => {
            getAllRecords()
            getDependents()
            // }, [2000])
            popupNotification('LogIn Success. Wait a moment...', true)
        } catch (error) {
            setState({ loading: false })
            console.log('e', error)
            if (error && error?.message?.includes('not confirmed yet')) {
                setState({ userEmailVerified: false })
                popupNotification('Please confirm your email verification code.', false)
            } else {
                popupNotification('Please enter valid credentials', false)
            }
        }
    }

    const signup = async () => {
        if (state.password !== state.confirmPassword) {
            // popupNotification(`Passwords don't match`, false, '')
            return
        }

        if (!state.firstName) {
            setState({ firstNameValid: false })
            return
        }
        if (!state.lastName) {
            setState({ lastNameValid: false })
            return
        }
        if (!state.phone) {
            setState({ phoneValid: false })
            return
        }
        if (!state.email) {
            setState({ emailValid: false })
            return
        }
        if (!state.gender) {
            setState({ genderValid: false })
            return
        }
        if (!state.preferredLanguage) {
            setState({ preferredLanguageValid: false })
            return
        }

        if (!state.relationShip) {
            setState({ relationShipValid: false })
            return
        }

        if (!state.password) {
            setState({ passwordValid: false })
            return
        }
        if (
            !state.emailValid ||
            !state.phoneValid ||
            !state.firstNameValid ||
            !state.lastNameValid ||
            !state.relationShipValid ||
            !state.preferredLanguageValid ||
            !state.genderValid
        )
            return

        setState({ isLoading: true })
        try {
            const payload = {
                firstName: state.firstName,
                lastName: state.lastName,
                userObjectId: date,
                gender: state.gender || 'male',
                relationShip: state.relationShip || 'self',
                preferredLanguage: state.preferredLanguage || 'English',
                email: state.email,
                password: state.password,
                phone: state.phone || '',
            }
            const { data } = await LoginService.signup(payload)
            setState({
                newUser: data,
            })

            setState({ loading: false })
            popupNotification(
                'Registration Code has been sent to your email. Please check spam or junk folder if does not appear in your inbox',
                true,
                '',
                10,
                'top'
            )
        } catch (e) {
            setState({ loading: false })
            popupNotification(e?.message, false, '')
        }
        setState({ isLoading: false })
    }

    const handleConfirmationSubmit = async () => {
        setState({ isLoading: true })
        const payload = {
            email: state.email,
            confirmationCode: state?.confirmationCode,
        }
        try {
            await LoginService.confirmationCode(payload)
            await signIn()
        } catch (e) {
            setState({ loading: false })
            popupNotification(e?.message, false)
        }
    }

    const handleResendCode = async () => {
        try {
            setState({ resendCodeLoading: true })
            await LoginService.resendVerificationCode(state.email)
            setState({ isCodeSent: true, resendCodeLoading: false })
        } catch (e) {
            setState({ loading: false })
            popupNotification(e?.message, false)
        }
    }

    const handleSendCodeClick = async () => {
        setState({ isSendingCode: true })
        try {
            await LoginService.forgotPassword(state.email)
            setState({ isCodeSent: true })
            // popupNotification(error?.message, true)
        } catch (error) {
            popupNotification(error?.message, false)
        }
    }

    const handleConfirmClick = async () => {
        try {
            setState({ isConfirming: true })
            await LoginService.changePassword({ token: state.confirmationCode, password: state.password })

            history.push('/login')
        } catch (error) {
            popupNotification(error?.message, false)
        }
    }

    return {
        signIn,
        signup,
        handleSendCodeClick,
        handleConfirmationSubmit,
        handleConfirmClick,
        handleResendCode,
        state,
        setState,
        resetState,
    }
}

export default useLogin
