import React from 'react'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  height: '60px',
  // opacity: 0.05,
  // background: '#000000',

  '& .MuiInputBase-input': {
    fontWeight: 500,
    fontSize:'12px',
  },

  '& .MuiFormLabel-root': {
    fontWeight: 500, 
  }
}));

const MrcTextField = ({ children, ...other }) => {
  return (
    <StyledTextField variant="outlined" {...other}>
      {children}
    </StyledTextField>
  )
}

export default MrcTextField
