export const languageData = [
    'Afrikaans',
    'Akan',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'ASL',
    'Assamese',
    'Assyrian',
    'Azerbaijani',
    'Bahdini',
    'Bambara',
    'Bashkir',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bosnian',
    'Bravanese',
    'Bulgarian',
    'Burmese',
    'Cambodian',
    'Cantonese',
    'Catalan',
    'Cebuano',
    'Chaldean',
    'Chamorro',
    'Chaozhou',
    'Chavacano',
    'Chin',
    'Chuukese',
    'Cree',
    'Croatian',
    'Czech',
    'Dakota',
    'Danish',
    'Dari',
    'Dinka',
    'Dioula',
    'Dutch',
    'Dzongkha',
    'English',
    'Estonian',
    'Ewe',
    'Fante',
    'Faroese',
    'Farsi',
    'Fijian Hindi',
    'Finnish',
    'Flemish',
    'French',
    'French Canadian',
    'Frisian',
    'Fujianese',
    'Fukienese',
    'Fula',
    'Fulani',
    'Fuzhou',
    'Ga',
    'Gaelic',
    'Galician',
    'Ganda',
    'Georgian',
    'German',
    'Gorani',
    'Greek',
    'Gujarati',
    'Haitian Creole',
    'Hakka',
    'Hassaniyya',
    'Hausa',
    'Hebrew',
    'Hiligaynon',
    'Hindi',
    'Hmong',
    'Hungarian',
    'Ibanag',
    'Icelandic',
    'Igbo',
    'Ilocano',
    'Ilonggo',
    'Indian',
    'Indonesian',
    'Inuktitut',
    'Irish',
    'Italian',
    'Jakartanese',
    'Japanese',
    'Javanese',
    'Kanjobal',
    'Kannada',
    'Karen',
    'Kashmiri',
    'Kazakh',
    'Khalkha',
    'Khmer',
    'Kikuyu',
    'Kinyarwanda',
    'Kirundi',
    'Korean',
    'Kosovan',
    'Kotokoli',
    'Krio',
    'Kurdish',
    'Kurmanji',
    'Kyrgyz',
    'Lakota',
    'Laotian',
    'Latin',
    'Latvian',
    'Lingala',
    'Lithuanian',
    'Luganda',
    'Luo',
    'Lusoga',
    'Luxembourgeois',
    'Maay',
    'Macedonian',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maldivian',
    'Maltese',
    'Mandarin',
    'Mandingo',
    'Mandinka',
    'Maori',
    'Marathi',
    'Marshallese',
    'Mien',
    'Mirpuri',
    'Mixteco',
    'Moldovan',
    'Mongolian',
    'Navajo',
    'Neapolitan',
    'Nepali',
    'Norwegian',
    'Nuer',
    'Nyanja',
    'Ojibaway',
    'Oriya',
    'Oromo',
    'Ossetian',
    'Pahari',
    'Pampangan',
    'Pashto',
    'Patois',
    'Pidgin English',
    'Polish',
    'Portuguese',
    'Pothwari',
    'Pulaar',
    'Punjabi',
    'Putian',
    'Quanxi',
    'Quechua',
    'Romani',
    'Romanian',
    'Romansch',
    'Rundi',
    'Russian',
    'Samoan',
    'Sango',
    'Sanskrit',
    'Serbian',
    'Shanghainese',
    'Shona',
    'Sichuan',
    'Sicilian',
    'Sindhi',
    'Sinhala',
    'Sinhalese',
    'Siswati/Swazi',
    'Slovak',
    'Slovene',
    'Slovenian',
    'Somali',
    'Soninke',
    'Sorani',
    'Sotho',
    'Spanish',
    'Sundanese',
    'Susu',
    'Swahili',
    'Swedish',
    'Sylhetti',
    'Tagalog',
    'Taiwanese',
    'Tajik',
    'Tamil',
    'Telugu',
    'Thai',
    'Tibetan',
    'Tigrinya',
    'Tongan',
    'Tshiluba',
    'Tsonga',
    'Tswana',
    'Turkish',
    'Turkmen',
    'Uighur',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Venda',
    'Vietnamese',
    'Visayan',
    'Welsh',
    'Wolof',
    'Xhosa',
    'Yao',
    'Yiddish',
    'Yoruba',
    'Yupik',
    'Zulu',
]

export const relationData = [
    { label: 'Self', value: 'self' },
    { label: 'Spouse', value: 'spouse' },
    // { label: 'Kids', value: 'kids' },
    { label: 'Dependent', value: 'dependent' },

    { label: 'Other', value: 'other' },
]

export const genderData = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Transgender Male', value: 'transgender male' },
    { label: 'Transgender Female', value: 'transgender female' },
    { label: 'Non-binary', value: 'non-binary' },
    { label: 'Other', value: 'others' },
    { label: 'Do not wish to disclose', value: 'do not wish to disclose' },
]


