import { jwtDecode } from './auth.util'

const authFetch = async (input, init) => {
    const accessToken = localStorage.getItem('token')
    if (!accessToken) {
        console.log('User access token not found.')
        localStorage.clear()
        window.location.href = 'login'
        // throw new Error('User access token not found.')
    }
    const { exp } = jwtDecode(accessToken)
    const currentTime = Date.now()
    const timeLeft = exp * 1000 - currentTime
    if (timeLeft < 0) {
        localStorage.clear()
        window.location.href = 'login'
    }

    const options = init
        ? {
              ...init,
              headers: {
                  ...init.headers,
                  Authorization: `Bearer ${accessToken}`,
              },
          }
        : {
              headers: {
                  Authorization: `Bearer ${accessToken}`,
              },
          }
    return fetch(input, options)
}

export default authFetch
