import React, { createContext, useState } from 'react'
import { useEffect } from 'react'
import { DependentService } from 'services/dependent.service'
import { RecordsService } from 'services/myrecords.service'
import { LoginService } from 'services/login.service'

const Context = createContext({
    searchTerm: null,
    dependents: [],
    recordType: [],
    getDependents() {},
    getAllRecords() {},
})

export const useSearchField = () => {
    const context = React.useContext(Context)
    return context
}

const SearchFieldProvider = ({ children }) => {
    const [searchTerm, setSearchTerm] = useState(null)
    const [filterByColumn, setFilterByColumn] = useState('all')
    const [searchColumns, setSearchColumns] = useState(null)
    const [dependents, setDependents] = useState([])
    const [recordType, setRecordType] = useState([])
    const [selectedDependent, setSelectedDependent] = useState(null)

    const handleOnSetSearchColumns = (columns) => {
        setFilterByColumn('all')
        setSearchTerm(null)
        setSearchColumns(columns)
    }

    const handleDependents = (data) => {
        setDependents(data)
    }

    const getDependents = async () => {
        // const { data: result } = await DependentService.getAllDependents()
        // const payload = result.map((option) => {
        //     return { id: option.id, name: `${option.firstName} ${option.lastName}` }
        // })
        // setDependents(payload)
        const email = localStorage.getItem('email')
        if (!email) return
        const [loginData, dependentData] = await Promise.all([
            LoginService.getUser(email),
            DependentService.getAllDependents(),
        ])
        if (loginData.data && dependentData.data) {
            const payload = dependentData.data.map((option) => {
                return { id: option.id, name: `${option.firstName} ${option.lastName}` }
            })
            setDependents([
                {
                    id: loginData.data.profile.id,
                    name: `${loginData.data.profile.firstName} ${loginData.data.profile.lastName} - (Self)`,
                    self: true,
                },
                ...payload,
            ])
        }
    }

    const getAllRecords = async () => {
        const { data: result } = await RecordsService.getAllRecords()
        setRecordType(
            result?.filter((x) => x.isDisplay).sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)) //For Ascending
        )
    }
    useEffect(() => {
        setTimeout(() => {
            getAllRecords()
            getDependents()
        }, 2000)
    }, [])

    const value = {
        searchTerm,
        searchColumns,
        filterByColumn,
        dependents,
        recordType,
        setDependents: handleDependents,
        setSearchTerm,
        setSearchColumns: handleOnSetSearchColumns,
        setFilterByColumn,
        selectedDependent,
        setSelectedDependent,
        setRecordType,
        getDependents,
        getAllRecords,
    }
    return <Context.Provider value={value}>{children}</Context.Provider>
}

export default SearchFieldProvider
