import { notification } from 'antd'
import 'antd/dist/antd.css'

const popupNotification = (message, success, description = '', duration = 5, placement = 'topRight') => {
    const style =
        placement === 'top'
            ? {
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(20%, 50%)',
              }
            : null
    if (success) {
        notification.success({
            message,
            placement: placement,
            duration,
            style: style,
        })
    } else {
        notification.error({
            message,
            description,
            placement: placement,
            duration,
            style: style,
        })
    }
}

export default popupNotification
