import React from 'react'
import { useHistory } from 'react-router-dom'
import DataGrid from 'components/DataTable/DataGridWrapper'
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined'
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined'
import Share from 'components/Share/Share'
import ShareMyRecordsCard from 'components/ShareMyRecordsCard'
import MrcButton from 'components/MrcButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import CancelIcon from '@mui/icons-material/Cancel'

import './ShareMyRecords.scss'
import { DialogContentText } from '@mui/material'
import { useState } from 'react'
const Doctors = ({ data, onRemove, onShare }) => {
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const [providerId, setProviderId] = useState(null)

    const handleOnRemove = (providerId) => {
        setProviderId(providerId)
        setOpen(true)
    }

    const goToThisPage = (item, pname, pid, experience, type, address) => {
        history.push(`/home/${item}/${pname}/${pid}/${experience}/${type}/${address}`)
    }
    const handleCloseModal = () => {
        setProviderId(null)
        setOpen(false)
    }

    const columns = [
        {
            field: 'photo',
            headerName: ' ',
            sortable: false,
            disablecolumnmenu: true,
            renderCell: (params) => <div className="photo male" />,
        },
        {
            field: 'providerName',
            headerName: 'Name',
            renderCell: (params) => params.row.providerName + ' ' + params.row.providerLastName,
        },
        // {
        //     field: 'providerType',
        //     headerName: 'Type',
        // },
        {
            field: 'sharedProfile',
            headerName: 'Shared Profile Name',
            renderCell: (params) => {
                return (
                    <ul>
                        {params.row?.sharedProfile?.map((profile) => (
                            <li>{profile.name}</li>
                        ))}
                    </ul>
                )
            },
        },
        // {
        //     // Chat feature not yet functional
        //     field: 'chat',
        //     sortable: false,
        //     headerName: 'Chat',
        //     renderCell: (params) => <MrcButton startIcon={<TextsmsOutlinedIcon />}>Chat</MrcButton>,
        // },
        {
            field: 'share',
            headerName: 'Share',
            sortable: false,
            renderCell: (params) => (
                <Share
                    data={params.row}
                    handleShareSave={(checkedMongoTables, selectedProfileIds) =>
                        onShare(checkedMongoTables, selectedProfileIds, params.row.providerId)
                    }
                />
            ),
        },
        // {
        //     field: 'book',
        //     sortable: false,
        //     headerName: 'Book',
        //     renderCell: (params) => <MrcButton startIcon={<EditCalendarOutlinedIcon />}>Book</MrcButton>,
        // },
        {
            field: 'remove',
            headerName: 'Remove',
            sortable: false,
            renderCell: (params) => (
                <CancelIcon className="close-icon" onClick={() => handleOnRemove(params.row.providerId)} />
            ),
        },
    ]
    return (
        <>
            <div style={{ height: 500, width: '100%' }}>
                <DataGrid
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    rows={data}
                    pageSize={10}
                    disableSelectionOnClick
                    getRowId={(r) => r.providerId}
                    renderRowMobile={(row) => (
                        <ShareMyRecordsCard
                            type="doctors"
                            row={row}
                            onChat={() => {}}
                            onShare={(checkedMongoTables, selectedProfileIds) =>
                                onShare(checkedMongoTables, selectedProfileIds, row.providerId)
                            }
                            onBook={(row) =>
                                goToThisPage(
                                    'book',
                                    row.providerName,
                                    row.providerId,
                                    row.providerExperience,
                                    row.providerType,
                                    row.providerAddress
                                )
                            }
                            onRemove={(row) => handleOnRemove(row.providerId)}
                        />
                    )}
                />
            </div>
            <Dialog
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Doctor</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete doctor?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    {/* Cancel deletion */}
                    <Button onClick={handleCloseModal} className="text-uppercase">
                        Cancel
                    </Button>

                    {/* Confirm deletion */}
                    <Button
                        color="primary"
                        variant="contained"
                        className="common-button"
                        onClick={() => onRemove(providerId)}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Doctors
