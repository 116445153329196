import React, { useEffect } from 'react'
// import 'react-notifications-component/dist/theme.css'
// import ReactNotification from 'react-notifications-component'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom'
// import { getLocalItem } from 'services/Services'
import { ThemeProvider } from '@mui/material/styles'

import './App.scss'
import theme from './theme'
import Home from './pages/home/Home'
import Login from './pages/auth/Login'
// import SignUp from './pages/auth/SignUp'
// import TermsAndPolicy from './pages/TermsAndPolicy/TermsAndPolicy'
import GlobalContext from './use/Context'
// import ForgotPassword from './pages/auth/ForgotPassword'
import SearchFieldProvider from './hoc/SearchFieldProvider'
// import { useReactPWAInstall } from 'react-pwa-install'
// import myLogo from './assets/icons/icon.png'
import RedirectUrl from 'pages/home/RedirectUrl/RedirectUrL'

export const allowAnonymous = ['/login', '/signup', '/forgotpassword', '/privacypolicy', '/termsandconditions']
const App = () => {
    // const { pwaInstall, supported, isInstalled } = useReactPWAInstall()
    // useEffect(() => {
    //     if (supported() && !isInstalled()) {
    //         pwaInstall({
    //             title: 'Matchrite Care',
    //             logo: myLogo,
    //             description: 'Matchrite Care- Health at the speed of life',
    //         })
    //     }
    // }, [supported, isInstalled])

    useEffect(() => {
        let _isMounted = true

        const backListener = (window.onpopstate = () => {
            if (_isMounted) {
                const { pathname } = window.location
                const isNotAllowedAnonymous = !allowAnonymous.some((path) => path.includes(pathname))

                if (isNotAllowedAnonymous) {
                    // const loginData = getLocalItem('profileData', true)
                    const loginData = localStorage.getItem('email')
                    if (!loginData) {
                        localStorage.clear()
                        window.location.href = '/login'
                    }
                }
            }
        })

        return () => {
            _isMounted = false
            // unbind listener
            backListener()
        }
    }, [])

    return (
        <GlobalContext>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <SearchFieldProvider>
                            <Switch>
                                <Redirect exact from="/" to="/home" />
                                <Route path="/home/myprofile" component={Home} />
                                <Route path="/home" component={Home} />
                                <Route exact path="/login" component={Login} />
                                <Route exact path="/signup" component={Login} />
                                <Route exact path="/forgotpassword" component={Login} />
                                {/* <Route exact path="/login" component={Login} />
                                <Route exact path="/signup" component={SignUp} />
                                <Route exact path="/forgotpassword" component={ForgotPassword} /> */}
                                {/* <Route exact path="/privacypolicy" component={TermsAndPolicy} />
                                <Route exact path="/termsandconditions" component={TermsAndPolicy} /> */}
                                <Route exact path="/redirect-success" component={RedirectUrl} />

                                <Redirect to="/home" />
                            </Switch>
                        </SearchFieldProvider>
                        {/* <ReactNotification /> */}
                    </Router>
                </ThemeProvider>
            </LocalizationProvider>
        </GlobalContext>
    )
}

export default App
