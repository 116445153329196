import { createTheme } from '@mui/material/styles'

const fontFamily = [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    '"Montserrat"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(',')

const theme = createTheme({
    palette: {
        primary: {
            darker: '#143650',
            main: '#176571',
            // main: '#007CCF',
            light: '#badff8',
            lighter: '#E6F2FA',
        },
        danger: {
            ligher: '#ffe1e1',
        },
        anon: {
            vividCerulean: '#0099FF',
            medSpringGreen: '#00FF99',
        }
    },
    typography: {
        fontFamily,
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: '#fafafa',
                        borderRadius: '0',
                    },
                },
            },
        },
    },
})

export default theme
