export const serviceColumns = [
  {
    field: 'branch_of_service',
    headerName: 'Branch',
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
  },
  {
    field: 'end_date',
    headerName: 'End Date',
  },
  {
    field: 'pay_grade',
    headerName: 'Pay Grade',
  },
]

export const deploymentColumns = [
  {
    field: 'location',
    headerName: 'Deployment Location',
  },
  {
    field: 'start_date',
    headerName: 'Deployment Start',
  },
  {
    field: 'end_date',
    headerName: 'Deployment End',
  },
]

export const dischargeColumns = [
  {
    field: 'discharge_status',
    headerName: 'Status',
  },
  {
    field: 'separation_reason',
    headerName: 'Reason',
  },
]

export const disabilityColumns = [
  {
    field: 'decision',
    headerName: 'Decision',
  },
  {
    field: 'disability_rating_id',
    headerName: 'Rating ID',
  },
  {
    field: 'effective_date',
    headerName: 'Effective Date',
  },
  {
    field: 'rating_percentage',
    headerName: 'Rating %',
  },
  {
    field: 'diagnostic_type_code',
    headerName: 'Type Code',
  },
  {
    field: 'diagnostic_type_name',
    headerName: 'Type Name',
  },
  {
    field: 'diagnostic_text',
    headerName: 'Diagnostic',
  },
]