import React from 'react'
import Dialog from '@mui/material/Dialog'
import Select from '@mui/material/Select'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DialogContent from '@mui/material/DialogContent'

import SideBar from 'components/layout/SideBar/SideBar'

class IdCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            data: [],
            member: '',
        }
    }

    componentDidMount() {
        const data = [
            {
                id: 0,
                name: 'Jason Anderson',
                relation: 'Husband',
                dob: '08/12/1995',
                gender: 'Male',
            },
            {
                id: 1,
                name: 'Amber Anderson',
                relation: 'Daughter',
                dob: '08/12/1995',
                gender: 'Female',
            },
            {
                id: 2,
                name: 'Sam Anderson',
                relation: 'Son',
                dob: '08/12/1995',
                gender: 'Male',
            },
            {
                id: 3,
                name: 'Wilson Tan',
                relation: 'Father',
                dob: '08/12/1995',
                gender: 'Male',
            },
        ]
        this.setState({ data })
    }

    getName = (id) => {
        let name = ''
        for (let i = 0; i < this.state.data.length; i++) {
            if (this.state.data[i].id === id) {
                name = this.state.data[i].name
            }
        }
        return name
    }
    render() {
        return (
            <div className="_container">
                <SideBar title="idcard" />
                <div className="container-right">
                    <div
                        className="main-title"
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ArrowBackIcon
                            style={{ cursor: 'pointer', marginRight: 10 }}
                            onClick={this.props.history.goBack}
                        />
                        <div>ID Card</div>
                    </div>
                    <div className="white-container">
                        <FormControl style={{ margin: '20px 30px', width: 200 }}>
                            <InputLabel id="demo-simple-select-label">Family Member</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.member}
                                onChange={(event) =>
                                    this.setState({
                                        member: event.target.value,
                                        open: true,
                                    })
                                }
                            >
                                {this.state.data.map((item, index) => (
                                    <MenuItem value={item.id} key={index}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Dialog
                            onClose={() => this.setState({ open: false })}
                            aria-labelledby="simple-dialog-title"
                            open={this.state.open}
                        >
                            <DialogTitle id="simple-dialog-title" style={{ textAlign: 'center' }}>
                                {this.getName(this.state.member)}
                            </DialogTitle>
                            <DialogContent>
                                <div
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        width: 300,
                                    }}
                                >
                                    <div style={{ flex: 0.5 }}>
                                        <div style={{ fontWeight: 'bold' }}>D.O.B</div>
                                        <div>12.11.74</div>
                                    </div>
                                    <div style={{ flex: 0.5 }}>
                                        <div style={{ fontWeight: 'bold' }}>Blood Type</div>
                                        <div>O</div>
                                    </div>
                                </div>
                                <Divider style={{ margin: '15px 0' }} />
                                <div
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        width: 300,
                                    }}
                                >
                                    <div style={{ flex: 0.5 }}>
                                        <div style={{ fontWeight: 'bold' }}>Height</div>
                                        <div>5'9</div>
                                    </div>
                                    <div style={{ flex: 0.5 }}>
                                        <div style={{ fontWeight: 'bold' }}>Weight</div>
                                        <div>189</div>
                                    </div>
                                </div>
                                <Divider style={{ margin: '15px 0' }} />
                                <div
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        width: 300,
                                    }}
                                >
                                    <div style={{ flex: 0.5 }}>
                                        <div style={{ fontWeight: 'bold' }}>Group ID</div>
                                        <div style={{ color: 'red' }}>#002136847</div>
                                    </div>
                                    <div style={{ flex: 0.5 }}>
                                        <div style={{ fontWeight: 'bold' }}>Member ID</div>
                                        <div style={{ color: 'red' }}>#002136847</div>
                                    </div>
                                </div>
                                <Divider style={{ margin: '15px 0' }} />
                                <div
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        width: 300,
                                    }}
                                >
                                    <div style={{ flex: 0.5 }}>
                                        <div style={{ fontWeight: 'bold' }}>Rx Info</div>
                                        <div style={{ color: 'red' }}>#002136847</div>
                                    </div>
                                    <div style={{ flex: 0.5 }}>
                                        <div style={{ fontWeight: 'bold' }}>Donor</div>
                                        <div>Yes</div>
                                    </div>
                                </div>
                                <Divider style={{ margin: '15px 0' }} />
                                <div
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        width: 300,
                                    }}
                                >
                                    <div style={{ flex: 0.5 }}>
                                        <div style={{ fontWeight: 'bold' }}>Emergency Contact</div>
                                        <div>Father: Lester Burns</div>
                                        <div>361 744 7995</div>
                                    </div>
                                </div>
                                <Divider style={{ margin: '15px 0' }} />
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
            </div>
        )
    }
}

export default IdCard
