import React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const getTheme = ({ theme, color }) => {
  switch(color) {
    case 'error':
      return {
        backgroundColor: theme.palette.danger.ligher,
        color: theme.palette.error.main,
      }
    default:
      return {
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.primary.main,
      }    
  }
}

const StyledButton = styled(Button)(({ theme, ...rest }) => ({
  ...getTheme({ theme, ...rest }),
  textTransform: 'none',

  '&:hover': {
    filter: 'brightness(0.9)',
  }
}))

const MrcButton = (props) => {
  const { children, ...other } = props;

  return (
    <StyledButton variant='contained' {...other}>
      {children}
    </StyledButton>
  )
}

export default MrcButton
