import React, { useState, useEffect } from 'react'
import Loader from 'components/Loader/Loader'
import DataGrid from 'components/DataTable/DataGridWrapper'
import { useSearchField } from 'hoc/SearchFieldProvider'
import filterEntries from 'util/filterEntries'

const NewRecordType = () => {
    const columns = []

    const [entries, setEntries] = useState([])
    const [loading, setLoading] = useState(false)

    const { searchTerm, filterByColumn, setSearchColumns, selectedDependent } = useSearchField()

    const getFilteredEntries = () => {
        const allColumns = ['providerName', 'substance', 'status', 'category', 'reaction']
        const columns = filterByColumn === 'all' ? allColumns : [filterByColumn]
        return filterEntries(entries, columns, searchTerm)
    }

    const getData = async () => {
        let profileId = selectedDependent?.id || localStorage.getItem('profileid')
        if (!profileId) return
        setLoading(true)
        setEntries([])
        // const data = await AllergiesByProfileIdAPI(profileId)
        // if (data) {
        //     if (data.message === 'No Data Found') {
        //         setEntries([])
        //         setLoading(false)
        //     } else {
        //         setEntries(data?.allergyIntolence)
        //         setLoading(false)
        //     }
        // } else {
        //     setLoading(false)
        // }
    }

    useEffect(() => {
        getData()
    }, [selectedDependent])

    useEffect(() => {
        setSearchColumns([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="container-right">
            {loading ? (
                <Loader pageName="vitals" />
            ) : (
                <div className="white-container">
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            disableColumnFilter
                            disableColumnSelector
                            rows={getFilteredEntries()}
                            disableSelectionOnClick
                            getRowId={(r) => JSON.stringify(r)}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default NewRecordType
