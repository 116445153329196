import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useEffect, useState } from 'react'
import { ImportButton } from 'components/BlueImportButton'
import { FieldBox } from '../MyDoctors/AddYourDoctor.styles'
import { Autocomplete, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material'
import CompassCalibrationOutlinedIcon from '@mui/icons-material/CompassCalibrationOutlined'
import { DependentService } from 'services/dependent.service'
import SettingLogo from './setting-logo.png'
import CloseLogo from './close-icon.png'
const ConnectPatientCerner = ({ title, ehrName, user, isOpen, onClose, onAdd }) => {
    const [selectedValue, setSelectedValue] = useState(null)
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)
    const [loader, setLoader] = useState(false)
    const [userConfirmation, setUserConfirmation] = useState(false)

    const handleAdd = () => {
        if (selectedValue === null || selectedValue?.length <= 0) {
            setError(`Please select a dependent`)
            return
        }
        setError(null)
        setUserConfirmation(true)
        // onAdd(selectedValue?.id)
        // onClose()
    }
    const getDependentData = async () => {
        setLoader(true)
        const { data: result } = await DependentService.getAllDependents()
        setLoader(false)
        const payload = result.map((option) => {
            return { id: option.id, name: `${option.firstName} ${option.lastName}` }
        })
        setData([
            {
                id: user.id,
                name: `${user.firstName} ${user.lastName} - (Self)`,
            },
            ...payload,
        ])
    }

    useEffect(() => {
        if (isOpen) {
            getDependentData()
        }
    }, [isOpen])
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '540px', // Set your width here
                    },
                },
            }}
        >
            <Stack direction="row" justifyContent="flex-end" sx={{ marginTop: '10px', marginRight: '10px' }}>
                <Button
                    onClick={() => {
                        onClose()
                        setSelectedValue(null)
                    }}
                >
                    <img src={CloseLogo} width="25px" height="25px" />
                </Button>
            </Stack>
            <Stack sx={{ paddingLeft: '30px' }}>
                <Stack sx={{ display: 'flex', gap: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <img src={SettingLogo} width="50px" height="50px" />
                    <strong style={{ color: 'black', fontWeight: 600, fontSize: '1.25rem' }}>
                        {userConfirmation ? 'Disclaimer' : ''}
                    </strong>
                </Stack>

                <DialogTitle id="alert-dialog-title" sx={{ padding: 0 }}>
                    {title}
                </DialogTitle>
                <DialogContent sx={{ padding: 0, paddingRight: 6 }}>
                    <Grid item xs={12} md={12} lg={12} sx={{ my: 2 }}>
                        {loader ? (
                            <div className="white-container">
                                <div className="loader">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : (
                            <FieldBox>
                                {userConfirmation ? (
                                    <>
                                        <Typography sx={{ color: '#9A9A9A' }}>
                                            MatchRite is a 3rd party application connecting with your hospital through
                                            <strong style={{ color: 'black', fontWeight: 600 }}> "{ehrName}"</strong>.
                                            <Typography sx={{ marginTop: '15px', color: '#9A9A9A' }}>
                                                After proceeding, you'll be redirected to your hospital application.
                                            </Typography>
                                            <Typography sx={{ marginTop: '15px', color: '#9A9A9A' }}>
                                                <strong style={{ color: 'black', fontWeight: 600 }}> "NOTE": </strong>
                                                MatchRite Care's USER data protection and privacy policies are based
                                                upon 'Opt-in' principals for data-sharing - see; Privacy Policy and
                                                Terms of Service. Upon importing records, your Hospital's EHR portal may
                                                post a notification advising that MatchRite Care is a 3rd party data
                                                application.
                                            </Typography>
                                            <Typography sx={{ marginTop: '15px', color: '#9A9A9A' }}>
                                                MatchRite Care data architecture is configured in compliance with HIPAA
                                                standards.
                                            </Typography>
                                        </Typography>
                                    </>
                                ) : (
                                    <Autocomplete
                                        style={{ flex: 1 }}
                                        options={data}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option) => option.id}
                                        onChange={(_, value) => {
                                            setSelectedValue(value)
                                            setError(null)
                                        }}
                                        error={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`Search Name`}
                                                variant="outlined"
                                                error={Boolean(error)}
                                                helperText={error}
                                            />
                                        )}
                                    />
                                )}
                                {/* <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}> */}
                                {/* <RequestedProviderData errors={null} /> */}
                                {/* </div> */}
                            </FieldBox>
                        )}
                    </Grid>
                </DialogContent>
            </Stack>
            <DialogActions sx={{ marginTop: '15px', backgroundColor: '#E5E5E5', height: '65px' }}>
                <Button
                    onClick={() => {
                        onClose()
                        setSelectedValue(null)
                    }}
                    sx={{ color: 'black', fontWeight: 600 }}
                >
                    Cancel
                </Button>
                {userConfirmation ? (
                    <Button
                        autoFocus
                        variant="contained"
                        color="primary"
                        sx={{
                            background: '#176571',
                            // background: '#00AFAA',
                            width: '120px',
                            marginRight: '10px',
                            '&:hover': { background: '#176571' },
                            // '&:hover': { background: '#00AFAA' },
                        }}
                        onClick={() => {
                            onAdd(selectedValue?.id)
                            // onClose()
                        }}
                    >
                        Proceed
                    </Button>
                ) : (
                    <ImportButton
                        startIcon={<CompassCalibrationOutlinedIcon />}
                        autoFocus
                        variant="contained"
                        color="primary"
                        onClick={handleAdd}
                    >
                        Add
                    </ImportButton>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default ConnectPatientCerner
