import React from 'react'
import Table from '@mui/material/Table'
import Rating from '@mui/lab/Rating'
import TableRow from '@mui/material/TableRow'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'

import Colors from 'use/Color'
import SideBar from 'components/layout/SideBar/SideBar'

import './FindProvider.scss'

class FindProvider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 5,
            type: '',
            providers: [],
            sProviders: [],
        }
    }

    componentDidMount() {
        const data = [
            {
                id: 0,
                providerName: 'Albert',
                providerType: 'Cardiologist',
                providerAddress: 'New York, US',
                providerExperience: 10,
                providerRatings: 5,
                providerReviews: 10,
                distance: 21,
            },
            {
                id: 1,
                providerName: 'Robert',
                providerType: 'Physcian',
                providerAddress: 'New Jersey, US',
                providerExperience: 6,
                providerRatings: 3,
                providerReviews: 120,
                distance: 11,
            },
            {
                id: 2,
                providerName: 'Amber Heard',
                providerType: 'Gynecologist',
                providerAddress: 'Shingle Springs, CA',
                providerExperience: 1,
                providerRatings: 4,
                providerReviews: 30,
                distance: 33,
            },
            {
                id: 3,
                providerName: 'Sabrina Kaskey',
                providerType: 'Neurologist',
                providerAddress: 'Los Angeles, US',
                providerExperience: 5,
                providerRatings: 5,
                providerReviews: 50,
                distance: 4,
            },
            {
                id: 1,
                providerName: 'Christoper',
                providerType: 'Pediatrician',
                providerAddress: 'Calfornia, US',
                providerExperience: 7,
                providerRatings: 3,
                providerReviews: 20,
                distance: 5,
            },
            {
                id: 1,
                providerName: 'Greta',
                providerType: 'Cardiologist',
                providerAddress: 'Bahamas, US',
                providerExperience: 4,
                providerRatings: 5,
                providerReviews: 60,
                distance: 1,
            },
            {
                id: 1,
                providerName: 'Rose',
                providerType: 'Physcian',
                providerAddress: 'Texas, US',
                providerExperience: 8,
                providerRatings: 3,
                providerReviews: 70,
                distance: 2,
            },
            {
                id: 1,
                providerName: 'Dr Tan',
                providerType: 'Cardiologist',
                providerAddress: 'Las vegas, US',
                providerExperience: 2,
                providerRatings: 1,
                providerReviews: 80,
                distance: 4,
            },
            {
                id: 1,
                providerName: 'Dr Stan',
                providerType: 'Neurologist',
                providerAddress: 'Washington, US',
                providerExperience: 3,
                providerRatings: 2,
                providerReviews: 15,
                distance: 12,
            },
            {
                id: 1,
                providerName: 'Dr Strange',
                providerType: 'Physcian',
                providerAddress: 'New York, US',
                providerExperience: 9,
                providerRatings: 4,
                providerReviews: 40,
                distance: 22,
            },
        ]
        this.setState({ providers: data })
        this.setState({ sProviders: data })
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value })
        this.setState({ page: 0 })
    }

    filterBySpeciality = (title) => {
        this.setState({ type: title })
        this.setState((state) => {
            const sProviders = state.providers.filter((item, j) => item.providerType.includes(title))
            return { sProviders }
        })
    }

    render() {
        const { page, rowsPerPage } = this.state
        return (
            <div className="_container findprovider">
                <SideBar title="findprovider" />
                <div className="container-right">
                    <div className="main-title">Find a Doctor</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className="left-side">
                        <div
                            style={{
                                width: '25%',
                                backgroundColor: '#FFFFFF',
                                borderTopLeftRadius: 30,
                                borderTopRightRadius: 10,
                                borderBottomLeftRadius: 10,
                                borderBottomRightRadius: 10,
                            }}
                        >
                            <div
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    padding: 20,
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: 20,
                                    }}
                                >
                                    Specialty
                                </div>
                                <div
                                    style={{ fontSize: 15 }}
                                    className="view-all"
                                    onClick={() => this.filterBySpeciality('')}
                                >
                                    View All
                                </div>
                            </div>
                            <div class="menuitems">
                                {/* <div class='menuitem' onClick={() => this.filterBySpeciality('Cardiologist')} style={{backgroundColor: this.state.type === 'Cardiologist'? '#4D9cd0' : '#FFFFFF', color: this.state.type === 'Cardiologist'? '#FFF' : '#000'}}>Cardiologist</div>
                                <div class='menuitem' onClick={() => this.filterBySpeciality('Physcian')} style={{backgroundColor: this.state.type === 'Physcian'? '#4D9cd0' : '#FFFFFF', color: this.state.type === 'Physcian'? '#FFF' : '#000'}}>Physcian</div>
                                <div class='menuitem' onClick={() => this.filterBySpeciality('Gynecologist')} style={{backgroundColor: this.state.type === 'Gynecologist'? '#4D9cd0' : '#FFFFFF', color: this.state.type === 'Gynecologist'? '#FFF' : '#000'}}>Gynecologist</div>
                                <div class='menuitem' onClick={() => this.filterBySpeciality('Neurologist')} style={{backgroundColor: this.state.type === 'Neurologist'? '#4D9cd0' : '#FFFFFF', color: this.state.type === 'Neurologist'? '#FFF' : '#000'}}>Neurologist</div>
                                <div class='menuitem' onClick={() => this.filterBySpeciality('Pediatrician')} style={{ borderBottom: '1px solid #dfdfdf', backgroundColor: this.state.type === 'Pediatrician'? '#4D9cd0' : '#FFFFFF', color: this.state.type === 'Pediatrician'? '#FFF' : '#000'}}>Pediatrician</div> */}
                                <div
                                    className="menuitem"
                                    onClick={() => this.filterBySpeciality('Family Doctor')}
                                    style={{
                                        backgroundColor:
                                            this.state.type === 'Family Doctor' ? Colors.secondary : '#FFFFFF',
                                        color: this.state.type === 'Family Doctor' ? '#FFF' : '#000',
                                    }}
                                >
                                    Family Doctor
                                </div>
                                <div
                                    className="menuitem"
                                    onClick={() => this.filterBySpeciality('Pediatrics')}
                                    style={{
                                        backgroundColor:
                                            this.state.type === 'Pediatrics' ? Colors.secondary : '#FFFFFF',
                                        color: this.state.type === 'Pediatrics' ? '#FFF' : '#000',
                                    }}
                                >
                                    Pediatrics
                                </div>
                                <div
                                    className="menuitem"
                                    onClick={() => this.filterBySpeciality('Cardiology')}
                                    style={{
                                        backgroundColor:
                                            this.state.type === 'Cardiology' ? Colors.secondary : '#FFFFFF',
                                        color: this.state.type === 'Cardiology' ? '#FFF' : '#000',
                                    }}
                                >
                                    Cardiology
                                </div>
                                <div
                                    className="menuitem"
                                    onClick={() => this.filterBySpeciality('Mental Health')}
                                    style={{
                                        backgroundColor:
                                            this.state.type === 'Mental Health' ? Colors.secondary : '#FFFFFF',
                                        color: this.state.type === 'Mental Health' ? '#FFF' : '#000',
                                    }}
                                >
                                    Mental Health
                                </div>
                                <div
                                    className="menuitem"
                                    onClick={() => this.filterBySpeciality('Internal Medicine')}
                                    style={{
                                        borderBottom: '1px solid #dfdfdf',
                                        backgroundColor:
                                            this.state.type === 'Internal Medicine' ? Colors.secondary : '#FFFFFF',
                                        color: this.state.type === 'Internal Medicine' ? '#FFF' : '#000',
                                    }}
                                >
                                    Internal Medicine
                                </div>
                                <div
                                    className="menuitem"
                                    onClick={() => this.filterBySpeciality('OB/GYN')}
                                    style={{
                                        backgroundColor: this.state.type === 'OB/GYN' ? Colors.secondary : '#FFFFFF',
                                        color: this.state.type === 'OB/GYN' ? '#FFF' : '#000',
                                    }}
                                >
                                    OB/GYN
                                </div>
                                <div
                                    className="menuitem"
                                    onClick={() => this.filterBySpeciality('Oncology')}
                                    style={{
                                        backgroundColor: this.state.type === 'Oncology' ? Colors.secondary : '#FFFFFF',
                                        color: this.state.type === 'Oncology' ? '#FFF' : '#000',
                                    }}
                                >
                                    Oncology
                                </div>
                                <div
                                    className="menuitem"
                                    onClick={() => this.filterBySpeciality('Orthopedic Surgery')}
                                    style={{
                                        backgroundColor:
                                            this.state.type === 'Orthopedic Surgery' ? Colors.secondary : '#FFFFFF',
                                        color: this.state.type === 'Orthopedic Surgery' ? '#FFF' : '#000',
                                    }}
                                >
                                    Orthopedic Surgery
                                </div>
                            </div>
                        </div>
                        <div
                            className="white-container"
                            style={{
                                marginLeft: 10,
                                borderRadius: 10,
                                height: '100%',
                            }}
                        >
                            <TextField
                                type="text"
                                label="Search Provider"
                                margin="normal"
                                name="search"
                                InputProps={{
                                    endAdornment: <SearchIcon position="end" />,
                                }}
                                style={{
                                    float: 'right',
                                    marginRight: 20,
                                    marginBottom: 10,
                                    width: 300,
                                }}
                            />
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Distance</TableCell>
                                            <TableCell>Experience</TableCell>
                                            <TableCell>Reviews</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.sProviders
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        style={{
                                                            backgroundImage: 'linear-gradient(white, white, #dfdfdf)',
                                                        }}
                                                        key={index}
                                                    >
                                                        {row.gender === 'male' ? (
                                                            <TableCell>
                                                                <div className="provider-photo-male-finddoctor"></div>
                                                            </TableCell>
                                                        ) : (
                                                            <TableCell>
                                                                <div className="provider-photo-female-finddoctor"></div>
                                                            </TableCell>
                                                        )}
                                                        <TableCell>{row.providerName}</TableCell>
                                                        <TableCell>{row.providerType}</TableCell>
                                                        <TableCell>{row.providerAddress}</TableCell>
                                                        <TableCell>
                                                            <RoomOutlinedIcon
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: Colors.secondary,
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {row.distance}
                                                            </span>{' '}
                                                            miles
                                                        </TableCell>
                                                        <TableCell>
                                                            <span
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {row.providerExperience}
                                                            </span>{' '}
                                                            Years
                                                        </TableCell>
                                                        <TableCell>
                                                            <Rating
                                                                name="read-only"
                                                                value={row.providerRatings}
                                                                readOnly
                                                            />
                                                            <div>({row.providerReviews} Reviews)</div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <CalendarTodayOutlinedIcon
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: Colors.secondary,
                                                                    marginRight: 10,
                                                                }}
                                                                onClick={() => {
                                                                    this.props.history.push('/home/videochat')
                                                                }}
                                                            />
                                                            <ChatBubbleOutlineOutlinedIcon
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: Colors.secondary,
                                                                    marginRight: 10,
                                                                }}
                                                            />
                                                            <PhoneForwardedOutlinedIcon
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: Colors.secondary,
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component="div"
                                count={this.state.sProviders.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FindProvider
