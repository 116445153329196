import React, { useEffect, useState } from 'react'
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined'
import Loader from 'components/Loader/Loader'
import { GetDoctorsAPI, RemoveDoctorAPI } from 'services/Services'
import './ShareMyRecords.scss'
import Organizations from './Organization'
import Doctors from './Doctors'
import { Button, IconButton, Stack, Tab, Tabs } from '@mui/material'
import AddDoctorOrganizationModal from './AddDoctorOrganizationModal'
import popupNotification from 'components/Notification/popupNotification'
import { DoctorService } from 'services/doctor.service'
import { OrganizationService } from 'services/organization.service'
import MrcTooltip from 'components/MrcTooltip'
import { ToolTipIcon } from 'assets/svg/tooltipIcon'
const tabs = ['Doctor', 'Organization']

const ShareMyRecords = () => {
    const [tab, setTab] = useState('Doctor')
    const [loader, setLoader] = useState(false)
    const [doctors, setDoctors] = useState([])
    const [doctorDataLoaded, setDoctorDataLoaded] = useState(false)
    const [organizationDataLoaded, setOrganizationDataLoaded] = useState(false)
    const [organizations, setOrganizations] = useState([])
    const [showAddDialog, setShowAddDialog] = useState(false)

    const getDoctors = async () => {
        const profileId = localStorage.getItem('profileid')
        const data = await GetDoctorsAPI(profileId)
        if (data) {
            if (data.message !== 'No Data Found') {
                setDoctors(data)
            }
        }
    }

    const getOrganizations = async () => {
        const { data: response } = await OrganizationService.getSharedOrganizations()
        if (response) {
            if (response.message !== 'No Data Found') {
                setOrganizations(response)
            }
        }
    }

    const getData = async () => {
        setLoader(true)
        if (tab === 'Organization' && !organizationDataLoaded) {
            await getOrganizations()
            setOrganizationDataLoaded(true)
        }
        if (tab === 'Doctor' && !doctorDataLoaded) {
            await getDoctors()
            setDoctorDataLoaded(true)
        }
        setLoader(false)
    }

    const handleAdd = async (item) => {
        setLoader(true)
        const payload =
            tab === 'Doctor'
                ? {
                      status: '1',
                      providerId: item.id,
                      profileId: localStorage.getItem('profileid'),
                  }
                : { organizationId: item.id, profileId: localStorage.getItem('profileid') }

        try {
            const { data: response } =
                tab === 'Doctor'
                    ? await DoctorService.addDoctor(payload)
                    : await OrganizationService.addOrganization(payload)
            if (response) {
                popupNotification(response.message || response?.data?.message, true)
                if (tab === 'Doctor') {
                    getDoctors()
                } else {
                    getOrganizations()
                }
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            popupNotification('Something went wrong', false)
        }
    }

    const handleDelete = async (id) => {
        setLoader(true)
        const profileId = localStorage.getItem('profileid')
        const myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')
        const requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
        }

        try {
            const response =
                tab === 'Doctor'
                    ? await RemoveDoctorAPI(profileId, id, requestOptions)
                    : await OrganizationService.removeOrganization({
                          profileId,
                          organizationId: id,
                      })
            popupNotification(response.message || response?.data?.message, true)
            if (tab === 'Doctor') {
                getDoctors()
            } else {
                getOrganizations()
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            popupNotification(error?.message, false)
        }
    }

    const handleShare = async (checkedMongoTables, selectedProfileIds, id) => {
        setLoader(true)
        const payload =
            tab === 'Doctor'
                ? {
                      profileIds: selectedProfileIds,
                      mongoDBTables: checkedMongoTables,
                      providers: ['' + id + ''],
                  }
                : {
                      profileIds: selectedProfileIds,
                      mongoDBTables: checkedMongoTables,
                      organizationId: id,
                  }
        try {
            const { data: response } =
                tab === 'Doctor'
                    ? await DoctorService.shareProfile(payload)
                    : await OrganizationService.shareOrganization(payload)
            if (response) {
                setLoader(false)
                popupNotification('Record Successfully Shared', true)
                if (tab === 'Doctor') {
                    getDoctors()
                } else {
                    getOrganizations()
                }
            }
        } catch (error) {
            setLoader(false)
            popupNotification('Something went wrong', false)
        }
    }

    useEffect(() => {
        getData()
    }, [tab])

    return (
        <div className="container-right">
            <div className="white-container sharemyrecords">
                <div style={{ position: 'relative' }}>
                    <Stack
                        direction="row"
                        sx={{
                            position: 'absolute',
                            left: '340px',
                            top: '-85px',
                            '@media (max-width: 786px)': {
                                left: '10px',
                                top: '-45px',
                            },
                        }}
                    >
                        <Button
                            style={{ background: '#176571' }}
                            variant="contained"
                            size="small"
                            startIcon={<LibraryAddOutlinedIcon />}
                            onClick={() => setShowAddDialog(true)}
                        >
                            {tab === 'Doctor' ? 'Add Doctor' : 'Add Organization'}
                        </Button>
                        <MrcTooltip
                            text={
                                <span>
                                    {[
                                        <b>NOTE: </b>,
                                        "ONLY Provider's which subscribe to MatchRite Care have access to read-only EHR record sharing capability.",
                                    ]}
                                </span>
                            }
                        >
                            {({ onOpen }) => (
                                <IconButton color="primary" aria-label="Info" onClick={onOpen}>
                                    <ToolTipIcon />
                                </IconButton>
                            )}
                        </MrcTooltip>
                    </Stack>
                </div>
                <Tabs
                    value={tab}
                    onChange={(_e, value) => setTab(value)}
                    sx={{
                        px: 2,
                        pr: 5,
                    }}
                >
                    {tabs.map((tab) => (
                        <Tab key={tab} label={tab} value={tab} />
                    ))}
                </Tabs>
                {loader ? (
                    <Loader pageName="sharemyrecords" />
                ) : (
                    <>
                        {tab === 'Doctor' && (
                            <Doctors
                                data={doctors}
                                onRemove={(id) => handleDelete(id)}
                                onShare={(checkedMongoTables, selectedProfileIds, id) =>
                                    handleShare(checkedMongoTables, selectedProfileIds, id)
                                }
                            />
                        )}
                        {tab === 'Organization' && (
                            <Organizations
                                data={organizations}
                                onRemove={(id) => handleDelete(id)}
                                onShare={(checkedMongoTables, selectedProfileIds, id) =>
                                    handleShare(checkedMongoTables, selectedProfileIds, id)
                                }
                            />
                        )}
                        {showAddDialog && (
                            <AddDoctorOrganizationModal
                                title={tab === 'Doctor' ? 'Add Your Doctor' : 'Add Your Organization'}
                                label={tab}
                                isOpen={showAddDialog}
                                onClose={() => setShowAddDialog(false)}
                                onAdd={(item) => handleAdd(item)}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default ShareMyRecords
