import React from 'react'
import { withRouter } from 'react-router-dom'
import clsx from 'clsx'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
// import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import { List } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { pageTitles } from 'use/pageTitles'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import withDynamicTitle from 'hoc/withDynamicTitle'
import { useSearchField } from 'hoc/SearchFieldProvider'
import './SideBar.scss'

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        fontSize: '1rem',
        fontWeight: 700,
    },
}))

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'unset',
    },
}))

const useStyles = makeStyles((theme) => ({
    sideList: {
        width: '100%',
        // select list items
        '& .MuiListItemText-root, & svg': {
            color: 'white',
        },
    },
    selected: {
        background:
            'linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.1) 20%, rgba(255,255,255,0.1) 100%)',
    },
    sideMenuListItem: {
        marginBottom: 0,
        padding: '0 10px',
        height: 45,

        '&:hover': {
            background: 'rgba(255,255,255,0.1) !important',
        },

        '& .MuiListItemIcon-root': {
            minWidth: 45,
        },

        '@media (min-height: 650px)': {
            height: 50,
        },
        '@media (min-height: 685px)': {
            height: 55,
        },

        [theme.breakpoints.down('sm')]: {
            padding: '0 15px',
            height: 45,
        },
    },
    subMenu: {
        '& .MuiListItemButton-root': {
            paddingLeft: '80px',

            [theme.breakpoints.down('sm')]: {
                paddingLeft: '70px',
            },
        },
    },
}))

const SideBar = ({ pageKey, title, history, onItemClick }) => {
    const classes = useStyles()
    const { recordType } = useSearchField()

    const [isSubMenuVisible, setShowMenu] = React.useState(false)

    const toggleShowMenu = () => {
        setShowMenu(!isSubMenuVisible)
    }

    const goToThisPage = (item, path) => {
        if (onItemClick) {
            onItemClick(item)
        }

        if (path) {
            return history.push(path)
        }
        history.push(`/home/${item}`)
    }
    const menuItems = [
        {
            title: 'myprofile',
            icon: <PersonOutlineOutlinedIcon />,
        },
        {
            title: 'dependents',
            icon: <PersonOutlineOutlinedIcon />,
        },
        {
            title: 'importrecords',
            icon: <ContentPasteGoOutlinedIcon />,
        },
        // {
        //     title: 'drvisitnotes',
        //     icon: <AssignmentOutlinedIcon />,
        // },
        {
            title: 'myrecords',
            icon: <EventNoteOutlinedIcon />,
            isSubMenuVisible,
            onClick: () => {
                toggleShowMenu()
                goToThisPage('myrecords')
            },
            subMenuItems: recordType?.map((type) => {
                return { title: type.name, path: `/home/myrecords/${type.tabUrl}` }
            }),
        },
        {
            title: 'share-records',
            icon: <ShareOutlinedIcon />,
        },
        {
            title: 'yourclaims',
            icon: <ReceiptLongOutlinedIcon />,
        },
        // {
        //     title: 'viewxray',
        //     icon: <GraphicEqIcon />,
        // },
        // {
        //     title: 'veteranhistory',
        //     icon: <FileCopyOutlinedIcon />,
        // },
        {
            title: 'disability',
            icon: <FileCopyOutlinedIcon />,
        },
        // {
        //     title: 'supportPage',
        //     icon: <EventNoteOutlinedIcon />,
        // },
        // {
        //     title: 'privacypolicy',
        //     icon: <EventNoteOutlinedIcon />,
        // },
    ]

    return (
        <>
            <List className={classes.sideList}>
                {menuItems.map(({ title: pageTitleKey, icon, subMenuItems, onClick, isSubMenuVisible }) => (
                    <React.Fragment key={pageTitleKey}>
                        <ListItem
                            key={pageTitleKey}
                            disablePadding
                            className={clsx(classes.sideMenuListItem, { [classes.selected]: pageTitleKey === pageKey })}
                        >
                            <StyledListItemButton
                                disableRipple
                                onClick={onClick ? onClick : () => goToThisPage(pageTitleKey)}
                            >
                                <ListItemIcon>{icon}</ListItemIcon>
                                <StyledListItemText
                                    className={classes.menuItemText}
                                    primary={pageTitles[pageTitleKey] || pageTitleKey}
                                />
                                {onClick && <>{subMenuItems && isSubMenuVisible ? <ExpandLess /> : <ExpandMore />}</>}
                            </StyledListItemButton>
                        </ListItem>
                        {subMenuItems && (
                            <Collapse className={classes.subMenu} in={isSubMenuVisible} timeout="auto" unmountOnExit>
                                <List disablePadding className={classes.sideList}>
                                    {subMenuItems.map(({ title: subTitle, icon, path }) => (
                                        <ListItem
                                            key={subTitle}
                                            disablePadding
                                            className={clsx(classes.sideMenuListItem, {
                                                [classes.selected]: subTitle === pageKey,
                                            })}
                                        >
                                            <StyledListItemButton
                                                className={classes.subMenuItem}
                                                key={subTitle}
                                                onClick={() => goToThisPage(subTitle, path)}
                                            >
                                                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                                                <StyledListItemText
                                                    className={classes.menuItemText}
                                                    primary={pageTitles[subTitle] || subTitle}
                                                />
                                            </StyledListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        )}
                    </React.Fragment>
                ))}
            </List>
        </>
    )
}

export default withDynamicTitle(withRouter(SideBar))
