import React from 'react'
import MrcButton from 'components/MrcButton'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

const PrimaryActionButton = styled(MrcButton)(({ theme }) => ({
    minHeight: '50px',
    width: '100%',
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
}))

const SecondaryActionButton = styled(MrcButton)(({ theme }) => ({
    minHeight: '50px',
    width: '100%',
    borderRadius: 0,
    color: theme.palette.common.black,
}))

const DeleteActionButton = styled(MrcButton)(({ theme }) => ({
    minHeight: '50px',
    width: '240px',
    // borderRadius: 0,
    // backgroundColor: '#FF9494',
    color: '#ff0000',
    // color: theme.palette.common.black,
    borderRadius: ' 9px',
    backgroundColor: '#FFEBEB',
    // opacity: '0.89',
    '&:hover': {
        backgroundColor: '#FF9090',
    },
}))

const FormSubmitButtons = ({
    submitTitle = 'Submit',
    cancelTitle = 'Cancel',
    disabled,
    onSubmit,
    onCancel,
    onDelete,
}) => (
    <Grid
        container
        sx={{
            '@media screen and (max-width: 768px)': {
                flexFlow: 'wrap',
            },
            flexFlow: 'nowrap',
            flex: 1,
        }}
    >
        <Grid container spacing={2}>
            <Grid item xs={6} lg={6} xl={6}>
                <PrimaryActionButton disabled={disabled} onClick={onSubmit} size="large">
                    {submitTitle}
                </PrimaryActionButton>
            </Grid>

            <Grid item xs={3} lg={2} xl={1} flex={1}>
                <SecondaryActionButton onClick={onCancel} size="large">
                    {cancelTitle}
                </SecondaryActionButton>
            </Grid>
        </Grid>
        {onDelete && (
            <Grid
                container
                justifyContent="flex-end"
                justify="flex-end"
                sx={{
                    '@media screen and (max-width: 768px)': {
                        flexBasis: '100%',
                        justifyContent: 'start',
                        marginTop: '15px',
                    },
                }}
            >
                <DeleteActionButton onClick={onDelete} size="large" startIcon={<DeleteOutlinedIcon />}>
                    Delete Account
                </DeleteActionButton>
            </Grid>
        )}
    </Grid>
)

export default FormSubmitButtons
