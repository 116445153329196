import React from 'react'
import TextField from '@mui/material/TextField'

import Wrapper from 'components/Wrapper'
import { pageTitles } from 'use/pageTitles'
import Loader from 'components/Loader/Loader'

class CovidPassport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        this.setState({ loading: false })
    }

    render() {
        if (this.state.loading) {
            return <Loader pageName="covidpassport" />
        }

        return (
            <div className="container-right">
                <div className="main-title">{pageTitles['covidpassport']}</div>
                <div className="white-container padding-28">
                    <div className="row">
                        <Wrapper>
                            <TextField label="SARS-CoV-2(COVID-19) mRNA-1273 vaccine" variant="outlined" />
                        </Wrapper>
                        <Wrapper>
                            <TextField label="Status" variant="outlined" />
                        </Wrapper>
                        <Wrapper>
                            <TextField label="Body site" variant="outlined" />
                        </Wrapper>
                        <Wrapper>
                            <TextField label="Route" variant="outlined" />
                        </Wrapper>
                        <Wrapper>
                            <TextField label="Clinician" variant="outlined" />
                        </Wrapper>
                        <Wrapper>
                            <TextField label="Alernative names" variant="outlined" />
                        </Wrapper>
                        <Wrapper>
                            <TextField label="FHIR source text" variant="outlined" />
                        </Wrapper>
                        <Wrapper>
                            {' '}
                            <TextField label="CVX Code 207" variant="outlined" />
                        </Wrapper>
                        <Wrapper>
                            {' '}
                            <TextField label="NDC/NHRIC" variant="outlined" />
                        </Wrapper>
                        <Wrapper>
                            {' '}
                            <TextField label="Data source" variant="outlined" />
                        </Wrapper>
                    </div>
                </div>
            </div>
        )
    }
}

export default CovidPassport
