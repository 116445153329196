import config from 'use/Config'
import mrcFetch from 'util/authFetch'

export async function GetProvidersAPI() {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/Providers/GetProviders`).then((response) => response.json())

    return data
}

export async function GetDoctorsAPI(profileid) {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/MyDoctors/GetDoctors?profileId=${profileid}`).then((response) =>
        response.json()
    )

    return data
}

export async function ConnectedEHRrecordsAPI(profileid) {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/MergeEHR/ConnectedEHRrecords?profileId=${profileid}`).then(
        (response) => response.json()
    )
    return data
}

export async function NewConnectedEHRrecordsAPI() {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/MergeEHR/get-connected-ehrs`).then((response) =>
        response.json()
    )

    return data
}

export async function GetAllEndpointsAPI(providerType, page = 1, search, limit) {
    let data = await mrcFetch(
        `${
            config.mrc.baseApiUrl
        }/EhrEndPoints/GetAllEndpoints?providerType=${providerType}&pageNumber=${page}&pageSize=${limit}${
            search ? `&search=${search}` : ''
        }`
    ).then((response) => response.json())

    return data
}

export async function GetEhrEndpoint(id) {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/EhrEndPoints/GetEndpointById?EhrEndPointId=${id}`).then((response) =>
        response.json()
    )
    return data
}

export async function GetAppointmentsAPI(profileid, providerId) {
    let data = await mrcFetch(
        `${config.mrc.baseApiUrl}/Appointments/GetAppointments?profileId=${profileid}&providerId=${providerId}`
    ).then((response) => response.json())

    return data
}

export async function GetProviderByProfileIdAPI(profileid) {
    let data = mrcFetch(`${config.mrc.baseApiUrl}/Providers/GetProviderByProfileId?profileId=${profileid}`).then(
        (response) => response.json()
    )

    return data
}

export async function DiagnosticReportByProfileIdAPI(profileid) {
    let data = await mrcFetch(
        `${config.mrc.baseApiUrl}/MergeEHR/DiagnosticReportByProfileId?profileId=${profileid}`
    ).then((response) => response.json())

    return data
}

export async function MedicationStatementByProfileIdAPI(profileid) {
    let data = await mrcFetch(
        `${config.mrc.baseApiUrl}/MergeEHR/MedicationStatementByProfileId?profileId=${profileid}`
    ).then((response) => response.json())

    return data
}

export async function ObservationByProfileIdAPI(profileid) {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/MergeEHR/ObservationByProfileId?profileId=${profileid}`).then(
        (response) => response.json()
    )

    return data
}

export async function DocumentReferenceByProfileIdAPI(profileid) {
    let data = await mrcFetch(
        `${config.mrc.baseApiUrl}/MergeEHR/DocumentReferenceByProfileId?profileId=${profileid}`
    ).then((response) => response.json())

    return data
}

export async function ConditionByProfileIdAPI(profileid) {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/MergeEHR/ConditionByProfileId?profileId=${profileid}`).then(
        (response) => response.json()
    )

    return data
}

export async function EncounterByProfileIdAPI(profileid) {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/MergeEHR/EncounterByProfileId?profileId=${profileid}`).then(
        (response) => response.json()
    )

    return data
}

export async function CarePlanProfileIdAPI(profileid) {
    let data = mrcFetch(`${config.mrc.baseApiUrl}/MergeEHR/CarePlanProfileId?profileId=${profileid}`).then((response) =>
        response.json()
    )

    return data
}

export async function ProceduresProfileIdAPI(profileid) {
    let data = mrcFetch(`${config.mrc.baseApiUrl}/MergeEHR/ProcedureByProfileId?profileId=${profileid}`).then(
        (response) => response.json()
    )

    return data
}

export async function ImmunizationByProfileIdAPI(profileid) {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/MergeEHR/ImmunizationByProfileId?profileId=${profileid}`).then(
        (response) => response.json()
    )

    return data
}

export async function AllergiesByProfileIdAPI(profileid) {
    let data = await mrcFetch(
        `${config.mrc.baseApiUrl}/MergeEHR/AllergyIntoleranceByProfileId?profileId=${profileid}`
    ).then((response) => response.json())
    return data
}

export async function ExplanationOfBenefitAPI(profileid) {
    let data = await mrcFetch(
        `${config.mrc.baseApiUrl}/BlueButton/ExplanationOfBenefit?profileId=${profileid}&providerType=BlueButton`
    ).then((response) => response.json())

    return data
}

export async function ShareProfileDataToDoctorAPI(requestOptions) {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/MyDoctors/ShareProfileDataToDoctor`, requestOptions).then(
        (response) => response.json()
    )

    return data
}

export async function GetuserAPI(email, requestOptions) {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/Login/Getuser?email=` + email, requestOptions).then(
        (response) => response.json()
    )

    return data
}

export async function UserRegisterAPI(requestOptions) {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/Login/UserRegister`, requestOptions).then((response) =>
        response.json()
    )

    return data
}

export async function AddDoctorsAPI(requestOptions) {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/MyDoctors/AddDoctors`, requestOptions).then((response) =>
        response.json()
    )

    return data
}

export async function BookAppointmentAPI(requestHeaders) {
    let data = await mrcFetch(`${config.mrc.baseApiUrl}/Appointments/BookAppointment`, requestHeaders)

    return data
}

export const DeleteEHRRecordAPI = async (profileId, delFacility, requestOptions) => {
    let data = await mrcFetch(
        `${config.mrc.baseApiUrl}/Cerner/DeleteEHRrecord?profileId=${profileId}&type=${delFacility}`,
        requestOptions
    ).then((response) => response.json())

    return data
}

export const RemoveDoctorAPI = async (profileId, providerId, requestOptions) => {
    let data = await mrcFetch(
        `${config.mrc.baseApiUrl}/MyDoctors/RemoveDoctor?profileId=` + profileId + '&providerId=' + providerId,
        requestOptions
    ).then((response) => response.json())

    return data
}

// Local storage

export function setLocalItem(key, value, encoded) {
    value = JSON.stringify(value)
    if (encoded) {
        value = btoa(value)
    }
    localStorage.setItem(key, value)
}

export function getLocalItem(key, decoded) {
    let value = localStorage.getItem(key)
    value = value ? JSON.parse(decoded ? atob(value) : value) : null
    return value
}

export function removeLocalItem(key) {
    localStorage.removeItem(key)
}
