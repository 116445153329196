import React, { useEffect } from 'react'
import appLogo from '../../../assets/logos/MatchRiteCare_logo_color_gradient_102220.jpg'
const RedirectUrl = () => {
    useEffect(() => {
        setTimeout(() => {
            const websiteValue = localStorage.getItem('isWebsite')
            const url = window.location.href
            const params = new URLSearchParams(url.split('?')[1])
            if (websiteValue)
                return window.location.replace(`${process.env.REACT_APP_PATIENT_URL}/home/importrecords?${params}`)
            else return window.location.replace(`matchritepatientapp://profile/myprofile?${params}`)
        }, [1500])
    }, [])
    // if (!params) return
    return (
        <div className="">
            <img
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 5,
                    bottom: 0,
                    left: 5,
                    zIndex: -10,
                    width: '75%',
                    margin: 'auto',
                }}
                src={appLogo}
                // src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
                alt=""
            />
            <div style={{ mx: 'auto', maxWidth: '80rem', textAlign: 'center', paddingTop: '3.5rem' }}>
                <h1 style={{ fontSize: '32px', color: 'black', fontWeight: 600, lineHeight: '2rem' }}>
                    Importing your record ...
                </h1>
            </div>
        </div>
    )
}

export default RedirectUrl
