import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import withDynamicTitle from 'hoc/withDynamicTitle'
import useMediaQuery from '@mui/material/useMediaQuery'

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            background: 'linear-gradient(180deg, white, #dddddd)',
            color: theme.palette.common.black,
            fontFamily: 'Montserrat',
            fontSize: theme.typography.pxToRem(16),
            padding: '10px 15px',
            maxWidth: 400,
            border: '1px solid #dadde9',
            marginTop: '0 !important',
            width: '400px',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '80vw',
            },
        },
    })
)

const MrcTooltip = ({ text, children }) => {
    const theme = useTheme()
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
    const [showTooltip, setShowTooltip] = useState(false)

    const handleTooltipClose = () => {
        setShowTooltip(false)
    }

    const handleTooltipOpen = () => {
        setShowTooltip(true)
    }

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Box style={{ display: 'inline-block' }}>
                <StyledTooltip
                    PopperProps={{ disablePortal: true }}
                    onClose={handleTooltipClose}
                    open={isMediumScreen ? undefined : showTooltip}
                    disableFocusListener={!isMediumScreen}
                    disableHoverListener={!isMediumScreen}
                    disableTouchListener={!isMediumScreen}
                    placement={isMediumScreen ? 'right' : 'bottom'}
                    title={text}
                >
                    {typeof children === 'function' ? children({ onOpen: handleTooltipOpen }) : children}
                </StyledTooltip>
            </Box>
        </ClickAwayListener>
    )
}

export default withDynamicTitle(MrcTooltip)
