import axios from 'axios'
// config
import config from 'use/Config'

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: config.mrc.baseApiUrl })

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
)

export default axiosInstance
