import React from 'react'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined'
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'

import SideBar from 'components/layout/SideBar/SideBar'

import './Claims.scss'
import { colors } from '@mui/material'

class Claims extends React.Component {
    render() {
        return (
            <div className="_container claims">
                <SideBar title="claims" />
                <div className="container-right">
                    <div className="main-title">Claims</div>
                    <div className="white-container" style={{ backgroundColor: colors.primary }}>
                        <div
                            style={{
                                display: 'flex',
                                margin: 10,
                                flexWrap: 'wrap',
                            }}
                        >
                            <div
                                class="card"
                                style={{ backgroundColor: '#eafaea' }}
                                onClick={() => this.props.history.push('/home/yourclaims')}
                            >
                                <AccountCircleOutlinedIcon className="icon-card" />
                                <div className="title-card">Your Claims</div>
                            </div>
                            <div
                                class="card"
                                style={{ backgroundColor: '#fefce4' }}
                                onClick={() => this.props.history.push('/home/idcard')}
                            >
                                <CreditCardOutlinedIcon className="icon-card" />
                                <div className="title-card">ID Card</div>
                            </div>
                            <div class="card" style={{ backgroundColor: '#fde7e5' }}>
                                <DescriptionOutlinedIcon className="icon-card" />
                                <div className="title-card">Claims Document</div>
                            </div>
                            <div class="card" style={{ backgroundColor: '#e5f3fd' }}>
                                <HelpOutlineOutlinedIcon className="icon-card" />
                                <div className="title-card">Copay Info</div>
                            </div>
                            <div class="card" style={{ backgroundColor: '#fbe8ce' }}>
                                <AccessAlarmOutlinedIcon className="icon-card" />
                                <div className="title-card">Cost & Spending</div>
                            </div>
                            <div class="card" style={{ backgroundColor: '#e7feff' }}>
                                <LocalHospitalOutlinedIcon className="icon-card" />
                                <div className="title-card">Find a Doctor</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Claims
