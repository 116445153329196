import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { pageTitles, pageSubTitles, matchPageTitles } from 'use/pageTitles'

function withDynamicTitle(WrappedComponent) {
  return (props) => {
    const { pathname } = useLocation()
    const [pageKey, title, subTitle] = useMemo(() => {
      const pageKey = pathname.replace('/home/', '')
      let title = pageTitles[pageKey]
      let subTitle = pageSubTitles[pageKey]
      if (title) {
        return [pageKey, title, subTitle]
      }
      const match = Object
        .entries(matchPageTitles)
        .find(([key]) => {
          const regEx = new RegExp(key)
          return regEx.test(pathname)
        })
      return [
        match ? match[1] : pageKey,
        match ? pageTitles[match[1]] : title,
        match ? pageSubTitles[match[1]] : subTitle,
      ]
    }, [pathname])

    return (
      <WrappedComponent {...props} pageKey={pageKey} title={title} subTitle={subTitle} />
    )
  }
}

export default withDynamicTitle
