import React, { useEffect,  useState } from 'react'
import { getCurrentDate } from 'use/Helper'
import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import AdjustIcon from '@mui/icons-material/Adjust'

const StyledChip = styled(Chip)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.625rem',

    '& svg': {
      display: 'none',
    }
  },
}));

function HeaderDateChip() {
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date())
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  return (
    <StyledChip
      avatar={<AdjustIcon style={{ width: '20px' }} />}
      label={`${getCurrentDate()}, ${date.toLocaleTimeString()}`}
      variant="Filled"
    />
  )
}

export default HeaderDateChip
