import React, { useMemo, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import useStyles, { tabStyles, tabsStyles } from './Tabs.styles'
import TabPanel from './TabPanel'

import Labs from '../../pages/home/Records/Labs'
import Vitals from '../../pages/home/Records/Vitals'
import Conditions from '../../pages/home/Records/Conditions'
import Medications from '../../pages/home/Records/Medications'
import Immunization from '../../pages/home/Records/Immunization'
import TreatmentPlan from '../../pages/home/Records/TreatmentPlan'
import Allergies from '.../../pages/home/Records/Allergies'
import Procedures from '../../pages/home/Records/Procedures'
import DrvVisitNotes from '../../pages/home/DrvVisitNotes/DrvVisitNotes'
import NewRecordType from '../../pages/home/Records/NewRecordType'

const MRCTab = styled((props) => <Tab {...props} />)(tabStyles)
const MRCTabs = styled((props) => <Tabs {...props} />)(tabsStyles)

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
}

function ScrollableTabs({ initialIndex, tabs, onChange }) {
    const classes = useStyles()
    const [value, setValue] = useState(initialIndex || 0)

    const handleChange = (_, newValue) => {
        onChange(newValue)
    }

    useEffect(() => {
        setValue(initialIndex)
    }, [initialIndex])

    const getComponent = (selectedTab) => {
        switch (selectedTab) {
            case 'vitals':
                return <Vitals />

            case 'medications':
                return <Medications />

            case 'treatmentplan':
                return <TreatmentPlan />

            case 'conditions':
                return <Conditions />

            case 'labs':
                return <Labs />

            case 'immunization':
                return <Immunization />

            case 'allergies':
                return <Allergies />

            case 'drvisitnotes':
                return <DrvVisitNotes />

            case 'procedures':
                return <Procedures />

            default:
                return <NewRecordType />
        }
    }

    const [tabsNode, panelsNode] = useMemo(
        () =>
            tabs.reduce(
                ([tabs, panels], val, index) => {
                    const { id, name } = val
                    const content = getComponent(val.tabUrl)
                    const tab = <MRCTab key={id} label={name} {...a11yProps(index)} />
                    const panel = (
                        <TabPanel key={id} value={value} index={index}>
                            {content}
                        </TabPanel>
                    )
                    return [
                        [...tabs, tab],
                        [...panels, panel],
                    ]
                },
                [[], []]
            ),
        [tabs, value]
    )

    return (
        <div className={classes.root}>
            {panelsNode && tabsNode ? (
                <>
                    <MRCTabs
                        value={value}
                        onChange={handleChange}
                        // variant="scrollable"
                        // scrollButtons="auto"
                        // aria-label="scrollable auto tabs"
                    >
                        {tabsNode}
                    </MRCTabs>
                    {panelsNode}
                </>
            ) : null}
        </div>
    )
}

ScrollableTabs.propTypes = {
    initialIndex: PropTypes.number,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
            content: PropTypes.node.isRequired,
        })
    ),
}

export default withRouter(ScrollableTabs)
