import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import 'react-phone-input-2/lib/style.css'
import Button from '@mui/material/Button'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuItem from '@mui/material/MenuItem'

// import { getLocalItem } from 'services/Services'

import Loader from 'components/Loader/Loader'
import FormGroupHeader from 'components/FormGroupHeader/FormGroupHeader'
import FormSubmitButtons from 'components/FormSubmitButtons'
import TextField from 'components/Form/TextField'
import { Grid } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LoginService } from 'services/login.service'

const relationData = [
    { label: 'Self', value: 'self' },
    { label: 'Spouse', value: 'spouse' },
    { label: 'Kids', value: 'kids' },
]

const BookAppointment = (props) => {
    const [preferredDate, setPreferredDate] = useState(null)
    const [preferredTime, setPreferredTime] = useState(null)
    const [loginData, setLoginData] = useState({})
    const [saving, setSaving] = useState(false)

    const getProfileData = async (email) => {
        try {
            if (!email) return
            setSaving(true)
            const { data: loginData } = await LoginService.getUser(email)
            if (loginData) {
                setSaving(false)
                setLoginData({
                    ...loginData.profile,
                })
            }
        } catch (error) {
            setSaving(false)
            console.log('error', error)
        }
    }

    React.useEffect(() => {
        // // Retrieves profile data from local storage
        // const data = getLocalItem('profileData', true)
        // if (data) {
        //     setLoginData({
        //         ...data.profile,
        //     })
        // }
        const email = localStorage.getItem('email')
        getProfileData(email)
    }, [])

    // Ensures all necessary user info has been entered for submission
    const validateForm = () => true

    // Updates patient information in matchrite database
    const handleSubmitPress = () => {}

    const handleOnCancel = () => {
        props.history.push('/home/share-records')
    }

    if (saving) {
        return <Loader pageName="BookAppointment" />
    }

    return (
        <div className="container-right">
            <div className="main-title with-icon">
                <Button startIcon={<ChevronLeftIcon />} onClick={() => props.history.push('/home/share-records')}>
                    Return
                </Button>
            </div>
            <div className="white-container BookAppointment padding-28">
                <br />
                <br />
                <FormGroupHeader>Request Information</FormGroupHeader>

                <br />
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextField select required label="Appointment type">
                            {relationData.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <DatePicker
                            required
                            label="Preferred date period"
                            inputFormat="MM/dd/yyyy"
                            value={preferredDate}
                            onChange={(d) => setPreferredDate(d)}
                            renderInput={(params) => <TextField {...params} />}
                            inputProps={{ min: new Date().toISOString().split('T')[0] }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <TimePicker
                            required
                            label="Preferred time period"
                            value={preferredTime}
                            onChange={(d) => setPreferredTime(d)}
                            renderInput={(params) => <TextField {...params} />}
                            inputProps={{ min: new Date().toISOString().split('T')[1] }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <TextField select required label="For whom is this appointment">
                            {relationData.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <TextField select required label="Insurance">
                            {relationData.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} md={8} lg={9}>
                        <TextField required label="Reason for consultation" />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField required label="Define the problem" multiline rows={6} />
                    </Grid>
                </Grid>

                <br />

                {/* <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <PrimaryActionButton disabled={!validateForm()} onClick={handleSubmitPress}>
                            Book your appointment request
                        </PrimaryActionButton>
                    </Grid>

                    <Grid item xs={4}>
                        <SecondaryActionButton>Cancel</SecondaryActionButton>
                    </Grid>
                </Grid> */}

                <FormSubmitButtons disabled={!validateForm()} onSubmit={handleSubmitPress} onCancel={handleOnCancel} />
            </div>
        </div>
    )
}
export default withRouter(BookAppointment)
