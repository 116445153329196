import React from 'react'

function TermsAndCondition() {
    return (
        <div className="scrollableContent">
            <div>
                <u>Please read these terms and conditions carefully before using our Services. </u>
                <p>
                    HIPAA DISCLAIMER: When accessing the Services, you may engage with the Services in a manner
                    regulated by the Health Insurance Portability and Accountability Act (“HIPAA”), such as providing
                    protected health information (“PHI”) through the Services. These Terms are not intended for
                    compliance with HIPAA, nor should you take such notice as a reflection of your rights under HIPAA.
                    These Terms do not apply to the confidential exchange of your PHI or our sharing of your PHI with
                    providers. In certain circumstances, MatchRite is considered a business associate as that term is
                    defined under HIPAA. In such circumstances, MatchRite enters into a business associate agreement
                    (“BAA”) with covered entities which outlines its compliance obligations under HIPAA. Please see our
                    HIPAA Privacy Practices for more information.
                </p>
                <b>Table of Contents</b>
                <ol role="list" type="A" className='termsList'>
                    <li>
                        <a href="#A">Application</a>
                    </li>
                    <li>
                        <a href="#B">Acknowledgment</a>
                    </li>
                    <li>
                        <a href="#C">License of Identifiable Data </a>
                    </li>
                    <li>
                        <a href="#D">Ownership of Aggregated/Anonymized Data </a>
                    </li>
                    <li>
                        <a href="#E">Proprietary Rights and Your Use of the Services </a>
                    </li>
                    <li>
                        <a href="#F">Links to Other Applications/Websites/Services </a>
                    </li>
                    <li>
                        <a href="#G">Your Communications Via the Services </a>
                    </li>
                    <li>
                        <a href="#H">User Contributions </a>
                    </li>
                    <li>
                        <a href="#I">Content Standards </a>
                    </li>
                    <li>
                        <a href="#J">Electronic Communications </a>
                    </li>
                    <li>
                        <a href="#K">Permitted Uses </a>
                    </li>
                    <li>
                        <a href="#L">Monitoring and Enforcement; Termination </a>
                    </li>
                    <li>
                        <a href="#M">Reliance on Information Posted </a>
                    </li>
                    <li>
                        <a href="#N">Limitation of Liability </a>
                    </li>
                    <li>
                        <a href="#O">App Stores </a>
                    </li>
                    <li>
                        <a href="#P">Linking to Other Sites </a>
                    </li>
                    <li>
                        <a href="#Q">Disclaimer </a>
                    </li>
                    <li>
                        <a href="#R">Indemnification </a>
                    </li>
                    <li>
                        <a href="#S">Governing Law </a>
                    </li>
                    <li>
                        <a href="#T">Injunctive Relief </a>
                    </li>
                    <li>
                        <a href="#U">Dispute Resolution </a>
                    </li>
                    <li>
                        <a href="#V">Minimum Age </a>
                    </li>
                    <li>
                        <a href="#W">Changes to Terms of Service </a>
                    </li>
                    <li>
                        <a href="#X">Other Terms </a>
                    </li>
                    <li>
                        <a href="#Y">Contact</a>
                    </li>
                </ol>
            </div>
            <div id="A">
                <u>A. Application</u>
                <p>
                    The website and mobile applications on which this Terms of Use (“Terms”) appear are owned and
                    operated by MatchRite Care LLC (collectively, “MatchRite,” “we,” “our,” or “us”). For purposes of
                    these Terms, “Site” includes websites, mobile applications, and other interactive properties through
                    which you may engage with the Services. “You” or “your” means the person accessing the Site (“User”)
                    and may also include any persons that allow others to provide information about themselves to us.
                    The Site may provide information, documents, tools, products, services, accounts, offers,
                    suggestions on the best way to use our products, and/or information on where to purchase our
                    products (referred to collectively will the entirety of the Site as the “Service” or “Services”).
                </p>
                <p>
                    THESE TERMS CONSTITUTE A BINDING AGREEMENT BETWEEN YOU AND US. PLEASE READ CAREFULLY THROUGH ALL
                    SECTIONS OF THESE TERMS. YOUR ACCESS TO AND USE OF THE SITE IS SUBJECT TO THESE TERMS AND ALL
                    APPLICABLE LAWS AND WE RESERVE THE RIGHT TO TERMINATE YOUR ACCESS TO THE SITE IF YOU VIOLATE THESE
                    TERMS. BY CLICKING ON LINKS WITHIN THE SITE OR WEBPAGES BEYOND THE SITE’S HOMEPAGE, BY CLICKING ON A
                    BOX OR ICON, OR BY DOWNLOADING A MOBILE APPLICATION, YOU AGREE TO THESE TERMS WHETHER OR NOT YOU
                    COMPLETE A TRANSACTION WITH US AND WHETHER OR NOT YOU COMPLETE YOUR TRANSACTION ON THE SITE OR
                    THROUGH OTHER CHANNELS, SUCH AS BY TELEPHONE, EMAIL, FACSIMILE OR OTHERWISE. IF YOU DO NOT AGREE
                    WITH THESE TERMS, DO NOT ACCESS OR OTHERWISE USE THE SITE, ANY SERVICES AVAILABLE THROUGH THIS SITE,
                    OR ANY INFORMATION CONTAINED ON THIS SITE.
                </p>
                <p>
                    <b>MANDATORY ARBITRATION NOTICE AND CLASS ACTION AND JURY TRIAL WAIVER.</b>
                    These Terms contain a mandatory (binding) arbitration provision and class action and jury trial
                    waiver clauses. Except for certain types of disputes described in the arbitration section below or
                    where prohibited by applicable law, you agree that disputes between you and us regarding your use of
                    the Site or Services will be resolved by binding, individual arbitration and you waive your right to
                    participate in a class action lawsuit or class-wide arbitration, including as a class
                    representative. The arbitrator’s decision will be subject to very limited review by a court. You
                    will be entitled to a fair hearing, but the arbitration procedures are simpler and more limited than
                    rules applicable in Court. For more details, see below.
                </p>
                <p>
                    We may make changes to the content available on the Site at any time. We can change, update, add, or
                    remove provisions of these Terms at any time by posting the updated Terms on the Site. We will make
                    commercially reasonable efforts to notify you of any material changes to these Terms however we are
                    not obligated to. You waive any right you may have to receive specific notice of such changes to
                    these Terms except for changes to our agreement to arbitration, which is discussed more fully below.
                    By using the Site after we have updated the Terms, you are agreeing to the then-current Terms. You
                    are responsible for regularly reviewing these Terms.
                </p>
                <p>
                    In addition to these Terms, your use of certain Services may be governed by additional agreements.
                </p>
            </div>
            <div id="B">
                <u>B. ACKNOWLEDGMENT</u>
                <p>
                    <p>
                        Your access to and use of the Services is conditioned on your acceptance of and compliance with
                        these Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others
                        who access or use the Services. By accessing or using the Services you agree to be bound by
                        these Terms and Conditions.
                        <u>
                            <b>
                                If you disagree with any part of these Terms and Conditions, do not access the Services.
                            </b>
                        </u>
                    </p>
                    <p>
                        Your access to and use of the Services is also conditioned on your acceptance of and compliance
                        with our Privacy Policy. Our Privacy Policy describes our policies and procedures on the
                        collection, use, and disclosure of your personal information when you use the Services and tells
                        you about your privacy rights and how the law protects you. Please read our Privacy Policy
                        carefully before using our Services.
                    </p>
                    <p>
                        We reserve the right to withdraw or amend this Site, and any Services or Materials (defined
                        below) we provide on the Site, at our sole discretion and without notice. We will not be liable
                        if, for any reason, any part of the Site is unavailable at any time or for any period. From time
                        to time, in our sole discretion and without notice, we may restrict access to some parts of the
                        Site, or the entire Site, to Users, including registered Users.
                    </p>
                    <p>You are responsible for both:</p>
                    <ul>
                        <li style={{ listStyle: 'none' }}>
                            Making all arrangements necessary for you to have access to the Site.
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Ensuring that all persons who access the Site through your internet connection are aware of
                            these Terms and comply with them.
                        </li>
                    </ul>
                    To access the Site or certain of the resources it offers, you may be asked to provide certain
                    registration details or other information, and select a username and password (“Account”). It is a
                    condition of your use of the Site that all the information you provide on the Site is correct,
                    current, and complete, and that you have the authority to provide such information to us.{' '}
                    <p>
                        If you choose, or are provided with, a username, password, or any other piece of information as
                        part of our security procedures, you must treat such information as confidential, and you must
                        not disclose it to any other person or entity. You also acknowledge that your account is
                        personal to you and agree not to provide any other person with access to this Site or portions
                        of it using your username, password, or other security information. The security and
                        confidentiality of your username and password is your sole responsibility. You shall ensure that
                        no unauthorized person shall have access to your Account, username, or password and shall
                        immediately notify us of any unauthorized access or use of which you become aware. You may not
                        transfer your Account to anyone without the express prior written consent of MatchRite. You
                        agree to bear all responsibility and liability for all actions under or through your Account as
                        well as all fees and/or charges incurred from the use of your Account, whether authorized or
                        unauthorized.
                    </p>
                    <p>
                        You agree to notify us immediately of any unauthorized access to or use of your username or
                        password or any other breach of security. You also agree to ensure that you sign out of or exit
                        from your account at the end of each session. You should use particular caution when accessing
                        your account from a public or shared computer so that others are not able to view or record your
                        password or other personal information.
                    </p>
                    We have the right to disable any username, password, or other identifier, whether chosen by you or
                    provided by us, at any time in our sole discretion for any reason, including if, in our opinion, you
                    have violated any provision of these Terms.
                </p>
            </div>
            <div id="C">
                <u>C. LICENSE OF IDENTIFIABLE DATA (“Personal Information”)</u>
                <p>
                    By providing Personal Information to MatchRite via the Site and or Services, you are granting
                    MatchRite a revocable, limited, license to use such Personal Information consistent with our Privacy
                    Policy and or applicable agreement, such as a Business Associate Agreement (BAA), Data Processing
                    Agreement (DPA), or Master Service Agreement (MSA). If your Personal Information is the subject of
                    an applicable BAA, DPA, or MSA, please review such agreement for your rights and MatchRite’s
                    obligations in regard to processing and retention.
                </p>
            </div>
            <div id="D">
                <u>D. OWNERSHIP OF AGGREGATED/ANONYMIZED DATA</u>
                <p>
                    MatchRite may deidentify and aggregate the Personal Information you provide via the Site and
                    Services to such an extent that it is no longer reasonably capable of being linked to an
                    identifiable person, at which point it is no longer considered Personal Information. MatchRite uses
                    deidentified and aggregated data to generate insights to provide as part of the Services. MatchRite
                    shall have exclusive ownership of aggregated and or anonymized data and the insights generated from
                    such data.
                </p>
            </div>
            <div id="E">
                <u>E. PROPRIETARY RIGHTS AND YOUR USE OF THE SERVICES:</u>
                <p>
                    Unless otherwise specified in these Terms, all information and screens appearing on this Site are
                    the sole property of us or our subsidiaries and affiliates, and other parties. We provide content
                    through the Site that is copyrighted or contains protectable trademarks of us or our third-party
                    licensors and suppliers (collectively, the “Materials”). Materials may include documents, services,
                    software, site design, text, graphics, logos, video, images, icons, and other content, as well as
                    the arrangement thereof.
                    <p>
                        Subject to these Terms, we hereby grant to you a revocable, limited, personal, non-exclusive,
                        and non-transferable license to use, view, print, display, and download the Materials for the
                        sole purpose of viewing them on a stand-alone personal computer or mobile device and to use this
                        Site solely for your personal use. Except for the foregoing license and as otherwise required or
                        limited by applicable law, you have no other rights in the Site or any Materials and you may not
                        modify, edit, copy, reproduce, create derivative works of, reverse engineer, alter, enhance, or
                        in any way exploit any of the Site or Materials in any manner or for any purpose that would
                        constitute infringement of our, our licensors’, or the Site’s other user’s intellectual property
                        rights. All rights not expressly granted herein are reserved.
                    </p>
                    <p>
                        If you download or print a copy of the Materials for personal use, you must retain all copyright
                        and other proprietary notices contained therein and you expressly acknowledge that such use does
                        not give you ownership in the Materials or Services. You agree not to circumvent, disable, or
                        otherwise interfere with security-related features of the Service or features that prevent or
                        restrict use or copying of any Materials or enforce limitations on use of the Service or the
                        Materials therein. You shall not challenge or assist any third party to dispute or challenge
                        MatchRite’s or its licensors’ ownership of such rights or the validity or enforceability of such
                        rights.
                    </p>
                    <p>
                        Access to the Services may include hardware, software, and other proprietary materials from
                        MatchRite’s third party suppliers that are subject to license rights, terms, and restrictions
                        (collectively, “Third-Party Licenses”). In such event, your rights to the Services are expressly
                        subordinate and subject to the Third-Party Licenses and you must comply with Third-Party
                        Licenses. If MatchRite’s rights provided by a third-party supplier are limited, suspended, or
                        terminated for any reason, your rights shall also be so limited, suspended or terminated. To the
                        extent of any conflict between the terms of these Terms and Conditions and the terms of a
                        Third-Party License, the terms of the Third Party-License shall control with respect to the
                        applicable third-party product.
                    </p>
                    <p>
                        If you breach any of these Terms, the above license will terminate automatically and you must
                        immediately destroy any downloaded or printed Materials.
                    </p>
                </p>
            </div>
            <div id="F">
                <u>F. LINKS TO OTHER APPLICATIONS/WEBSITES/SERVICES: </u>
                <p>
                    Our Services may contain links to third-party websites or services that are not owned or controlled
                    by us. We have no control over and assume no responsibility for the content, privacy policies, or
                    practices of any third-party websites or services.
                    <p>
                        You further acknowledge and agree that we shall not be responsible or liable, directly or
                        indirectly, for any damage or loss caused or alleged to be caused by or in connection with the
                        use of or reliance on any such content, goods, or services available on or through any such web
                        sites, services or applications (“Third-Party Content”). We strongly advise you to read the
                        terms and conditions and privacy policies of any third-party websites, services, or applications
                        that you visit or use. We shall not be responsible or liable, directly or indirectly, for any
                        damage or loss caused or alleged to be caused by or in connection with use of or reliance on any
                        Third-Party Content.
                    </p>
                </p>
            </div>
            <div id="G">
                <u>G. YOUR COMMUNICATIONS VIA THE SERVICES: </u>
                <p>
                    By forwarding any content or communications to us through the Site or by other electronic means, you
                    thereby grant us a perpetual, royalty-free, fully paid-up, world-wide, irrevocable, nonexclusive,
                    freely transferable, and freely sublicensable license to use, reproduce, modify, adapt, publish,
                    translate, create derivative works from, redistribute, and display such content and communications
                    in any form for the purposes of providing the Services and any purpose tangentially related to the
                    Services. No compensation will be paid to you with respect to our or our sublicensees’ use of your
                    communications. By providing or submitting content, including via User Contributions as described
                    and defined in the next section, you represent and warrant that you own or otherwise control all of
                    the rights to your submitted content and communications as described in this section, including all
                    the rights necessary for you to submit the content and communications and grant the license above.
                </p>
            </div>
            <div id="H">
                <u>H. USER CONTRIBUTIONS: </u>
                <p>
                    The Services may contain interactive features (collectively, “Interactive Services”) that allow
                    users to post, submit, publish, display, or transmit to other users or other persons (hereinafter,
                    “post”) content or materials (collectively, “User Contributions”) on or through the Site. All User
                    Contributions must comply with the Content Standards set out in these Terms. User Contributions are
                    the sole responsibility of the User that submitted that User Contribution. MatchRite does not
                    control User Cotributions generated or provided by Users and is under no circumstances responsible
                    or liable in any way for any User Contribution that is made available through the Services ..
                    <p>
                        Please note, any User Contribution you post to the Site will be considered non-confidential and
                        non-proprietary. We encourage you to refrain from posting PHI to public portions of the
                        Services. This section does not apply to PHI you provide confidentially for providers to access.
                    </p>
                    <p>
                        By providing any User Contribution on the Site, you grant us and our affiliates and service
                        providers, and each of their and our respective licensees, successors, and assigns the right to
                        use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties
                        any such material for any purpose, or where applicable, for purposes you have consented to.
                    </p>
                    <p>You represent and warrant that: </p>
                    <ul>
                        <li style={{ listStyle: 'none' }}>
                            You own or control all rights in and to the User Contributions and have the right to grant
                            the license granted above to us and [our affiliates and service providers, and each of their
                            and] our respective licensees, successors, and assigns.{' '}
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            All of your User Contributions do and will comply with these Terms.
                        </li>
                    </ul>
                    <p>
                        You understand and acknowledge that you are responsible for any User Contributions you submit or
                        contribute, and you, not us, have full responsibility for such content, including its legality,
                        reliability, accuracy, and appropriateness.
                    </p>
                    <p>
                        We are not responsible or liable to any third party for the content or accuracy of any User
                        Contributions posted by you or any other user of the Site. MatchRite does not endorse any User
                        Contribution, and does not warrant or guarantee the integrity, truthfulness, accuracy, quality,
                        authenticity, or identity of any User Contribution.
                    </p>
                </p>
            </div>
            <div id="I">
                <u>I. CONTENT STANDARDS: </u>
                <p>
                    These content standards apply to any and all User Contributions and use of Site to the extent
                    applicable to the Services. User Contributions must in their entirety comply with all applicable
                    federal, state, local, and international laws and regulations. Without limiting the foregoing, User
                    Contributions must not:
                    <ul>
                        <li style={{ listStyle: 'none' }}>
                            Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing,
                            violent, hateful, inflammatory, or otherwise objectionable.
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Promote sexually explicit or pornographic material, violence, or discrimination based on
                            race, sex, religion, nationality, disability, sexual orientation, or age.
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Infringe any patent, trademark, trade secret, copyright, or other intellectual property or
                            other rights of any other person.
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Violate the legal rights (including the rights of publicity and privacy) of others or
                            contain any material that could give rise to any civil or criminal liability under
                            applicable laws or regulations or that otherwise may be in conflict with these Terms and our
                            Privacy Policy.
                        </li>
                        <li style={{ listStyle: 'none' }}>Be likely to deceive any person.</li>
                        <li style={{ listStyle: 'none' }}>
                            Promote any illegal activity, or advocate, promote, or assist any unlawful act.
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm,
                            or annoy any other person.
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Impersonate any person or misrepresent your identity or affiliation with any person or
                            organization.
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Involve commercial activities or sales, such as contests, sweepstakes, and other sales
                            promotions, barter, or advertising.
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Give the impression that they emanate from or are endorsed by us or any other person or
                            entity, if this is not the case.
                        </li>
                    </ul>
                </p>
            </div>
            <div id="J">
                <u>J. ELECTRONIC COMMUNICATIONS: </u>
                <p>
                    By using the Site and/or the Services, you consent to receiving electronic communications, including
                    electronic notices, from us. These electronic communications may include notices about applicable
                    fees and charges, transactional information and other information concerning or related to the
                    Services, Site, and/or Materials. These electronic communications are part of your relationship with
                    us. You agree that any notices, agreements, disclosures or other communications that we send you
                    electronically will satisfy any legal communication requirements, including that such communications
                    be in writing.
                </p>
            </div>
            <div id="K">
                <u>K. PERMITTED USES: </u>
                <p>
                    By accessing or using the Site and Services, you agree that:
                    <ul>
                        <li style={{ listStyle: 'none' }}>
                            Your use of the Services are subject to and governed by these Terms;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            You will only access or use the Services and transact business with us if you are at least
                            eighteen (18) years old;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            You will use the Site solely for its Services offered in the normal course of business;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            You will always act in accordance with the law and custom, and in good faith;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            You will comply with and be bound by these Terms as they appear on the Site each time you
                            access and use the Site and Services;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Each use of the Site by you indicates and confirms your agreement to be bound by these
                            Terms; and
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            These Terms are a legally binding agreement between you and us that will be enforceable
                            against you.
                        </li>
                    </ul>
                    <p>You further agree to not use the Site and Services in any way that:</p>
                    <ul>
                        <li style={{ listStyle: 'none' }}>
                            Changes or alters the Site or content or Services that may appear on the Site;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Impairs in any way the integrity or operation of the Site;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Interferes with or induces a breach of the contractual relationships between us and our
                            employees;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Is in any way unlawful or prohibited, or that is harmful or destructive to anyone or their
                            property;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Transmits any advertisements, solicitations, schemes, spam, flooding, or other unsolicited
                            email and commercial communications;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Transmits any harmful or disabling computer codes or viruses;
                        </li>
                        <li style={{ listStyle: 'none' }}>Harvests email addresses from the Site;</li>
                        <li style={{ listStyle: 'none' }}>
                            Transmits unsolicited email to the Site or to anyone whose email address includes the domain
                            name of the Site;
                        </li>
                        <li style={{ listStyle: 'none' }}>Interferes with our network services;</li>
                        <li style={{ listStyle: 'none' }}>
                            Attempts to gain unauthorized access to our network services;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Suggests an express or implied affiliation or relationship with us without our express
                            written permission;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Impairs or limits our ability to operate the Site or any other person’s ability to access
                            and use the Site;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Unlawfully impersonates or otherwise misrepresents your affiliation with any person or
                            entity;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Transmits or uploads violent, obscene, sexually explicit, discriminatory, hateful,
                            threatening, abusive, defamatory, offensive, harassing, or otherwise objectionable content
                            or images;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Dilutes or depreciates our or any of our affiliates’ name and reputation;
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Transmits or uploads content or images that infringe upon any third party’s intellectual
                            property rights or right to privacy; or
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            Unlawfully transmits or uploads any confidential, proprietary or trade secret information.
                        </li>
                    </ul>
                    We have no obligation, but maintain the right, to monitor the Site. This list of prohibited
                    activities provides examples and is not complete or exclusive. We reserve the right to terminate
                    access to your account and or your ability to use this Site (or the Materials) with or without cause
                    and with or without notice, for any reason or no reason, or for any action that we determine is
                    inappropriate or disruptive to this Site or to any other user of this Site and/or Materials. We may
                    report to law enforcement authorities any actions that may be illegal, and any reports we receive of
                    such conduct. When legally required or at our discretion, we will cooperate with law enforcement
                    agencies in any investigation of alleged illegal activity on this Site or on the Internet, which may
                    include disclosing any information we obtain. In addition, we may disclose information we obtain as
                    necessary or appropriate to operate or improve the Site, to protect us and/or our Site users, or for
                    any other purpose that the law permits.
                </p>
            </div>
            <div id="L">
                <u>L. MONITORING AND ENFORCEMENT; TERMINATION:</u>
                <p>
                    We may terminate or suspend your access immediately, without prior notice or liability, for any
                    reason whatsoever, including without limitation if you breach these Terms and Conditions. More
                    specifically, we have the right
                </p>
                <ul>
                    <li style={{ listStyle: 'none' }}>
                        Remove or refuse to post any User Contributions for any or no reason in our sole discretion.
                    </li>
                    <li style={{ listStyle: 'none' }}>
                        Take any action with respect to any User Contribution that we deem necessary or appropriate in
                        our sole discretion, including if we believe that such User Contribution violates the Terms,
                        including the Content Standards, infringes any intellectual property right or other right of any
                        person or entity, threatens the personal safety of users of the Site or the public, or could
                        create liability for us.
                    </li>
                    <li style={{ listStyle: 'none' }}>
                        Disclose your identity or other information about you to any third party who claims that
                        material posted by you violates their rights, including their intellectual property rights or
                        their right to privacy.
                    </li>
                    <li style={{ listStyle: 'none' }}>
                        Take appropriate legal action, including referral to law enforcement, for any illegal or
                        unauthorized use of the Site.
                    </li>
                    <li style={{ listStyle: 'none' }}>
                        Terminate or suspend your access to all or part of the Site for any reason, including any
                        violation of these Terms.
                    </li>
                </ul>
                <p>
                    Without limiting the foregoing, we have the right to cooperate fully with any law enforcement
                    authorities or court order requesting or directing us to disclose the identity or other information
                    of anyone posting any materials on or through the Site. YOU WAIVE AND HOLD HARMLESS US AND OUR
                    AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY
                    OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES
                    OR LAW ENFORCEMENT AUTHORITIES.
                </p>
                <p>
                    However, we cannot review all material before it is posted on the Site, and cannot ensure prompt
                    removal of objectionable material after it has been posted. Accordingly, we assume no liability for
                    any action or inaction regarding transmissions, communications, or content provided by any user or
                    third party. We have no liability or responsibility to anyone for performance or nonperformance of
                    the activities described in this section.
                    <p>Upon termination, your right to use the Services will cease immediately. </p>
                </p>
            </div>
            <div id="M">
                <u>M. RELIANCE ON INFORMATION POSTED </u>
                <p>
                    The information presented on or through the Site and Services is made available solely for general
                    information purposes. We do not warrant the accuracy, completeness, or usefulness of this
                    information. Any reliance you place on such information is strictly at your own risk. We disclaim
                    all liability and responsibility arising from any reliance placed on such materials by you or any
                    other visitor to the Site, or by anyone who may be informed of any of its contents.
                </p>
                <p>
                    The Services may include content provided by third parties, including materials provided by other
                    users, bloggers, and third-party licensors, syndicators, aggregators, and/or reporting services. All
                    statements and/or opinions expressed in these materials, and all articles and responses to questions
                    and other content, other than the content provided by us, are solely the opinions and the
                    responsibility of the person or entity providing those materials. These materials do not necessarily
                    reflect our opinion. We are not responsible, or liable to you or any third party, for the content or
                    accuracy of any materials provided by any third parties.
                </p>
            </div>
            <div id="N">
                <u>N. LIMITATION OF LIABILITY</u>
                <p>
                    WE cannot guarantee the Site will be available one hundred percent (100%) of the time Because public
                    networks, such as the internet, occasionally experience disruptions. Although WE STRIVE to provide
                    the most reliable webSite reasonably possible, interruptions and delays in accessing the Site are
                    unavoidable and WE DISCLAIM any liability for damages resulting from such problems.
                </p>
                <p>
                    NOTWITHSTANDING THE FOREGOING, OUR LIABILITY AND THE LIABILITY OF OUR AFFILIATES, EMPLOYEES, AGENTS,
                    REPRESENTATIVES AND THIRD-PARTY SERVICE PROVIDERS WITH RESPECT TO ANY AND ALL CLAIMS ARISING OUT OF
                    YOUR USE OF THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, NEGLIGENCE, STRICT LIABILITY OR
                    OTHERWISE, SHALL NOT EXCEED, IN THE AGGREGATE, THE AMOUNT PAID TO USE THE SERVICES OR FIFTY DOLLARS
                    ($50), WHICHEVER IS GREATER.
                </p>
                <p>
                    IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER
                    CONSEQUENTIAL DAMAGES FOR ANY USE OF THE SITE, OR ON ANY OTHER HYPERLINKED WEBSITE, INCLUDING,
                    WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA OR OTHER
                    INFORMATION, FOR BUSINESS INTERRUPTION, FOR PERSONAL INJURY, LOSS OF PRIVACY ARISING OUT OF OR IN
                    ANY WAY RELATED TO THE USE OF OR INABILITY TO USE THE SERVICES, OR OTHERWISE IN CONNECTION WITH ANY
                    PROVISION OF THE TERMS, EVEN IF WE ARE EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <p>
                    Some states do not allow the exclusion of implied warranties or limitation of liability for
                    incidental or consequential damages, which means that some of the above limitations may not apply.
                    In these states or countries, each party's liability will be limited to the greatest extent
                    permitted by law.
                </p>
            </div>
            <div id="O">
                <u>O. APP STORES:</u>
                <p>
                    You acknowledge and agree that the availability of our mobile application may be dependent on the
                    third party from which you received the application’s license, e.g., the Apple iPhone or Android app
                    stores (“App Store”). You acknowledge and agree that these Terms are between you and us and not with
                    the App Store and that we are responsible for the provision of Services as described in these Terms.
                    However, if you downloaded the mobile application from the Apple App Store, Apple and its
                    subsidiaries are third-party beneficiaries of these Terms. Upon your acceptance of these Terms,
                    Apple shall have the right (and will be deemed to have accepted the right) to enforce these Terms
                    against you as a third-party beneficiary thereof. These Terms incorporate by reference Apple’s
                    Licensed Application End User License Agreement, for purposes of which, you are “the end-user.” In
                    the event of a conflict in the terms of the Licensed Application End User License Agreement and
                    these Terms, these Terms will control.
                </p>
            </div>
            <div id="P">
                <u>P. LINKING TO THE SITE AND SOCIAL MEDIA FEATURES:</u>
                <p>
                    You may link to our homepage, provided you do so in a way that is fair and legal and does not damage
                    our reputation or take advantage of it, but you must not establish a link in such a way as to
                    suggest any form of association, approval, or endorsement on our part without our express written
                    consent.
                </p>
                <p>This Site may provide certain social media features that enable you to:</p>
                <ul>
                    <li style={{ listStyle: 'none' }}>
                        Link from your own or certain third-party websites to certain content on this Site.
                    </li>
                    <li style={{ listStyle: 'none' }}>
                        Send emails or other communications with certain content, or links to certain content, on this
                        Site.
                    </li>
                    <li style={{ listStyle: 'none' }}>
                        Cause limited portions of content on this Site to be displayed or appear to be displayed on your
                        own or certain third-party websites.
                    </li>
                </ul>
                <ul>
                    <li style={{ listStyle: 'none' }}>
                        You may use these features solely as they are provided by us and otherwise in accordance with
                        any additional terms and conditions we provide with respect to such features. Subject to the
                        foregoing, you must not:
                    </li>
                    <li style={{ listStyle: 'none' }}>Establish a link from any website that is not owned by you.</li>
                    <li style={{ listStyle: 'none' }}>
                        Cause the Site or portions of it to be displayed on, or appear to be displayed by, any other
                        site, for example, framing, deep linking, or in-line linking.
                    </li>
                    <li style={{ listStyle: 'none' }}>Link to any part of the Site other than the homepage.</li>
                    <li style={{ listStyle: 'none' }}>
                        Otherwise take any action with respect to the materials on this Site that is inconsistent with
                        any other provision of these Terms.
                    </li>
                </ul>
                <p>
                    The website from which you are linking, or on which you make certain content accessible, must comply
                    in all respects with these Terms. You agree to cooperate with us to stop any unauthorized framing or
                    linking immediately. We reserve the right to withdraw linking permission without notice. We may
                    disable all or any social media features and any links at any time in our sole discretion and
                    without notice.
                </p>
            </div>
            <div id="Q">
                <u>Q. DISCLAIMER </u>
                <p>
                    The Service is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without
                    warranty of any kind. To the maximum extent permitted under applicable law, we, on our behalf and
                    behalf of our Affiliates and its our respective licensors and service providers, expressly disclaim
                    all warranties, whether express, implied, statutory, or otherwise, concerning the Services,
                    including all implied warranties of merchantability, fitness for a particular purpose, title and
                    non-infringement, and warranties that may arise out of course of dealing, course of performance,
                    usage or trade practice. Without limitation to the foregoing, we provide no warranty or undertaking,
                    and make no representation of any kind that the Services will meet your requirements, achieve any
                    intended results, be compatible or work with any other software, Services, systems or services,
                    operate without interruption, meet any performance or reliability standards or be error-free or that
                    any errors or defects can or will be corrected.
                </p>
                <p>
                    Without limiting the foregoing, neither we nor any of our providers make any representations or
                    warranty of any kind, express or implied:
                </p>
                <ul>
                    <li style={{ listStyle: 'none' }}>
                        As to the operation or availability of the Services, or the information, content, and materials
                        or products included thereon;
                    </li>
                    <li style={{ listStyle: 'none' }}>That the Services will be uninterrupted or error-free;</li>
                    <li style={{ listStyle: 'none' }}>
                        As to the accuracy, reliability, or currency of any information or content provided through the
                        Services;
                    </li>
                </ul>
                <p>
                    That the Services, its servers, the content, or e-mails sent from or on behalf of the Services are
                    free of viruses, scripts, trojan horses, worms, malware, timebombs, or other harmful components.
                    <p>
                        Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on
                        applicable statutory rights of a consumer, so some or all of the above exclusions and
                        limitations may not apply to you. But in such a case the exclusions and limitations outlined in
                        this section shall be applied to the greatest extent enforceable under applicable law.
                    </p>
                </p>
            </div>
            <div id="R">
                <u>R. INDEMNIFICATION </u>
                <p>
                    You agree to indemnify, defend and hold harmless us and, to the extent applicable, our subsidiaries
                    and affiliates, and each of their and our respective directors, officers, shareholders, employees,
                    agents, representatives, clients, contractors and third-party service providers, for any and all
                    losses, claims, demands, actions, liability, fines, penalties and expenses (including reasonable
                    legal fees) that may arise from any of your acts through the use of the Site and Services. Such acts
                    may include: (i) providing content to or communicating with us or, to the extent applicable, our
                    subsidiaries or affiliates; (ii) unauthorized use of material obtained through the Site and
                    Services; (iii) engaging in a prohibited activity; or (iv) any other action that breaches these
                    Terms. We reserve the right to assume the exclusive defense and control of any matter otherwise
                    subject to indemnification by you, which shall not excuse your indemnity obligations.
                </p>
            </div>
            <div id="S">
                <u>S. GOVERNING LAW</u>
                <p>
                    The laws of Missouri, excluding its conflicts of law rules, and of the United States of America
                    shall govern these Terms and your use of the Services. Your use of the Services may also be subject
                    to other local, state, national, or international laws. Except as set forth in the agreement to
                    arbitration and without waiving it, you agree that any dispute arising from or relating to the
                    subject matter of these Terms (including but not limited to if you opt out of the agreement to
                    arbitration) shall be governed by the exclusive jurisdiction and venue of the state and federal
                    courts of Kansas City, Missouri, except where the jurisdiction and venue are mandated by applicable
                    assignment.
                </p>
            </div>
            <div id="T">
                <u>T. INJUNCTIVE RELIEF</u>
                <p>
                    You acknowledge that we may be irreparably damaged if these Terms are not specifically enforced, and
                    damages at law would be an inadequate remedy. Therefore, in the event of a breach or threatened
                    breach of any provision of these Terms by you, we shall be entitled, without prejudice to any other
                    rights and remedies that may be sought under the mandatory arbitration provision of these Terms, to
                    an injunction restraining such breach or threatened breach, without being required to show any
                    actual damage or to post an injunction bond, and/or to a decree for specific performance of the
                    provisions of these Terms. For purposes of this Section, you agree that any action or proceeding
                    with regard to such injunction restraining such breach or threatened breach shall be brought in the
                    state or federal courts located in Missouri. You consent to the jurisdiction of such court and waive
                    any objection to the laying of venue of any such action or proceeding in such court. You agree that
                    service of any court paper may be affected on such party by mail or in such other manner as may be
                    provided under applicable laws, rules of procedure or local rules.
                </p>
            </div>
            <div id="U">
                <u>U. DISPUTES RESOLUTION: </u>
                <p>
                    If you have any concern or dispute about the Services, you agree to first try to resolve the dispute
                    informally by contacting us.
                    <p>
                        In the event that we are not able to resolve a dispute, and with the exception of the claims for
                        injunctive relief by us as described above and to the extent allowed by law, you hereby agree
                        that either you or we may require any dispute, claim, or cause of action (“Claim”) between you
                        and us or any third parties arising out of use of the Site, the Services, and any other actions
                        with us (whether based in contract, tort, statute, fraud, misrepresentation, or any other legal
                        theory) to be arbitrated on an individual (non-class) basis. However, both parties retain the
                        right to seek relief in a small claims court (or a state court equivalent) for a Claim within
                        the scope of its jurisdiction so long as the small claims action does not seek to certify a
                        class, combine the claims of multiple persons, recover damages in excess of the limit for a
                        small claim under applicable state law or is not transferred, removed, or appealed from small
                        claims court to any different court. Additionally, if you are a California resident, you retain
                        the right to obtain public injunctive relief from any court with proper jurisdiction.
                    </p>
                    <p>
                        THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS VERY
                        LIMITED. ADDITIONALLY, ANY ARBITRATION OF A CLAIM WILL BE ON AN INDIVIDUAL BASIS, AND,
                        THEREFORE, YOU UNDERSTAND AND AGREE THAT YOU ARE WAIVING THE RIGHT TO PARTICIPATE AS A CLASS
                        REPRESENTATIVE OR CLASS MEMBER IN A CLASS ACTION LAWSUIT. AS PART OF THIS WAIVER, YOU AGREE THAT
                        YOU WAIVE THE RIGHT TO ACT AS A PRIVATE ATTORNEY GENERAL IN AN ARBITRATION; THAT EXCEPT AS
                        OTHERWISE PROVIDED IN THIS ARBITRATION AGREEMENT, CLAIMS BROUGHT BY OR AGAINST YOU MAY NOT BE
                        JOINED OR CONSOLIDATED WITH CLAIMS BROUGHT BY OR AGAINST ANY OTHER PERSON; AND THE ARBITRATOR
                        SHALL HAVE NO AUTHORITY TO CONDUCT A CLASS-WIDE ARBITRATION, PRIVATE ATTORNEY GENERAL
                        ARBITRATION OR MULTIPLE-PARTY ARBITRATION.
                    </p>
                    <p>
                        You and we agree that your use of the Services involves interstate commerce, and that this
                        arbitration agreement shall be interpreted and enforced in accordance with the Federal
                        Arbitration Act (FAA) set forth in Title 9 of the U.S. Code to the fullest extent possible,
                        notwithstanding any state law to the contrary, regardless of the origin or nature of the Claims
                        at issue. The arbitrator must follow, to the extent applicable: (1) the substantive law of the
                        state in which we entered into the transaction giving rise to this arbitration agreement; (2)
                        the applicable statutes of limitations; and (3) claims of privilege recognized at law. The
                        arbitrator will not be bound by federal, state or local rules of procedure and evidence or by
                        state or local laws concerning arbitration proceedings.
                    </p>
                    <p>
                        If either you or we elect to arbitrate a Claim, the dispute shall be resolved by binding
                        arbitration administered under the applicable rules of the American Arbitration Association
                        (“AAA”). Either you or we may elect to resolve a particular Claim through arbitration, even if
                        the other party has already initiated litigation in court related to the Claim, by: (a) making
                        written demand for arbitration upon the other party, (b) initiating arbitration against the
                        other party, or (c) filing a motion to compel arbitration in court.
                    </p>
                    <p>
                        If this is a consumer-purpose transaction, the applicable rules will be the AAA’s Consumer
                        Arbitration Rules. The applicable AAA rules and other information about arbitrating a claim
                        under AAA, including how to submit a dispute to arbitration, may be obtained by visiting its
                        website at{' '}
                        <a href="https://www.adr.org/" target="_blank" rel="noreferrer">
                            https://www.adr.org
                        </a>{' '}
                        or by calling 1-800-778-7879. If AAA will not serve as the administrator of the arbitration, and
                        you and we cannot then agree upon a substitute arbitrator, you and we shall request that a court
                        with proper jurisdiction appoint an arbitrator. However, we will abide by the applicable AAA
                        rules regardless of the forum. Arbitration shall be conducted in the county and state where you
                        accepted these Terms, you reside, or another reasonably convenient place to you as determined by
                        the arbitrator, unless applicable laws require another location. Judgment on the award rendered
                        by the arbitrator may be entered in any court having jurisdiction thereof. Except as provided in
                        applicable statutes, the arbitrator’s award is not subject to review by the court and it cannot
                        be appealed. The parties will have the option to request and receive a statement of reasons for
                        the arbitration award.
                    </p>
                    <p>
                        If you elect to file the arbitration, and this is a consumer-purpose transaction, you will pay
                        the filing fee to the extent required by AAA’s Consumer Arbitration Rules but not to exceed the
                        cost of filing a lawsuit. Any amount above what it would cost you to file a lawsuit, we will
                        pay. All other arbitration fees and expenses shall be allocated to us according to AAA rules.
                        Except for the arbitration fees and expenses, each party shall pay its own costs and fees
                        incurred (including attorneys’ fees), unless the arbitrator allocates them differently in
                        accordance with applicable law. This paragraph applies only if this is a consumer-purpose
                        transaction.
                    </p>
                    <p>
                        Notwithstanding anything to the contrary in these Terms, and except as otherwise set forth in
                        this paragraph, the agreement to arbitration may be amended by us only upon advance notice to
                        you. If we make any amendment to this agreement to arbitration (other than renumbering the
                        agreement to align with any other amendment to the Terms) in the future, that amendment shall
                        not apply to any claim that was filed in a legal proceeding or action against us prior to the
                        effective date of the amendment. The amendment shall apply to all other Claims governed by this
                        agreement to arbitration that have arisen or may arise between you and us. However, we may amend
                        this agreement to arbitration and not provide you notice; in that case, the amendments will not
                        apply to you and the agreement to arbitration contained in these Terms to which you agreed will
                        continue to apply to you and us as if no amendments were made.
                    </p>
                    <p>
                        If any part of this arbitration provision is invalid, all other parts of it remain valid.
                        However, if the class action limitation is invalid, then this arbitration provision is invalid
                        in its entirety, provided that the remaining Terms shall remain in full force and effect. This
                        arbitration provision will survive the termination of your use of the Site, the Services, and
                        any other actions with us.
                    </p>
                    <p>
                        You may reject this arbitration provision within thirty (30) days of accepting the Terms by
                        emailing us at <a href="mailto:legal@matchritecare.com">legal@matchritecare.com</a> and
                        including in the subject line “Rejection of Arbitration Provision.”
                    </p>
                </p>
            </div>
            <div id="V">
                <u>V. MINIMUM AGE</u>
                <p>
                    We do not allow persons under the age of eighteen (18) to use the Services. By using the Services,
                    you represent and warrant that you are eighteen (18) years of age or over.
                </p>
            </div>
            <div id="W">
                <u>W. CHANGES TO THESE TERMS AND CONDITIONS: </u>
                <p>
                    We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a
                    revision is material, we will make reasonable efforts to provide at least 30 days' notice before any
                    new terms taking effect. What constitutes a material change will be determined at our sole
                    discretion.
                </p>
                <p>
                    If we change these Terms, then we will opt-in all users (including all Medicare beneficiaries) to
                    receive the updated Terms via your choice of an email or a pop-up screen within our platform. By
                    continuing to access or use our Services after those revisions become effective, you agree to be
                    bound by the revised Terms. If you do not agree to the new terms, in whole or in part, please stop
                    using the Services.
                </p>
            </div>
            <div id="X">
                <u>X. OTHER TERMS</u>
                <p>
                    MERGER: These Terms (which hereby incorporate by reference any other provisions applicable to use of
                    the Services) constitutes the entire agreement between you and us and it supersedes all prior or
                    contemporaneous communications, promises and proposals, whether oral, written or electronic, between
                    you and us with respect to the Sites and information, software, products and services associated
                    with it.
                    <p>
                        ASSIGNMENT: You may not assign, delegate or transfer these Terms or your rights or obligations
                        hereunder, in any way (by operation of law or otherwise) without our prior written consent. We
                        may freely assign our obligations and rights under these Terms, including all personal
                        information in our possession that we have collected during your use of the Site as further
                        described in our Privacy Policy.
                    </p>
                    <p>
                        HEADINGS: Provision and section headings are for convenience of reference only and shall not
                        affect the interpretation of these Terms.
                    </p>
                    <p>
                        TYPOGRAPHICAL ERRORS: Information on the Site and Services may contain technical inaccuracies or
                        typographical errors. We attempt to make the Services’ postings as accurate as possible, but we
                        do not warrant the content of the Site is accurate, complete, reliable, current, or error-free.
                    </p>
                    <p>
                        SEVERABILITY: If any provision of these Terms are held to be unenforceable or invalid, such
                        provision will be changed and interpreted to accomplish the objectives of such provision to the
                        greatest extent possible under applicable law and the remaining provisions will continue in full
                        force and effect.
                    </p>
                    <p>
                        WAIVER: Except as provided herein, the failure to exercise a right or to require performance of
                        an obligation under these Terms shall not affect a party's ability to exercise such right or
                        require such performance at any time thereafter, nor shall the waiver of a breach constitute a
                        waiver of any subsequent breach.
                    </p>
                    <p>
                        TRANSLATION INTERPRETATION: These Terms and Conditions may have been translated if we have made
                        them available to you on our Services. You agree that the original English text shall prevail in
                        the case of a dispute.
                    </p>
                </p>
            </div>
            <div id="Y">
                <u>Y. CONTACT </u>
                <p>
                    You may contact us with questions about these Terms via support within the application or via email
                    to <a href="mailto:legal@matchritecare.com">legal@matchritecare.com</a>.
                </p>
            </div>
        </div>
    )
}

export default TermsAndCondition
