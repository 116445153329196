import React from 'react'
import * as moment from 'moment'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Dialog from '@mui/material/Dialog'
import { preloadScript } from 'opentok-react'
import Divider from '@mui/material/Divider'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import DialogActions from '@mui/material/DialogActions'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import maleIcon from 'assets/profiles/male.png'
import Loader from 'components/Loader/Loader'
import { GetAppointmentsAPI } from 'services/Services'
import SideBar from 'components/layout/SideBar/SideBar'

class Jason extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: {},
            error: null,
            connected: false,
            apiKey: '',
            sessionId: '',
            token: '',
            telehealthSessionInfo: {},
            typeId: {},

            loading: true,
            value: [],
        }

        this.sessionEvents = {
            sessionConnected: () => {
                this.setState({ connected: true })
            },
            sessionDisconnected: () => {
                this.setState({ connected: false })
            },
        }
        this.open = {}
        this.teleHealthInfo = {}
        this.typeId = {}
    }

    handleClickOpen = (itemRecord) => {
        if (itemRecord.telehealthSessionInfo && itemRecord.telehealthSessionInfo != null) {
            this.open[itemRecord.id] = true
            this.teleHealthInfo[itemRecord.id] = itemRecord.telehealthSessionInfo
            this.typeId[itemRecord.id] = itemRecord.typeId
            this.setState({
                open: this.open,
                telehealthSessionInfo: this.teleHealthInfo,
                typeId: this.typeId,
            })
        }
    }

    handleClose = () => {
        this.open = {}
        this.setState({ open: this.open })
    }

    video(item) {
        if (item.typeId === 2) {
            return (
                <VideocamOutlinedIcon className="light-blue curpoi" onClick={this.handleClickOpen.bind(this, item)} />
            )
        } else {
            return <DirectionsWalkIcon className="light-blue" />
        }
    }

    componentDidMount() {
        this.getAppointments()
    }

    getAppointments = async () => {
        let profileid = localStorage.getItem('profileid')
        let providerId = this.props.match.params.pid

        const data = await GetAppointmentsAPI(profileid, providerId)

        if (data) {
            const value = data

            if (value.message === 'No Data Found') {
                await this.setState({ value: [], loading: false })
            } else {
                await this.setState({ value: data[0], loading: false })
                let gridItems = this.state.value
                gridItems.map((item) => {
                    if (item && item.startDateTime) {
                        item['startDate'] = moment(moment(item.startDateTime)).format('DD MMM, YYYY')
                        item['startTime'] = moment(moment(item.startDateTime)).format('hh:mm a')
                    }
                    return item
                })
                this.setState({ value: gridItems, loading: false })

                //  if(this.state.typeId === null){
                //    return (

                // <VideocamOutlinedIcon className='light-blue' onClick={this.handleClickOpen} />
                // )

                // }else{
                //    return(
                //   <PhoneForwardedIcon className='light-blue' />
                //    )
                // }
            }
        } else {
            this.setState({ loading: false })
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <div className="_container jason">
                        <SideBar title="Appointments" />
                        <div className="container-right">
                            <div className="main-title"> Provider Appiontments</div>
                            <div className="white-container">
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (!this.state.value) {
            // if(!this.state.person) {
            return (
                <div>
                    <div className="_container">
                        <SideBar title="Appointments" />
                        <div className="container-right">
                            <div className="main-title"> Provider Appiontments</div>
                            <div className="white-container">
                                <div style={{ textAlign: 'center' }}>
                                    Didnt get the patient details......LogIn to EHR to get Them
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            // }
        }

        return (
            <div className="_container">
                <SideBar title="appointments" />
                <div className="container-right">
                    <div className="main-title">
                        <ArrowBackIcon
                            style={{ cursor: 'pointer', marginRight: 10 }}
                            onClick={this.props.history.goBack}
                        />
                        Provider Appiontments
                    </div>
                    <div className="white-container">
                        <Grid container spacing={3}>
                            {this.state.value && this.state.value.length > 0 ? (
                                this.state.value.map((item, i) => {
                                    return (
                                        <Grid item xs={6} sm={6}>
                                            <Paper>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <img
                                                            src={maleIcon}
                                                            className="profile-pic"
                                                            alt="profile-pic"
                                                        />
                                                    </div>
                                                    <div className="col-md-10">
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <h1 className="patient-name">{item.providerName}</h1>
                                                                <h5 className="physician">{item.providerType}</h5>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <h1 className="patient-name">{item.startTime}</h1>
                                                                <span className="physician">{item.startDate}</span>
                                                            </div>
                                                        </div>
                                                        <Divider variant="middle" />
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <h5 className="problem">Problem of Visit</h5>

                                                                <h4 className="physician">{item.problemDefinition}</h4>
                                                                {/* {item.telehealthSessionInfo!== null ?(
                                        <h5 className='apiKey' style={{width:200}}>
                                            {/* {item.telehealthSessionInfo.apiKey}
                                            {item.telehealthSessionInfo.sessionId} */}
                                                                {/* {/* {item.item.telehealthSessionInfo.sessionId} */}
                                                                {/* </h5> */}
                                                                {/* ):null} */}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="icons-align">
                                                                    {/* {this.state.typeId === null && this.state.typeId === 1? (
                              //   <VideocamOutlinedIcon className='light-blue' onClick={this.handleClickOpen.bind(this,item.telehealthSessionInfo,item.typeId)} />
                              // <PhoneForwardedIcon className='light-blue' />
                              {this.video})
                               ):null} */}
                                                                    {this.video(item)}

                                                                    {this.state.open[item.id] &&
                                                                    this.state.telehealthSessionInfo[item.id] &&
                                                                    this.state.telehealthSessionInfo[item.id] !==
                                                                        null ? (
                                                                        <VideoDialog
                                                                            listItem={item}
                                                                            stateItem={this.state}
                                                                            handleClose={this.handleClose.bind()}
                                                                        />
                                                                    ) : null}
                                                                    {/*  telehealthInfo={this.state.telehealthSessionInfo[item.id]} open={this.state.open[item.id]} */}

                                                                    <MailOutlineIcon className="mail" />
                                                                    <LocationOnIcon className="light-blue" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Paper>
                                        </Grid>
                                    )
                                })
                            ) : (
                                <div
                                    className="white-container"
                                    style={{
                                        textAlign: 'center',
                                        margin: '10rem 0',
                                        fontWeight: '600',
                                    }}
                                >
                                    No data found ...Please login with EHR
                                </div>
                            )}
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}
class VideoDialog extends React.Component {
    constructor(props) {
        super(props)
        this.propsData = this.props
        this.state = {
            open: {},
        }
        this.state.open[this.propsData.listItem.id] = this.props.stateItem.open
    }

    handleClose = () => {
        let open = {}
        open[this.propsData.listItem.id] = false
        this.setState({ open: open })
    }

    render() {
        return (
            <Dialog
                open={this.state.open[this.propsData.listItem.id]}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogContent>
               <VideoCallIntiater telehealthInfo={this.props.listItem.telehealthSessionInfo} teleHealthItem={this.props.listItem} onClick={this.props.handleClose} />
            </DialogContent> */}
                <DialogActions></DialogActions>
            </Dialog>
        )
    }
}

export default preloadScript(Jason)
