import config from 'use/Config'
import mrcFetch from 'util/authFetch'

export function bluebuttonPatientToken() {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const code = params.get('code')
    let formdata = new FormData()
    formdata.append('code', code)
    formdata.append('client_id', config.bluebuttonCredentials.CLIENT.ID)
    formdata.append('grant_type', 'authorization_code')
    formdata.append('redirect_uri', config.bluebuttonCredentials.REDIRECT.REDIRECT_URI)
    formdata.append('client_secret', config.bluebuttonCredentials.CLIENT.secret)

    let requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
    }

    fetch(config.bluebuttonCredentials.DETAILS.GET_TOKEN, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            let datatok = result.access_token
            let patient = result.patient
            addPatientToBluebutton(datatok, patient)
        })
        .catch((error) => {
            console.error('Error:', error)
        })
}

export function addPatientToBluebutton(token, patientId) {
    let raw = JSON.stringify({
        token: token,
        patientId: patientId,
        ProfileId: localStorage.getItem('profileid'),
    })

    let requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: raw,
        redirect: 'follow',
    }

    mrcFetch(`${config.mrc.baseApiUrl}/BlueButton/ProcessBluebuttonData`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log('error', error))
}
