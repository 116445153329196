import React from 'react'
import DataGrid from 'components/DataTable/DataGridWrapper'

import Loader from 'components/Loader/Loader'
import { ProceduresProfileIdAPI } from 'services/Services'
import { useSearchField } from 'hoc/SearchFieldProvider'
import filterEntries from 'util/filterEntries'
import AlertDialog from 'components/AlertDialog'
import { getDate } from 'use/Helper'
import { ToolTipIcon } from 'assets/svg/tooltipIcon'
import { IconButton } from '@mui/material'

class ProceduresImpl extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [],
            loading: true,
            open: false,
            selected: {},
        }
    }

    componentDidMount() {
        const { setSearchColumns, selectedDependent } = this.props
        this.proceduresProfileId(selectedDependent?.id)
        setSearchColumns([
            {
                value: 'providerName',
                label: 'Provider name',
            },
            {
                value: 'performerName',
                label: 'Performer',
            },
            {
                value: 'patientName',
                label: 'Patient',
            },
            {
                value: 'code',
                label: 'Code',
            },
            {
                value: 'location',
                label: 'Location',
            },
            {
                value: 'datePerformed',
                label: 'Date',
            },
        ])
    }
    componentDidUpdate(prevProps, prevState) {
        const { selectedDependent } = this.props
        if (selectedDependent?.id !== prevProps.selectedDependent?.id) {
            this.proceduresProfileId(selectedDependent?.id)
        }
    }

    // Function to retrieve patient care plans from Matchrite API
    proceduresProfileId = async (selectedDependentId) => {
        let profileId = selectedDependentId || localStorage.getItem('profileid')
        this.setState({
            loading: true,
        })
        const data = await ProceduresProfileIdAPI(profileId)

        if (data) {
            if (data.message === 'No Data Found') {
                this.setState({ value: [], loading: false })
            } else {
                this.setState({ value: data.procedures, loading: false })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    getFilteredEntries = () => {
        const { value } = this.state
        const { searchTerm, filterByColumn } = this.props
        const allColumns = ['providerName', 'performer', 'patient', 'code', 'location', 'status']
        const columns = filterByColumn === 'all' ? allColumns : [filterByColumn]
        return filterEntries(value, columns, searchTerm)
    }

    render() {
        if (this.state.loading) {
            return <Loader pageName="procedures" />
        }

        // Columns for data grid that will that will display patient's care plans
        const columns = [
            {
                field: 'providerName',
                headerName: 'Provider name',
                flex: 0.5,
            },
            {
                field: 'performerName',
                headerName: 'Performer Dr',
                flex: 0.5,
                // renderCell: (param) => param.value?.display,
            },
            {
                field: 'patientName',
                headerName: 'Patient Name',
                flex: 0.5,
                // renderCell: (param) => param.value?.display,
            },
            {
                field: 'code',
                headerName: 'Code',
                flex: 0.3,
            },
            {
                field: 'datePerformed',
                headerName: 'Date',
                type: 'date',
                flex: 0.5,
                renderCell: (param) => (param.value ? getDate(param.value) : ''),
            },
            {
                field: 'location',
                headerName: 'Location',
                flex: 0.7,
            },
            {
                field: '',
                headerName: 'Click icon for details',
                // headerName: 'Activity',
                flex: 0.2,
                sortable: false,
                disablecolumnmenu: true,
                renderCell: (param) => (
                    // Icon clicked to display consolidated information about data row
                    <IconButton
                        onClick={() =>
                            this.setState({
                                selected: param.row,
                                open: true,
                            })
                        }
                    >
                        <ToolTipIcon />
                    </IconButton>
                ),
            },
        ]

        return (
            <div className="container-right">
                {/* Data grid that populates cells with patient care plan data (limit 5 rows per page)*/}
                <div className="white-container">
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            disableColumnFilter
                            disableColumnSelector
                            rows={this.getFilteredEntries()}
                            disableSelectionOnClick
                            getRowId={(r) => JSON.stringify(r)}
                        />
                    </div>
                    {/* Popup that displays information about a lab row when the icon in it's furthest right column is clicked */}
                    {Object.keys(this.state.selected).length > 0 && (
                        <AlertDialog
                            fullWidth
                            title="Procedure details"
                            open={this.state.open}
                            setOpen={(value) => this.setState({ open: value })}
                        >
                            <div>
                                <label className="fw-bold">Code Number:</label> {this.state.selected.codeNumber}
                            </div>
                            <div>
                                <label className="fw-bold">Status:</label> {this.state.selected.status}
                            </div>
                        </AlertDialog>
                    )}
                </div>
            </div>
        )
    }
}

export function Procedures(props) {
    const { searchTerm, filterByColumn, setSearchColumns, selectedDependent } = useSearchField()

    return (
        <ProceduresImpl
            {...props}
            searchTerm={searchTerm}
            filterByColumn={filterByColumn}
            setSearchColumns={setSearchColumns}
            selectedDependent={selectedDependent}
        ></ProceduresImpl>
    )
}

export default Procedures
