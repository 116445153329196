import axiosInstance from 'util/axios'
export const DoctorService = {
    addDoctor: async (body) => {
        try {
            const response = await axiosInstance.post(`/MyDoctors/AddDoctors`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    shareProfile: async (body) => {
        try {
            const response = await axiosInstance.post(`/MyDoctors/ShareProfileDataToDoctor`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
}
