import axiosInstance from 'util/axios'
export const ImportEhrService = {
    sendEhrAddRequest: async (body) => {
        try {
            const response = await axiosInstance.post(`/User/SendEhrAddRequest`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
}
