export const ToolTipIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.718" height="27.01" viewBox="3424.82 1240.748 12.359 13.505">
        <g data-name="CompositeLayer">
            <path
                d="M3431 1254.253c-.42 0-.848-.107-1.225-.327l-3.73-2.154a2.466 2.466 0 0 1-1.225-2.123v-4.295c0-.873.471-1.683 1.225-2.123l3.73-2.154a2.44 2.44 0 0 1 2.45 0l3.73 2.154a2.466 2.466 0 0 1 1.224 2.123v4.295c0 .873-.47 1.683-1.224 2.123l-3.73 2.154c-.377.22-.804.327-1.225.327Zm0-12.56c-.258 0-.521.069-.754.2l-3.73 2.155c-.465.27-.754.766-.754 1.306v4.295c0 .534.29 1.037.754 1.307l3.73 2.154c.465.27 1.043.27 1.501 0l3.73-2.154c.465-.27.754-.767.754-1.307v-4.295c0-.534-.289-1.036-.753-1.306l-3.73-2.154a1.499 1.499 0 0 0-.748-.201Z"
                fill="#00afaa"
                fillRule="evenodd"
                data-name="Path 126769"
            />
            <path
                d="M3431 1248.6a.474.474 0 0 1-.471-.47v-3.297c0-.258.213-.471.47-.471.258 0 .472.213.472.47v3.298a.474.474 0 0 1-.471.47Z"
                fill="#00afaa"
                fillRule="evenodd"
                data-name="Path 126767"
            />
            <path
                d="M3431 1250.799a.622.622 0 0 1-.446-.183.917.917 0 0 1-.138-.207.619.619 0 0 1-.044-.238.66.66 0 0 1 .182-.446.658.658 0 0 1 .892 0 .66.66 0 0 1 .182.446.625.625 0 0 1-.05.238.725.725 0 0 1-.132.207.622.622 0 0 1-.446.183Z"
                fill="#00afaa"
                fillRule="evenodd"
                data-name="Path 126768"
            />
        </g>
    </svg>
)
