import config from 'use/Config'
import mrcFetch from 'util/authFetch'

export function allscriptsPatientToken(EHRClientId, EHRGetTokenUrl) {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const code = params.get('code')
    let myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

    let urlencoded = new URLSearchParams()
    urlencoded.append('code', code)
    urlencoded.append('client_id', EHRClientId)
    urlencoded.append('grant_type', 'authorization_code')
    urlencoded.append('redirect_uri', config.allscriptsCredentials.REDIRECT.REDIRECT_URI)

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow',
    }
    return fetch(config.allscriptsCredentials.DETAILS.GET_TOKEN, requestOptions)
        .then((response) => response.json())

        .then((result) => {
            let datatok = result.access_token
            addPatientToAllScripts(datatok)
        })

    // .catch((error) => console.log('error', error))
}

export function addPatientToAllScripts(code, state) {
    // export function addPatientToAllScripts(token) {
    // const search = window.location.search
    // const params = new URLSearchParams(search)
    // const token = params.get('code')
    // const ehrHospital = params.get('state')
    let raw = JSON.stringify({
        token: code,
        // patientId: '385800201',
        // ProfileId: localStorage.getItem('profileid'),
        ProfileId: localStorage.getItem('selectedProfileId'),
        EHRHospital: state,
    })

    let requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: raw,
        redirect: 'follow',
    }

    // mrcFetch(`${config.mrc.baseApiUrl}/AllScripts/ProcessAllScriptsData`, requestOptions)
    mrcFetch(`${config.mrc.baseApiUrl}/Veradigm/ProcessVeradigmData`, requestOptions)
        .then((response) => response.text())
        .then((result) => localStorage.removeItem('selectedProfileId'))
        .catch((error) => console.log('error', error))
}
