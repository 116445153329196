import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import logoMale from 'assets/profiles/male.png';
import SideBar from 'components/layout/SideBar/SideBar'

class Amber extends React.Component {
    sideClose = () => {
        this.setState({ size: false })
    }

    sideOpen = () => {
        this.setState({ size: true })
    }

    goToThisPage = (item) => {
        this.props.history.push(`/home/${item}`)
    }

    render() {
        return (
            <div className="_container">
                <SideBar title="treatmentplan" />
                <div className="container-right">
                    <div
                        className="main-title"
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ArrowBackIcon
                            style={{ cursor: 'pointer', marginRight: 10 }}
                            onClick={this.props.history.goBack}
                        />
                        <div>Amber Anderson Appiontments</div>
                    </div>
                    <div className="white-container">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img
                                                src={logoMale}
                                                className="profile-pic"
                                                alt="profile-pic"
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h1 className="patient-name">Dr Ashley Koiosky</h1>
                                                    <h5 className="physician">Physician</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <h1 className="patient-name">09:00 AM</h1>
                                                    <h5 className="physician">24 July,2019</h5>
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 className="problem">Problem of Visit</h5>
                                                    <h4 className="physician">Essential Hypertention</h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icons-align">
                                                        <VideocamOutlinedIcon
                                                            className="light-blue"
                                                            onClick={() => {
                                                                this.sideClose()
                                                                this.goToThisPage('toxbox')
                                                            }}
                                                        />
                                                        <MailOutlineIcon className="mail" />
                                                        <LocationOnIcon className="light-blue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img
                                                src={logoMale}
                                                className="profile-pic"
                                                alt="profile-pic"
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h1 className="patient-name">Dr Sherley Abraham</h1>
                                                    <h5 className="physician">Physician</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <h1 className="patient-name">09:00 AM</h1>
                                                    <h5 className="physician">24 July,2019</h5>
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 className="problem">Problem of Visit</h5>
                                                    <h4 className="physician">Severe Hypertention</h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icons-align">
                                                        <VideocamOutlinedIcon className="light-blue" />

                                                        <MailOutlineIcon className="mail" />
                                                        <LocationOnIcon className="light-blue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img
                                                src={logoMale}
                                                className="profile-pic"
                                                alt="profile-pic"
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h1 className="patient-name">Turgut Alagoz</h1>
                                                    <h5 className="physician">Physician</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <h1 className="patient-name">09:00 AM</h1>
                                                    <h5 className="physician">24 July,2019</h5>
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 className="problem">Problem of Visit</h5>
                                                    <h4 className="physician">Essential Hypertention</h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icons-align">
                                                        <VideocamOutlinedIcon className="light-blue" />
                                                        <MailOutlineIcon className="mail" />
                                                        <LocationOnIcon className="light-blue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img
                                                src={logoMale}
                                                className="profile-pic"
                                                alt="profile-pic"
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h1 className="patient-name">Dr Ashley Koiosky</h1>
                                                    <h5 className="physician">Physician</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <h1 className="patient-name">09:00 AM</h1>
                                                    <h5 className="physician">24 July,2019</h5>
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 className="problem">Problem of Visit</h5>
                                                    <h4 className="physician">Essential Hypertention</h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icons-align">
                                                        <VideocamOutlinedIcon className="light-blue" />
                                                        <MailOutlineIcon className="mail" />
                                                        <LocationOnIcon className="light-blue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img
                                                src={logoMale}
                                                className="profile-pic"
                                                alt="profile-pic"
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h1 className="patient-name">Dr Sherley Abraham</h1>
                                                    <h5 className="physician">Physician</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <h1 className="patient-name">09:00 AM</h1>
                                                    <h5 className="physician">24 July,2019</h5>
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 className="problem">Problem of Visit</h5>
                                                    <h4 className="physician">Severe Hypertention</h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icons-align">
                                                        <VideocamOutlinedIcon className="light-blue" />
                                                        <MailOutlineIcon className="mail" />
                                                        <LocationOnIcon className="light-blue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img
                                                src={logoMale}
                                                className="profile-pic"
                                                alt="profile-pic"
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h1 className="patient-name">Turugut Alagoz</h1>
                                                    <h5 className="physician">Physician</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <h1 className="patient-name">09:00 AM</h1>
                                                    <h5 className="physician">24 July,2019</h5>
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 className="problem">Problem of Visit</h5>
                                                    <h4 className="physician">Essential Hypertention</h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icons-align">
                                                        <VideocamOutlinedIcon className="light-blue" />
                                                        <MailOutlineIcon className="mail" />
                                                        <LocationOnIcon className="light-blue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}

export default Amber
