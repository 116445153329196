import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import DataGridWrapper from 'components/DataTable/DataGridWrapper'
import { ExceptionService } from 'services/exception.service'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { LoginService } from 'services/login.service'
import Loader from 'components/Loader/Loader'
import moment from 'moment'

const exceptionColumns = [
    {
        field: 'raisedAt',
        headerName: 'Raised Date',
        renderCell: (params) => moment(moment(params.row.raisedAt)).format('DD MMM, YYYY'),
        width: 80,
    },
    {
        field: 'profileName',
        headerName: 'User Name',
        width: 100,
    },
    {
        field: 'profileEmail',
        headerName: 'User Email',
        width: 100,
    },
    {
        field: 'isActive',
        headerName: 'Status',
        renderCell: (params) => (params.row.isActive ? 'Active' : 'Inactive'),
        width: 40,
    },
    {
        field: 'isFixed',
        headerName: 'Error Resolved',
        renderCell: (params) => (params.row.isFixed ? 'Yes' : 'No'),
        width: 40,
    },
    {
        field: 'errorMessage',
        headerName: 'Error Message',
        width: 550,
    },
]

const ExceptionPage = () => {
    const [logsRecord, setLogsRecord] = useState([])
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const get531ProfileUser = async () => {
        const email = localStorage.getItem('email')
        if (!email) return
        setLoading(true)
        try {
            const { data: loginData } = await LoginService.getUser(email)
            if (
                loginData &&
                (loginData?.email.includes('junaid') ||
                    loginData?.email.includes('jones') ||
                    loginData?.email.includes('chris') ||
                    loginData?.email.includes('waqar') ||
                    loginData?.email.includes('talha') ||
                    loginData?.email.includes('@531digital.com'))
            ) {
                getAllExceptionLogs()
            } else {
                history.push('/home/myprofile')
            }
        } catch (error) {
            history.push('/home/myprofile')
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        get531ProfileUser()
    }, [])

    const getAllExceptionLogs = async () => {
        try {
            const { data: response } = await ExceptionService.getAllExceptionLogs()
            if (response) {
                setLogsRecord(response)
            }
        } catch (error) {
            console.error(error)
        }
    }

    if (loading) {
        return (
            <div className="container-right">
                <div className="white-container padding-28 veteranhistory">
                    <div className="row">
                        <Box sx={{ marginBottom: 4 }}>
                            <Loader />
                        </Box>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container-right">
            <div className="white-container padding-28 veteranhistory">
                <div className="row">
                    <Box sx={{ marginBottom: 4 }}>
                        <DataGridWrapper columns={exceptionColumns} rows={logsRecord} />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default ExceptionPage
