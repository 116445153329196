import { tableCellClasses } from '@mui/material/TableCell'

export const containerStyles = () => ({
    boxSizing: 'border-box',
})

export const cellStyles = ({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        border: 0,
        padding: '8px 16px',
        fontFamily: 'Montserrat',
        fontSize: 14,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
        fontFamily: 'Montserrat',

    },
})

export const tableStyles = ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey.A200}`,
})

export const rowStyles = ({ theme }) => ({
    // background: 'linear-gradient(180deg, white, #dddddd)',
    borderBottom: `1px solid ${theme.palette.grey.A200}`,
    // '&:nth-of-type': {
    //     backgroundColor: theme.palette.action.white,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
})

export const headStyles = ({ theme }) => ({
    // boxShadow: `0px 0px 5px 0px ${theme.palette.grey.A400}`,
    // border: `1px solid ${theme.palette.grey[200]}`,
    filter: 'drop-shadow(0px 3px 3px rgba(0,0,0,0.16 ))',
    background: '#ffffff',
    fontFamily: 'Montserrat',
    fontSize: '10px',
})

export const paginationStyles = () => ({
    borderBottom: 0,

    '& .MuiTablePagination-displayedRows': {
        margin: 0,
    },
})
