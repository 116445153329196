import React from 'react'
import 'react-phone-input-2/lib/style.css'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'

import Checkbox from '@mui/material/Checkbox'
import { pageTitles } from 'use/pageTitles'
import { useEffect } from 'react'
import { DependentService } from 'services/dependent.service'
import { Autocomplete, TextField } from '@mui/material'
import { LoginService } from 'services/login.service'
import { useSearchField } from 'hoc/SearchFieldProvider'
import { RecordsService } from 'services/myrecords.service'

const ShareRecordsModal = ({ isOpen, onClose, onShare, sharedTables = [], sharedProfiles = [] }) => {
    const [dependentData, setDependentData] = React.useState(null)
    const [selectedProfile, setSelectedProfile] = React.useState([])
    const [allChecked, setAllChecked] = React.useState(false)
    const [eNames, setENames] = React.useState({})
    const [checkedItems, setCheckedItems] = React.useState({})
    const { recordType, setRecordType } = useSearchField()

    const toggleItem = (item) => {
        const payload =
            checkedItems[`${item}Notes`] && checkedItems[item]
                ? {
                      ...checkedItems,
                      [item]: !checkedItems[item],
                      [`${item}Notes`]: !checkedItems[`${item}Notes`],
                  }
                : {
                      ...checkedItems,
                      [item]: !checkedItems[item],
                  }
        setCheckedItems(payload)
    }
    const toggleItemNotes = (item) => {
        const payload = !checkedItems[`${item}Notes`]
            ? {
                  ...checkedItems,
                  [item]: true,
                  [`${item}Notes`]: !checkedItems[`${item}Notes`],
              }
            : {
                  ...checkedItems,
                  //   [item]: false,
                  [`${item}Notes`]: !checkedItems[`${item}Notes`],
              }
        setCheckedItems(payload)
    }
    const handleOnShare = () => {
        onClose()
        if (onShare) {
            const checkedList = Object.fromEntries(
                Object.entries(checkedItems).filter(([key, value]) => value === true)
            )
            const itemsPayloads = Object.keys(checkedList).map((item) => eNames[item])
            const selectedProfileIdsPayloads = selectedProfile?.map((profile) => profile.id)
            onShare(itemsPayloads, selectedProfileIdsPayloads)
        }
    }

    const getDependentWithSelfData = async () => {
        const email = localStorage.getItem('email')
        if (!email) return
        const [dependentData, loginData] = await Promise.all([
            DependentService.getAllDependents(),
            LoginService.getUser(email),
        ])
        if (loginData.data && dependentData.data) {
            const payload = dependentData.data.map((option) => {
                return { id: option.id, name: `${option.firstName} ${option.lastName}` }
            })

            setDependentData([
                {
                    id: loginData.data.profile.id,
                    name: `${loginData.data.profile.firstName} ${loginData.data.profile.lastName} - (Self)`,
                },
                ...payload,
            ])
        }
    }
    const setERecordNames = () => {
        let recordENames = {}
        for (let type of recordType) {
            recordENames = {
                ...recordENames,
                [type.tabUrl]: type.eName,
                [`${type.tabUrl}Notes`]: `${type.eName}Notes`,
            }
        }
        setENames(recordENames)
    }
    const getRecordType = async () => {
        const { data: result } = await RecordsService.getAllRecords()
        const records = result?.filter((x) => x.isDisplay).sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        setRecordType(records)
    }
    useEffect(() => {
        if (isOpen) {
            setDependentData([])
            getDependentWithSelfData()
            if (recordType.length === 0) {
                getRecordType()
            } else {
                setERecordNames()
            }
        }
    }, [isOpen, recordType])

    useEffect(() => {
        if (checkedItems && Object.values(checkedItems).filter((v) => v).length === Object.keys(eNames).length) {
            setAllChecked(true)
        } else {
            setAllChecked(false)
        }
    }, [checkedItems, eNames])

    useEffect(() => {
        if (isOpen) {
            if (sharedProfiles?.length && dependentData?.length)
                setSelectedProfile(dependentData?.filter((data) => sharedProfiles?.find((x) => x.id === data.id)))
            if (sharedTables?.length) {
                const result = {}
                for (const key in eNames) {
                    if (sharedTables?.includes(eNames[key])) {
                        result[key] = true
                        setCheckedItems({
                            ...result,
                            [key]: true,
                        })
                    }
                }
            }
        }
    }, [isOpen, sharedProfiles, eNames, sharedTables, dependentData])

    const handleSelectAll = () => {
        let result = {}
        if (!allChecked) {
            Object.keys(eNames).forEach((item) => {
                result[item] = true
            })
        } else {
            Object.keys(eNames).forEach((item) => {
                result[item] = false
            })
        }
        setCheckedItems({ ...checkedItems, ...result })

        setAllChecked(!allChecked)
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Share your medical records</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Select which records you wish to share with your medical practitioner
                </DialogContentText>
                {dependentData && (
                    <DialogContentText id="alert-dialog-description" sx={{ mt: '15px' }}>
                        <Autocomplete
                            style={{ flex: 1 }}
                            multiple
                            value={selectedProfile}
                            options={dependentData}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option) => option.id}
                            onChange={(_, value) => {
                                setSelectedProfile(value)
                            }}
                            error={true}
                            renderInput={(params) => (
                                <TextField {...params} label={`Select Dependent`} variant="outlined" />
                            )}
                        />
                    </DialogContentText>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                        control={<Checkbox checked={allChecked} onChange={handleSelectAll} />}
                        label="Select All Fields"
                    />
                    <FormControl sx={{ ml: 3 }} component="fieldset" variant="standard">
                        {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
                        <FormGroup>
                            {recordType.map(({ tabUrl: key }) => (
                                <>
                                    <FormControlLabel
                                        sx={{ mt: '-5px' }}
                                        key={key}
                                        control={
                                            <Checkbox
                                                checked={!!checkedItems[key]}
                                                onChange={() => toggleItem(key)}
                                                name={key}
                                            />
                                        }
                                        label={pageTitles[key]}
                                    />
                                    <FormControlLabel
                                        sx={{ mt: '-15px', pl: 3 }}
                                        key={`${key}-notes`}
                                        control={
                                            <Checkbox
                                                checked={!!checkedItems[`${key}Notes`]}
                                                onChange={() => toggleItemNotes(key)}
                                                name={`${key}Notes`}
                                            />
                                        }
                                        label={`${pageTitles[key]} Notes`}
                                    />
                                </>
                            ))}
                        </FormGroup>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose()
                        setCheckedItems({})
                    }}
                >
                    CANCEL
                </Button>
                <Button autoFocus variant="contained" color="primary" onClick={handleOnShare}>
                    Share
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ShareRecordsModal
