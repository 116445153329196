import axiosInstance from 'util/axios'
export const ContactUsService = {
    support: async (body) => {
        try {
            const response = await axiosInstance.post(`/support/contact-form`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
}
