import React from 'react'
import 'react-phone-input-2/lib/style.css'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Autocomplete from '@mui/lab/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import CompassCalibrationOutlinedIcon from '@mui/icons-material/CompassCalibrationOutlined';
import ImportButton from 'components/BlueImportButton'
import './AddYourDoctor.scss'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { GetProvidersAPI, AddDoctorsAPI, GetDoctorsAPI } from 'services/Services'
import popupNotification from 'components/Notification/popupNotification'
import { FieldBox } from './AddYourDoctor.styles'

import { organizations } from './Utils'
import { DoctorService } from 'services/doctor.service'

class AddYourDoctor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            org: '',
            providers: '',
            loading: true,
            provider: {},
            errors: {},
        }
    }

    componentDidMount() {
        this.getProviders()
    }

    // Returns a list of avaliable providers from matchrite database
    getProviders = async () => {
        const data = await GetProvidersAPI()
        if (data) {
            if (data.message === 'No Data Found') {
                this.setState({ providers: [] })
            } else {
                this.setState({ providers: data })
            }
        }
        this.setState({ loading: false })
    }

    // Pairs patient id with selected provider id and send them to matchrite database
    handleAddYourDoctor = async () => {
        if (Object.keys(this.state.provider).length === 0) {
            return this.setState(({ errors }) => ({
                errors: {
                    ...errors,
                    connectDoctor: 'Please select a doctor',
                }
            }))
        }

        this.setState({ loading: true })

        const payload = {
            status: '1',
            providerId: this.state.provider.providerId,
            profileId: localStorage.getItem('profileid'),
        }

        try {
            const { data: response } = await DoctorService.addDoctor(payload)
            if (response) {
                if (response.message === 'Doctor already added for this profileId') {
                    popupNotification('Doctor already added for this profileId', false)
                }
                if (response.message === 'Doctor added successfully') {
                    popupNotification('Doctor added successfully', true)
                }
                if (
                    response.message !== 'Doctor added successfully' &&
                    response.message !== 'Doctor already added for this profileId'
                ) {
                    popupNotification('Add failed', false)
                }
                this.setState({ loading: false })
                this.props.history.push(`/home/share-records`)
            }
        } catch (error) {
            this.setState({ loading: false })
            popupNotification('Something went wrong', false)
        }
    }

    // Function to change org state to user selected organization (doesn't actually achieve anything right now)
    organizationChange = (organization) => {
        this.setState({ org: organization.value })
    }

    // (not sure why this is here)
    getClaims = () => {
        if (!this.state.org) {
            return this.setState(({ errors }) => ({
                errors: {
                    ...errors,
                    connectOrg: 'Please select an organization',
                }
            }))
        }
        switch (this.state.org) {
            case 'Blue Button':
                break
            default:
        }
    }


    render() {
        if (this.state.loading) {
            return (
                // Loading animation and clickable icon to return to share my records page
                <div className="container-right">
                    <div className="main-title with-icon">
                        <Button
                            startIcon={<ChevronLeftIcon />}
                            onClick={() => this.props.history.push('/home/share-records')}
                        >
                            Return
                        </Button>
                    </div>
                    <div className="white-container">
                        <div className="loader">
                            <CircularProgress />
                        </div>
                    </div>
                </div>
            )
        }

        const { errors } = this.state

        return (
            <div className="container-right">

                {/* Page title with clickable icon to return to share my records page */}
                <div className="main-title with-icon">
                    <Button
                        startIcon={<ChevronLeftIcon />}
                        onClick={() => this.props.history.push('/home/share-records')}
                    >
                        Return
                    </Button>
                </div>

                <Grid container style={{ marginTop: 30 }}>
                    <Grid item xs={12} md={8} lg={6}>
                        <Box style={{ marginBottom: 20 }}>
                            <FieldBox>
                                <Autocomplete
                                    style={{ flex: 1 }}
                                    options={this.state.providers}
                                    getOptionLabel={(option) => option.providerName + ' ' + option.providerLastName}
                                    getOptionSelected={(option) => option.providerId}
                                    onChange={(_, p) => this.setState({ provider: p })}
                                    error={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search doctors"
                                            variant="outlined"
                                            error={Boolean(errors.connectDoctor)}
                                            helperText={errors.connectDoctor}
                                        />
                                    )}
                                />
                                <ImportButton
                                    startIcon={<CompassCalibrationOutlinedIcon />}
                                    onClick={this.handleAddYourDoctor}
                                >
                                    Connect
                                </ImportButton>
                            </FieldBox>
                        </Box>
                        {/* <Box>
                            <FieldBox>
                                <Autocomplete
                                    style={{ flex: 1 }}
                                    options={organizations}
                                    getOptionLabel={(option) => option.value}
                                    getOptionSelected={(option) => option.ehrEndPointId}                                    
                                    // onChange={(e, facility) => this.facilityChange(facility)}
                                    onChange={(e, facility) => this.organizationChange(facility)}
                                    error={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search organization"
                                            variant="outlined"
                                            error={Boolean(errors.connectOrg)}
                                            helperText={errors.connectOrg}
                                        />
                                    )}
                                />
                                <ImportButton 
                                startIcon={<CompassCalibrationOutlinedIcon />} 
                                onClick={this.getClaims}
                                >
                                    Connect
                                </ImportButton>
                            </FieldBox>
                        </Box> */}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default AddYourDoctor
