import React from 'react'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import SideBar from 'components/layout/SideBar/SideBar'

import Labs from '../Records/Labs'
import Vitals from '../Records/Vitals'
import Conditions from '../Records/Conditions'
import Medications from '../Records/Medications'
import Immunization from '../Records/Immunization'
import TreatmentPlan from '../Records/TreatmentPlan'
import Procedures from '../Records/Procedures'
import DrvVisitNotes from '../DrvVisitNotes/DrvVisitNotes'

class EncounterDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0,
        }
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue })
    }

    render() {
        return (
            <div className="_container">
                <SideBar title="encounterdetail" />
                <div className="container-right">
                    <div
                        className="main-title"
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ArrowBackIcon
                            style={{ cursor: 'pointer', marginRight: 10 }}
                            onClick={this.props.history.goBack}
                        />
                        <div>Encounter Detail</div>
                    </div>
                    <div className="white-container">
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            variant="fullWidth"
                            indicatorColor="primary"
                            divColor="primary"
                            aria-label="icon tabs example"
                        >
                            <Tab icon={<div style={{ color: '#000' }}>Vitals</div>} aria-label="phone" />
                            <Tab icon={<div style={{ color: '#000' }}>Medications</div>} aria-label="favorite" />
                            <Tab icon={<div style={{ color: '#000' }}>Procedures</div>} aria-label="procedure" />
                            {/* <Tab icon={<div style={{ color: '#000' }}>Treatment Plan</div>} aria-label="person" /> */}
                            <Tab icon={<div style={{ color: '#000' }}>Labs</div>} aria-label="favorite" />
                            <Tab icon={<div style={{ color: '#000' }}>Conditions</div>} aria-label="favorite" />
                            <Tab icon={<div style={{ color: '#000' }}>Immunization</div>} aria-label="favorite" />
                            <Tab icon={<div style={{ color: '#000' }}>Dr Visit Notes</div>} aria-label="favorite" />
                        </Tabs>
                        {this.state.value === 0 && <Vitals />}
                        {this.state.value === 1 && <Medications />}
                        {this.state.value === 2 && <Procedures />}
                        {/* {this.state.value === 2 && <TreatmentPlan />} */}
                        {this.state.value === 3 && <Labs />}
                        {this.state.value === 4 && <Conditions />}
                        {this.state.value === 5 && <Immunization />}
                        {this.state.value === 6 && <DrvVisitNotes />}
                    </div>
                </div>
            </div>
        )
    }
}

export default EncounterDetail
