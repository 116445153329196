import React from 'react'
import { Typography, Stack } from '@mui/material'
// import { styled } from '@mui/material/styles'
// import IconButton from '@mui/material/IconButton'
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import withDynamicTitle from 'hoc/withDynamicTitle'
import './pageHeader.css'
// import MrcTooltip from 'components/MrcTooltip'

// const StyledGrid = styled(Grid)(({ theme }) => ({
//     marginBottom: 10,
// }))

// const StyledTitle = styled(Typography)(({ theme }) => ({
//     fontFamily: 'Montserrat',
//     fontSize: '35px',
//     fontWeight: 'normal',
//     lineHeight: '35px',
//     fontStyle: 'normal',

//     [theme.breakpoints.down('lg')]: {
//         fontSize: '2rem',
//     },

//     [theme.breakpoints.down('sm')]: {
//         fontSize: '1.8rem',
//     },
// }))

const PageHeader = ({ title, subTitle }) => {
    return (
        // <StyledGrid container direction="column" spacing="11px">
        //     <Grid item>
        //         <StyledTitle variant="h3" component="h1">
        //             {title}
        //             {/* {subTitle && (
        //                 <MrcTooltip text={subTitle}>
        //                     {({ onOpen }) => (
        //                         <IconButton color="primary" aria-label="Info" onClick={onOpen}>
        //                             <InfoOutlinedIcon />
        //                         </IconButton>
        //                     )}
        //                 </MrcTooltip>
        //             )} */}
        //         </StyledTitle>
        //     </Grid>
        //     <Grid item>
        //         {/* <StyledTitle variant="h3" component="h1"> */}
        //             {subTitle}
        //             {/* {subTitle && (
        //                 <MrcTooltip text={subTitle}>
        //                     {({ onOpen }) => (
        //                         <IconButton color="primary" aria-label="Info" onClick={onOpen}>
        //                             <InfoOutlinedIcon />
        //                         </IconButton>
        //                     )}
        //                 </MrcTooltip>
        //             )} */}
        //         {/* </StyledTitle> */}
        //     </Grid>
        // </StyledGrid>
        <Stack sx={{mb:'44px'}}>
            <Typography className="title">
                {title}
            </Typography>
            <Typography className="subTitle">
                {subTitle}
            </Typography>
        </Stack>
    )
}

export default withDynamicTitle(PageHeader)
