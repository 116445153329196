import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { FaSquareFacebook } from 'react-icons/fa6'
import { AiFillInstagram } from 'react-icons/ai'
import { FaXTwitter } from 'react-icons/fa6'

import IconButton from '@mui/material/IconButton'
import Config from 'use/Config'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import ContactUsForm from '../ContactUs/ContactUs'
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        fontSize: '1rem',
        fontWeight: 700,
    },
}))

const StyledLinkText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        fontSize: '0.79rem',
        cursor: 'pointer',
    },
}))

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    padding: 0,
    paddingBottom: '10px',
    color: 'white',
}))

const Container = styled(Grid)(({ theme }) => ({
    color: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    padding: `12px 0px 2px 16px`,

    '& svg': {
        fill: theme.palette.common.white,
        opacity: 0.6,
    },

    [theme.breakpoints.down('sm')]: {
        padding: `10px 24px 5px`,
    },
}))

const StyledButton = styled(IconButton)(({ theme }) => ({
    fontSize: '1.9rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
    },
}))

const termsRedirect = () => {
    window.open('https://www.matchritecare.com/terms-and-conditions', '_blank')
}
const privacyRedirect = () => {
    window.open('https://www.matchritecare.com/privacy-policy', '_blank')
}

const FollowUs = () => {
    const [openSupportModal, setOpenSupportModal] = useState(false)
    return (
        <Container container direction="column">
            <Grid item>
                <StyledListItemButton disableRipple onClick={() => setOpenSupportModal(true)}>
                    <ListItemIcon style={{ color: 'white', minWidth: '36px !important' }}>
                        <EventNoteOutlinedIcon style={{ minWidth: '36px !important' }} />
                    </ListItemIcon>
                    <StyledListItemText primary="User Support" />
                </StyledListItemButton>
            </Grid>
            <Grid item sx={{ display: 'flex', flexDirection: 'row', pt: 1, gap: '10px' }}>
                <StyledLinkText primary="Terms of Service" onClick={termsRedirect} />
                <StyledLinkText primary="Privacy Policy" onClick={privacyRedirect} />
            </Grid>

            {/* <Grid item sx={{ pt: 1 }}>
                Follow Us
            </Grid> */}
            <Grid item sx={{ textAlign: 'center' }}>
                <StyledButton sx={{ pl: 0 }} href={Config.socialMedia.facebook} target="_blank">
                    <FaSquareFacebook />
                </StyledButton>
                <StyledButton href={Config.socialMedia.instagram} target="_blank">
                    <AiFillInstagram />
                </StyledButton>
                <StyledButton href={Config.socialMedia.twitter} target="_blank">
                    <FaXTwitter />
                </StyledButton>
            </Grid>
            {openSupportModal && (
                <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={openSupportModal}
                    onClose={() => setOpenSupportModal(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">MatchRite Care User Support</DialogTitle>
                    <DialogContent>
                        THANK YOU for using MatchRite Care as your comprehensive EHR platform. Our aim is to make your
                        User experience simple, intuitive and helpful. Please contact us for any issues that may arise
                        in your efforts to use MatchRite Care. We offer two Support segments; 'APPLICATION' and
                        'GENERAL'.
                        <hr />
                        <div style={{ marginBottom: '10px' }}>
                            <b>APPLICATION Support:</b> issues directly related to use of the MatchRite Care
                            application. Please use link below to submit 'Application Support' issues via our support
                            ticketing service{' '}
                            <a
                                href="https://531software.atlassian.net/servicedesk/customer/portals"
                                target="_blank"
                                rel="noreferrer"
                            >

                                {/* https://531software.atlassian.net/servicedesk/customer/portals */}
                                {`${process.env.REACT_APP_PATIENT_URL}/support-ticket`}
                            </a>
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <b>GENERAL Support:</b> inquiries and/or questions NOT directly relevant to use of the
                            MatchRite Care application. Please use the contact form below to submit 'General Support'
                            inquiries For additional inquiries, you may contact{' '}
                            <a href="mailto:support@matchritecare.com">support@matchritecare.com</a>.
                            <ContactUsForm />
                        </div>
                    </DialogContent>
                    <DialogActions style={{ padding: '16px 24px', display: 'flex', justifyContent: 'space-between' }}>
                        <b>
                            NOTE: Our application consists of external Patient Portals. NOT all resolutions are within
                            our support domain.
                        </b>
                        <Button
                            onClick={() => {
                                setOpenSupportModal(false)
                            }}
                        >
                            CANCEL
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Container>
    )
}

export default FollowUs
