import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
    logo: {
        padding: '2rem 0',
        width: '65%',
    },
    bodyContainer: {
        padding: '20px 55px',
        [theme.breakpoints.down('lg')]: {
            padding: '20px 25px',
        },
    },
}))
