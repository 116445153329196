import React, { useState, useEffect } from 'react'
import './VeteranHistory.scss'
import Box from '@mui/material/Box'
import DataGridWrapper from 'components/DataTable/DataGridWrapper'
import FormGroupHeader from 'components/FormGroupHeader'
import { disabilityColumns } from './constants'
import { getPatientVeteranDisability } from 'services/veteran.service'

const Disability = () => {
    const [disability, setDisability] = useState([])

    useEffect(() => {
        getVeteranDisability()
    }, [])

    const getVeteranDisability = async () => {
        try {
            const profileId = localStorage.getItem('profileid')
            const { data: response } = await getPatientVeteranDisability(profileId)
            if (response) {
                const responseDataParse = JSON.parse(response.disabilityData)
                setDisability(responseDataParse.individual_ratings)
            }
        } catch (error) {
            console.error(error)
            localStorage.removeItem('veteran-token')
        }
    }

    return (
        <div className="container-right">
            <div className="white-container padding-28 veteranhistory">
                <div className="row">
                    <Box sx={{ marginBottom: 4 }}>
                        <DataGridWrapper columns={disabilityColumns} rows={disability} />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Disability
