import React from 'react'
import truncate from 'lodash.truncate'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

const StyledValue = styled(Typography)(({ theme }) => ({
    span: {
        color: theme.palette.grey[500],
        fontWeight: 'normal',
        fontFamily: 'Montserrat',
    },
    fontFamily: 'Montserrat',
}))

const CardField = (props) => {
    const { label, value } = props

    return (
        <div>
            <Typography style={{ fontWeight: 600, fontFamily: 'Montserrat' }}>{label}</Typography>
            <StyledValue noWrap>{typeof value === 'string' ? truncate(value, { length: 18 }) : value}</StyledValue>
        </div>
    )
}

export default CardField
