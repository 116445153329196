import { makeStyles } from '@mui/styles'

const flexColumnCenter = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    // flexDirection: 'column',
}
export const useStyles = makeStyles((theme) => ({
    loginLeftSection: {
        ...flexColumnCenter,
        marginBottom: '10px',
        '& .content': {
            maxWidth: '80%',
            width: '440px',
        },
        '& h1': {
            fontWeight: '200',
            fontSize: '54px',
        },
        '& h5': {
            fontWeight: '300',
            fontSize: '20px',
        },
    },
    loginRightSection: {
        ...flexColumnCenter,
        '& .content': {
            maxWidth: '90%',
            width: '440px',
        },

        '& .subContent': {
            '& h5': {
                fontWeight: '300',
                fontSize: '16px',
            },
        },

        '& input:-webkit-autofill': {
            '-webkit-transition-delay': '9999999s',
        },
    },
    logo: {
        ...flexColumnCenter,
        '& img': {
            width: '60%',
        },
    },
    termsContainerButton: {
        ...flexColumnCenter,
        flexDirection: 'row',
    },
    appBarContainer: {
        margin: '30px 0',
    },
    mainContainer:{
        backgroundColor: '#176571',
        // backgroundColor: '#2a7cce',
    },
    appBarTitle: {
        ...flexColumnCenter,
        color: 'white',
        fontSize: '16px',
        fontWeight: '500',

        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
            paddingLeft: '20px',
        },
    },

    appBarSubTitle: {
        ...flexColumnCenter,
        color: 'white',
        fontSize: '14px',
        height: '16px',
        [theme.breakpoints.down('md')]: {
            marginTop: '10px',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
            paddingRight: '20px',
        },
    },
}))
