import React from 'react'
import './VeteranHistory.scss'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const VeteranHistory = () => {
    const { height, width } = useWindowDimensions()

    return (
        <div className="container-right">
            <iframe
                id="veteran-frame"
                title="Valor Veteran"
                src="https://private-valor-7dih2wxer-mtm-talha.vercel.app"
                width={width - 200}
                height={height - 200}
            ></iframe>
        </div>
    )
}

export default VeteranHistory
