import isEmpty from 'lodash.isempty'
import get from 'lodash.get'

const filterEntries = (rows, searchFields = [], searchTerm) => {
  if (isEmpty(searchTerm) || isEmpty(rows) || isEmpty(searchFields)) {
      return rows
  }
  try {
    const regex = new RegExp(searchTerm, "i")
    return (rows || []).filter((row) => searchFields.some((field) => regex.test(get(row, field))))
  } catch(error) {
    console.error(error)
  }
  return rows
}

export default filterEntries
