import React, { createContext, useState } from 'react'
import { isValidToken } from 'util/auth.util'
// import { isValidToken, setSession } from 'util/auth.util'
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr'
// import { useHistory } from 'react-router-dom'
import config from 'use/Config'
// const allowAnonymous = ['/login', '/signup', '/forgotpassword', '/privacypolicy', '/termsandconditions']
const isAuthenticated = () => {
    if (localStorage.getItem('token')) {
        return isValidToken(localStorage.getItem('token'))
    }
    return false
}

const Context = createContext({})

export const useGlobalContext = () => {
    const context = React.useContext(Context)
    return context
}
const GlobalContext = (props) => {
    // const history = useHistory()
    const [authenticated, setAuthenticated] = useState(isAuthenticated())
    const [ehrLoad, setEhrLoad] = useState(null)
    const [ehrConnection, setEhrConnection] = useState(null)
    // useEffect(() => {
    //     const { pathname } = window.location
    //     const isNotAllowedAnonymous = !allowAnonymous.some((path) => path.includes(pathname))
    //     if (!authenticated && isNotAllowedAnonymous) {
    //         history.push('/login')
    //     }
    // }, [history, authenticated])

    // const initialize = useCallback(async () => {
    //     try {
    //         const token = localStorage.getItem('token')
    //         const refreshToken = localStorage.getItem('refreshToken')
    //         if (token && isValidToken(token)) {
    //             setSession(token, refreshToken)
    //         }
    //     } catch (error) {
    //         history.push('/login')
    //     }
    // }, [])

    // useEffect(() => {
    //     initialize()
    // }, [])

    const createConnection = () => {
        const backendDomain = new URL(config.mrc.baseApiUrl).origin
        const hubConnection = new HubConnectionBuilder()
            .withUrl(`${backendDomain}/connection/importehr`, {
                skipNegotiation: true,

                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build()
        setEhrConnection(hubConnection)
        startConnection(hubConnection)
    }
    const refreshEhrConnection = () => {
        if (ehrConnection) {
            stopEhrConnection()
        }
        createConnection()
    }

    const stopEhrConnection = () => {
        if (ehrConnection) {
            ehrConnection.stop()
        }
    }

    const startConnection = (hubConnection) => {
        hubConnection
            .start()
            .then((result) => {
                hubConnection.on('completedEhr', (data) => {
                    const loginProfileId = localStorage.getItem('profileid')
                    if (data?.userId === loginProfileId) {
                        setEhrLoad(true)
                        stopEhrConnection()
                    }
                })

                hubConnection.on('signalRTesting', (data) => {
                    console.log('SIGNAL R--signalRTesting--data ', data)
                })
            })
            .catch((e) => console.log('connection failed: ', e))
    }

    return (
        <Context.Provider
            value={{
                authenticated,
                setAuthenticated,
                ehrLoad,
                setEhrLoad,
                // setEhrConnection,
                refreshEhrConnection,
            }}
        >
            {props.children}
        </Context.Provider>
    )
}

export default GlobalContext
