import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import * as serviceWorker from './serviceWorker'
// import PWAPrompt from 'react-ios-pwa-prompt'
// import ReactPWAInstallProvider from 'react-pwa-install'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <React.StrictMode>
        {/* <ReactPWAInstallProvider enableLogging> */}
            <App />
        {/* </ReactPWAInstallProvider> */}
        {/* <App /> */}
        {/* <PWAPrompt promptOnVisit={1} timesToShow={13} copyClosePrompt="Close" permanentlyHideOnDismiss={false} /> */}
    </React.StrictMode>
)

serviceWorker.register()
// import React from 'react'
// import ReactDOM from 'react-dom'
// import ReactPWAInstallProvider, { useReactPWAInstall } from 'react-pwa-install'
// import myLogo from './assets/icons/icon.png'
// import * as serviceWorker from './serviceWorker'

// function App() {
//     const { pwaInstall, supported, isInstalled } = useReactPWAInstall()

//     const handleClick = () => {
//         pwaInstall({
//             title: 'Install Web App',
//             logo: myLogo,
//             features: (
//                 <ul>
//                     <li>Cool feature 1</li>
//                     <li>Cool feature 2</li>
//                     <li>Even cooler feature</li>
//                     <li>Works offline</li>
//                 </ul>
//             ),
//             description: 'This is a very good app that does a lot of useful stuff. ',
//         })
//             .then(() => alert('App installed successfully or instructions for install shown'))
//             .catch(() => alert('User opted out from installing'))
//     }

//     return (
//         <div>
//             {supported() && !isInstalled() && (
//                 <button type="button" onClick={handleClick}>
//                     Install App
//                 </button>
//             )}
//         </div>
//     )
// }

// ReactDOM.render(
//     <ReactPWAInstallProvider enableLogging>
//         <App />
//     </ReactPWAInstallProvider>,
//     document.querySelector('#root')
// )
// serviceWorker.register()
