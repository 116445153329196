import config from 'use/Config'
import mrcFetch from 'util/authFetch'

export function vaPatientToken(code, state) {
    // let HospitalClientId = ''
    // const search = window.location.search
    // const params = new URLSearchParams(search)
    // const code = params.get('code')
    // const state_hospital = params.get('state')

    // HospitalClientId = EHRClientId

    // console.log({ EHRClientSecret })
    // console.log({ HospitalClientId })

    // let ehrhospital = state_hospital
    return addPatientToVA(code, state)

    // let formdata = new FormData()
    // formdata.append('grant_type', 'authorization_code')
    // formdata.append('code', code)
    // formdata.append('client_id', HospitalClientId)
    // formdata.append('client_secret', config.athenaCredentials.CLIENT.SECRET)
    // formdata.append('redirect_uri', config.athenaCredentials.REDIRECT.REDIRECT_URI)
    // console.log(config.athenaCredentials.CLIENT.SECRET)
    // console.log('HospitalClientId', { HospitalClientId })
    // // console.log({formdata});
    // let requestOptions = {
    //     method: 'POST',
    //     body: formdata,
    //     redirect: 'follow',
    // }
    // fetch(tokenFetchUrl, requestOptions)
    //     .then((response) => response.json())
    //     .then((result) => {
    //         // let datatok = result.access_token
    //         // let patient = '857'
    //         // let ehrhospital = state_hospital
    //         console.log('VA Result', result)
    //         // addPatientToVA(result.token, result.patient, ehrhospital)

    //         // addPatientToAthena(datatok, patient, ehrhospital)
    //     })
    //     .catch((error) => {
    //         console.error('Error:', error)
    //     })

    /*

console.log('build 18 dec 12');
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


var urlencoded = new URLSearchParams();
urlencoded.append("client_id", "0oaar4dbrkboPKr1T297");
urlencoded.append("redirect_uri", "https://patient.matchritecare.com/home/importrecords");
urlencoded.append("grant_type", "authorization_code");
urlencoded.append("code", code);
//urlencoded.append("client_secret", "5mrw2OWi90moXOQcFQ5EEoMtFnuyJoOPB_-2lAyv");
urlencoded.append("code_verifier", "M25iVXpKU3puUjFaYWg3T1NDTDQtcW1ROUY5YXlwalNoc0hhakxifmZHag");

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: urlencoded,
  redirect: 'follow'
};

fetch("https://myidentity.platform.athenahealth.com/oauth2/v1/token", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));

*/

    /*

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var urlencoded = new URLSearchParams();
        urlencoded.append("client_id", HospitalClientId);
        urlencoded.append("redirect_uri", config.athenaCredentials.REDIRECT.REDIRECT_URI);
        urlencoded.append("grant_type", "authorization_code");
        urlencoded.append("code", code);
        urlencoded.append("client_secret", config.athenaCredentials.CLIENT.SECRET);
        urlencoded.append("code_verifier", "123");

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        fetch(tokenFetchUrl, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            let datatok = result.access_token
            let patient = '857'
            let ehrhospital = state_hospital
console.log(result);
          //  addPatientToAthena(datatok, patient, ehrhospital)
        })
        .catch((error) => {
            console.error('Error:', error)
        })

        */
}

export function addPatientToVA(token, ehrhospital) {
    let raw = JSON.stringify({
        token: token,
        patientId: '',
        // ProfileId: localStorage.getItem('profileid'),
        ProfileId: localStorage.getItem('selectedProfileId'),
        EHRHospital: ehrhospital,
    })

    let requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: raw,
        redirect: 'follow',
    }
    return mrcFetch(`${config.mrc.baseApiUrl}/VAEHR/ProcessVAData`, requestOptions)
        .then((response) => response.text())
        .then((result) => localStorage.removeItem('selectedProfileId'))
    // .catch((error) => console.log('error', error))
}
