import config from 'use/Config'
import mrcFetch from 'util/authFetch'

export function meditechPatientToken(EHRClientId, EHRGetTokenUrl) {
    let tokenFetchUrl = ''
    let HospitalClientId = ''
    const search = window.location.search
    const params = new URLSearchParams(search)
    const code = params.get('code')
    const state_hospital = params.get('state')

    tokenFetchUrl = EHRGetTokenUrl
    HospitalClientId = EHRClientId
    /*
    let formdata = new FormData()
    formdata.append('grant_type','authorization_code')
    formdata.append('code', code)
    formdata.append('client_id', HospitalClientId)
    formdata.append('client_secret', config.meditechCredentials.CLIENT.SECRET)
    formdata.append('redirect_uri', config.meditechCredentials.REDIRECT.REDIRECT_URI)

    console.log({formdata});
    let requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
    }
    fetch(tokenFetchUrl, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            let datatok = result.access_token
            let patient = result.patient
            let ehrhospital = state_hospital
console.log(result);
            //addPatientToMeditech(datatok, patient, ehrhospital)
        })
        .catch((error) => {
            console.error('Error:', error)
        })

*/

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

    var urlencoded = new URLSearchParams()
    urlencoded.append('grant_type', 'authorization_code')
    urlencoded.append('code', code)
    urlencoded.append('client_id', HospitalClientId)
    urlencoded.append('client_secret', config.meditechCredentials.CLIENT.SECRET)
    urlencoded.append('redirect_uri', config.meditechCredentials.REDIRECT.REDIRECT_URI)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow',
    }
    return fetch(tokenFetchUrl, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            let datatok = result.access_token
            let patient = result.patient
            let ehrhospital = state_hospital
            // console.log(result)
            addPatientToMeditech(datatok, patient, ehrhospital)
        })
    // .catch((error) => {
    //     console.error('Error:', error)
    // })
}

export function addPatientToMeditech(code, state) {
    // export function addPatientToMeditech(token, patientId, ehrhospital) {
    // const search = window.location.search
    // const params = new URLSearchParams(search)
    // const token = params.get('code')
    // const ehrhospital = params.get('state')
    let raw = JSON.stringify({
        token: code,
        // patientId: patientId,
        // ProfileId: localStorage.getItem('profileid'),
        ProfileId: localStorage.getItem('selectedProfileId'),
        EHRHospital: state,
    })

    let requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: raw,
        redirect: 'follow',
    }
    mrcFetch(`${config.mrc.baseApiUrl}/MeditechEHR/ProcessMeditechData`, requestOptions)
        .then((response) => response.text())
        .then((result) => localStorage.removeItem('selectedProfileId'))
        .catch((error) => console.log('error', error))
}
