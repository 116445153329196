import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import Box from '@mui/material/Box'

import { containerStyles, cellStyles, rowStyles, headStyles, paginationStyles, tableStyles } from './DataTable.styles'

const StyledTableCell = styled(TableCell)(cellStyles)
const StyledTableRow = styled(TableRow)(rowStyles)
const StyledTableHead = styled(TableHead)(headStyles)
const StyledTablePagination = styled(TablePagination)(paginationStyles)
const StyledContainer = styled(TableContainer)(containerStyles)
const StyledTableBody = styled(TableBody)(tableStyles)

export default function CustomizedTables(props) {
    const { columns, rows, rowsPerPage, orderByColumn, order, currentPage, showPagination, totalEntries, onPageChange, onRequestSort } = props

    const createSortHandler = (index) => (event) => {
        onRequestSort(event, index)
    }

    return (
        <StyledContainer>
            <Table aria-label="table">
                <StyledTableHead>
                    <TableRow>
                        {columns.map((headCell, index) => (
                            <StyledTableCell key={headCell.id} {...headCell.props}>
                                <TableSortLabel
                                    active={orderByColumn === headCell.id}
                                    direction={orderByColumn === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(index)}
                                >
                                    {headCell.label}
                                    {orderByColumn === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </StyledTableHead>
                <StyledTableBody>
                    {!rows.length && (
                        <StyledTableCell colSpan={columns.length}>
                            <Typography textAlign="center">No records found</Typography>
                        </StyledTableCell>
                    )}
                    {rows.map((row) => (
                        <StyledTableRow key={row.id}>
                            {row.values.map((value, i) => (
                                <StyledTableCell component="th" scope="row" key={`${row.id}-${i}`}>
                                    {value || '--'}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    ))}
                </StyledTableBody>
                {showPagination ? (
                    <TableFooter>
                        <TableRow>
                            <StyledTablePagination
                                rowsPerPageOptions={[]}
                                count={totalEntries}
                                rowsPerPage={rowsPerPage}
                                page={currentPage}
                                showFirstButton
                                showLastButton
                                onPageChange={onPageChange}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                ) : null}
            </Table>
        </StyledContainer>
    )
}

CustomizedTables.propTypes = {
    onPageChange: PropTypes.func,
    rowsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    showPagination: PropTypes.bool,
    totalEntries: PropTypes.number,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            props: PropTypes.any,
        })
    ),
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any.isRequired,
            values: PropTypes.array,
        })
    ),
}
