import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

export const FieldsBox = styled(Box)(({ theme }) => ({
  marginBottom: 40,

  [theme.breakpoints.down('sm')]: {
    marginBottom: 20,
  },
}))

export const ColumnsHr = styled(Box)(({ theme }) => ({
  height: '100%',
  width: 2,
  backgroundColor: theme.palette.grey.A200,
  margin: '0 auto',
}))

export const FacilityLabel = styled(Typography)(({ theme }) => ({
  margin: '40px 0 20px',

  [theme.breakpoints.down('sm')]: {
    margin: '30px 0 20px',
  },
}))

export const VeteransBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],

  '& h6': {
    margin: 0,
    flex: 1,
    color: 'rgba(0, 0, 0, 0.6)',
  },


  '& .va-label': {
    height: '56px',
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    paddingLeft: 14,
  }
}));

export const MissingFaciltyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& > .MuiFormControl-root': {
    flex: 1,
  }
}));
