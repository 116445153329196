import React, { useMemo, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import DataTable from './DataTable'
import MobileListTable from '../MobileListTable'
import MobileTableCard from 'components/MobileTableCard'

const DEFAULT_PAGE_SIZE = 8

function descendingComparator(a, b, valueIndex) {
    const aValues = a || {}
    const bValues = b || {}
    if (bValues[valueIndex] < aValues[valueIndex]) {
        return -1
    }
    if (bValues[valueIndex] > aValues[valueIndex]) {
        return 1
    }
    return 0
}

function getComparator(order, valueIndex) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, valueIndex)
        : (a, b) => -descendingComparator(a, b, valueIndex)
}

const DataGridWrapper = (props) => {
    const { columns, rows, pageSize = DEFAULT_PAGE_SIZE, renderRowMobile } = props
    const theme = useTheme()
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
    const [currentPage, setCurrentPage] = React.useState(0)
    const [order, setOrder] = React.useState('asc')
    const [orderByColumn, setOrderByColumn] = React.useState()

    useEffect(() => {
        setCurrentPage(0)
    }, [rows])

    const onRequestSort = (event, index) => {
        const columnName = columns[index]?.field
        const isAsc = orderByColumn === columnName && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderByColumn(columnName)
    }

    const tableColumns = React.useMemo(() => {
        const gridColumns = columns || []

        return gridColumns.map(({ field, headerName, ...props }) => ({
            id: field,
            label: headerName,
            props,
        }))
    }, [columns])

    const tableRows = useMemo(() => {
        const gridColumns = columns || []
        const rowsPerPage = pageSize || DEFAULT_PAGE_SIZE

        if (!isMediumScreen) {
            return []
        }

        let sortedRows = rows
        if (order && orderByColumn) {
            sortedRows = rows.sort(getComparator(order, orderByColumn))
        }
        const renderRows = (sortedRows || []).slice(
            0 + currentPage * rowsPerPage,
            rowsPerPage + currentPage * rowsPerPage
        )
        return renderRows.map((row, index) => ({
            id: `row-${index}`,
            values: gridColumns.map(({ field, renderCell }) => {
                const value = row[field]
                if (renderCell) {
                    return renderCell({ row, value, id: field })
                }
                return value
            }),
        }))
    }, [rows, order, orderByColumn, columns, isMediumScreen, pageSize, currentPage])

    const mobileTableRows = useMemo(() => {
        const gridColumns = columns || []

        if (isMediumScreen) {
            return []
        }
        const rowsPerPage = pageSize || DEFAULT_PAGE_SIZE
        const tableRows = (rows || []).slice(0 + currentPage * rowsPerPage, rowsPerPage + currentPage * rowsPerPage)

        if (!renderRowMobile) {
            return tableRows.map((row, index) => ({
                id: `row-${index}`,
                value: <MobileTableCard row={row} columns={gridColumns} />,
            }))
        }

        return tableRows.map((row, index) => ({
            id: `row-${index}`,
            value: renderRowMobile(row, index),
        }))
    }, [rows, columns, isMediumScreen, pageSize, currentPage, renderRowMobile])

    return isMediumScreen ? (
        <DataTable
            columns={tableColumns}
            rows={tableRows}
            order={order}
            orderByColumn={orderByColumn}
            rowsPerPage={pageSize}
            showPagination={Boolean(rows?.length)}
            currentPage={currentPage}
            totalEntries={rows?.length}
            onRequestSort={onRequestSort}
            onPageChange={(event, page) => setCurrentPage(page)}
        />
    ) : (
        <MobileListTable
            columns={tableColumns}
            items={mobileTableRows}
            showPagination={Boolean(rows?.length)}
            rowsPerPage={pageSize}
            currentPage={currentPage}
            totalEntries={rows?.length}
            onPageChange={(event, page) => setCurrentPage(page)}
        />
    )
}

export default DataGridWrapper
