import React from 'react'
import ReactLoading from 'react-loading'
import 'react-phone-input-2/lib/style.css'
import Radio from '@mui/material/Radio'
import PhoneInput from 'react-phone-input-2'
import DateFnsUtils from '@date-io/date-fns'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Autocomplete from '@mui/lab/Autocomplete'
import FormControlLabel from '@mui/material/FormControlLabel'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'

import Colors from 'use/Color'
import SideBar from 'components/layout/SideBar/SideBar'
import popupNotification from 'components/Notification/popupNotification'

import './CreateDependant.scss'

class CreateDependant extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.hiddenPhotoFileInput = React.createRef()
        this.hiddenPlanImageFileInput = React.createRef()
    }

    componentDidMount() {
        this.initialState()
    }

    initialState = () => {
        this.setState({
            UserId: null,
            avatarSource: null,
            firstName: '',
            lastName: '',
            relation: '',
            dob: null,
            gender: 'male',
            language: '',
            contacts: [
                {
                    Home: '',
                    Cell: '',
                    Work: '',
                    selected_label: 'Type',
                },
            ],
            preference: 'primary',
            plan: '',
            contactNumber1: '',
            groupName: '',
            copay: '',
            EffectiveFrom: null,
            ExpiresOn: null,
            firstNameCard: '',
            middleNameCard: '',
            lastNameCard: '',
            planImage: null,
            InsuredEntityType: 'insuredperson',
            relation1: '',
            InsuredEntityFirstName: '',
            InsuredEntityMiddleName: '',
            InsuredEntityLastName: '',
            InsuredEntityContactNumber: '',
            InsuredEntitySsn: '',
            InsInsuredEntityGender: 'male',
            InsuredEntityDateOfBirth: null,
            InsuredEntityAddressLine1: '',
            InsuredEntityAddressLine2: '',
            InsuredEntityCountry: '',
            InsuredEntityState: '',
            InsuredEntityCity: '',
            InsuredEntityCounty: '',
            InsuredEntityPostalCode: '',
            InsuredEntityPhoneAreaCode: '+1',
            loading: false,
        })
    }

    handlePhotoClick = (event) => {
        this.hiddenPhotoFileInput.current.click()
    }

    handlePlanImageClick = (event) => {
        this.hiddenPlanImageFileInput.current.click()
    }

    Save = () => {
        this.setState({ loading: true })
        let that = this
        setTimeout(function () {
            that.setState({ loading: false })
            localStorage.removeItem('updateDependentId')
            that.setState({ loading: false })
            popupNotification('Succefully Saved', true)
            that.props.history.push('/home/dependents')
        }, 3000)
    }

    Cancel = () => {
        this.initialState()
        popupNotification('All Cleared', true)
    }

    render() {
        const languagedata = [
            'Afrikaans',
            'Akan',
            'Albanian',
            'Amharic',
            'Arabic',
            'Armenian',
            'ASL',
            'Assamese',
            'Assyrian',
            'Azerbaijani',
            'Bahdini',
            'Bambara',
            'Bashkir',
            'Basque',
            'Belarusian',
            'Bengali',
            'Bosnian',
            'Bravanese',
            'Bulgarian',
            'Burmese',
            'Cambodian',
            'Cantonese',
            'Catalan',
            'Cebuano',
            'Chaldean',
            'Chamorro',
            'Chaozhou',
            'Chavacano',
            'Chin',
            'Chuukese',
            'Cree',
            'Croatian',
            'Czech',
            'Dakota',
            'Danish',
            'Dari',
            'Dinka',
            'Dioula',
            'Dutch',
            'Dzongkha',
            'English',
            'Estonian',
            'Ewe',
            'Fante',
            'Faroese',
            'Farsi',
            'Fijian Hindi',
            'Finnish',
            'Flemish',
            'French',
            'French Canadian',
            'Frisian',
            'Fujianese',
            'Fukienese',
            'Fula',
            'Fulani',
            'Fuzhou',
            'Ga',
            'Gaelic',
            'Galician',
            'Ganda',
            'Georgian',
            'German',
            'Gorani',
            'Greek',
            'Gujarati',
            'Haitian Creole',
            'Hakka',
            'Hassaniyya',
            'Hausa',
            'Hebrew',
            'Hiligaynon',
            'Hindi',
            'Hmong',
            'Hungarian',
            'Ibanag',
            'Icelandic',
            'Igbo',
            'Ilocano',
            'Ilonggo',
            'Indian',
            'Indonesian',
            'Inuktitut',
            'Irish',
            'Italian',
            'Jakartanese',
            'Japanese',
            'Javanese',
            'Kanjobal',
            'Kannada',
            'Karen',
            'Kashmiri',
            'Kazakh',
            'Khalkha',
            'Khmer',
            'Kikuyu',
            'Kinyarwanda',
            'Kirundi',
            'Korean',
            'Kosovan',
            'Kotokoli',
            'Krio',
            'Kurdish',
            'Kurmanji',
            'Kyrgyz',
            'Lakota',
            'Laotian',
            'Latin',
            'Latvian',
            'Lingala',
            'Lithuanian',
            'Luganda',
            'Luo',
            'Lusoga',
            'Luxembourgeois',
            'Maay',
            'Macedonian',
            'Malagasy',
            'Malay',
            'Malayalam',
            'Maldivian',
            'Maltese',
            'Mandarin',
            'Mandingo',
            'Mandinka',
            'Maori',
            'Marathi',
            'Marshallese',
            'Mien',
            'Mirpuri',
            'Mixteco',
            'Moldovan',
            'Mongolian',
            'Navajo',
            'Neapolitan',
            'Nepali',
            'Norwegian',
            'Nuer',
            'Nyanja',
            'Ojibaway',
            'Oriya',
            'Oromo',
            'Ossetian',
            'Pahari',
            'Pampangan',
            'Pashto',
            'Patois',
            'Pidgin English',
            'Polish',
            'Portuguese',
            'Pothwari',
            'Pulaar',
            'Punjabi',
            'Putian',
            'Quanxi',
            'Quechua',
            'Romani',
            'Romanian',
            'Romansch',
            'Rundi',
            'Russian',
            'Samoan',
            'Sango',
            'Sanskrit',
            'Serbian',
            'Shanghainese',
            'Shona',
            'Sichuan',
            'Sicilian',
            'Sindhi',
            'Sinhala',
            'Sinhalese',
            'Siswati/Swazi',
            'Slovak',
            'Slovene',
            'Slovenian',
            'Somali',
            'Soninke',
            'Sorani',
            'Sotho',
            'Spanish',
            'Sundanese',
            'Susu',
            'Swahili',
            'Swedish',
            'Sylhetti',
            'Tagalog',
            'Taiwanese',
            'Tajik',
            'Tamil',
            'Telugu',
            'Thai',
            'Tibetan',
            'Tigrinya',
            'Tongan',
            'Tshiluba',
            'Tsonga',
            'Tswana',
            'Turkish',
            'Turkmen',
            'Uighur',
            'Ukrainian',
            'Urdu',
            'Uzbek',
            'Venda',
            'Vietnamese',
            'Visayan',
            'Welsh',
            'Wolof',
            'Xhosa',
            'Yao',
            'Yiddish',
            'Yoruba',
            'Yupik',
            'Zulu',
        ]
        const relationdata = [
            { label: 'Self', value: 'self' },
            { label: 'Father', value: 'father' },
            { label: 'Mother', value: 'mother' },
            { label: 'Son', value: 'son' },
            { label: 'Daughter', value: 'daughter' },
            { label: 'Paternal Grand Father', value: 'pgfather' },
            { label: 'Paternal Grand Mother', value: 'pgmother' },
            { label: 'Maternal Grand Father', value: 'mgfather' },
            { label: 'Maternal Grand Mother', value: 'mgmother' },
        ]
        const countries = [
            { label: 'United States', value: 'us' },
            { label: 'Canada', value: 'ca' },
            { label: 'Mexico', value: 'me' },
            { label: 'Australia', value: 'au' },
            { label: 'Italy', value: 'it' },
        ]
        return (
            <div className="_container createdependant">
                <SideBar title="patientprofile" />
                <div className="container-right">
                    <div className="main-title">
                        <ArrowBackIcon
                            style={{ cursor: 'pointer', marginRight: 10 }}
                            onClick={() => {
                                localStorage.removeItem('updateDependentId')
                                this.props.history.goBack()
                            }}
                        />
                        <div>{localStorage.getItem('updateDependentId') ? 'Update' : 'Create'} Dependent</div>
                    </div>
                    <div className="white-container">
                        <div
                            style={{
                                padding: 20,
                                display: 'flex',
                                flexDirection: 'row',
                                flex: 1,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    flex: 0.26,
                                    marginTop: 20,
                                }}
                            >
                                <div className="profile-image" onClick={this.handlePhotoClick}></div>
                                <input type="file" ref={this.hiddenPhotoFileInput} style={{ display: 'none' }} />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 0.37,
                                }}
                            >
                                <TextField
                                    type="text"
                                    label="First Name"
                                    name="firstname"
                                    value={this.state.firstName}
                                    onChange={(event) =>
                                        this.setState({
                                            firstName: event.target.value,
                                        })
                                    }
                                    required
                                    style={{ margin: '10px 20px' }}
                                />
                                <TextField
                                    type="text"
                                    label="Last Name"
                                    name="lastname"
                                    required
                                    style={{ margin: '10px 20px' }}
                                />
                                <Autocomplete
                                    id="relationship-autocomplete"
                                    size="small"
                                    onSelect={(event) =>
                                        this.setState({
                                            relation: event.target.value,
                                        })
                                    }
                                    options={relationdata}
                                    getOptionLabel={(option) => option.label}
                                    defaultValue={relationdata[0]}
                                    style={{ margin: '10px 20px' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Relationship"
                                            placeholder="Favorites"
                                            required
                                        />
                                    )}
                                />
                                <LocalizationProvider dateAdapter={DateFnsUtils}>
                                    <DatePicker
                                        id="dob-picker-dialog"
                                        label="DOB"
                                        format="MM/dd/yyyy"
                                        value={this.state.dob}
                                        onChange={(date) => this.setState({ dob: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        required
                                        style={{ margin: '10px 20px' }}
                                        // disable future dates
                                        inputProps={{ max: new Date().toISOString().split('T')[0] }}
                                    />
                                </LocalizationProvider>
                                <FormControl component="fieldset" style={{ margin: '10px 20px 0' }} required>
                                    <FormLabel component="legend">Gender</FormLabel>
                                    <RadioGroup
                                        aria-label="gender"
                                        name="InsInsuredEntityGender"
                                        value={this.state.gender}
                                        onChange={(event) => {
                                            this.setState({
                                                gender: event.target.value,
                                            })
                                        }}
                                        row
                                    >
                                        <FormControlLabel
                                            value="male"
                                            control={<Radio color="primary" />}
                                            label="Male"
                                        />
                                        <FormControlLabel
                                            value="female"
                                            control={<Radio color="primary" />}
                                            label="Female"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <Autocomplete
                                    id="prefered-language-autocomplete"
                                    size="small"
                                    options={languagedata}
                                    getOptionLabel={(option) => option}
                                    defaultValue={languagedata[40]}
                                    style={{ margin: '10px 20px' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Prefered Language"
                                            placeholder="Favorites"
                                        />
                                    )}
                                />
                                <div
                                    style={{
                                        margin: '20px 20px 0',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormLabel component="legend">Contact Number</FormLabel>
                                    <AddCircleOutlineOutlinedIcon
                                        style={{
                                            color: Colors.primary,
                                            cursor: 'pointer',
                                            fontSize: 30,
                                            marginLeft: 10,
                                        }}
                                        onClick={() => {
                                            let contacts = JSON.parse(JSON.stringify(this.state.contacts))
                                            contacts.push({
                                                Home: '',
                                                Cell: '',
                                                Work: '',
                                                selected_label: 'Type',
                                            })
                                            this.setState({
                                                contacts: contacts,
                                            })
                                        }}
                                    />
                                </div>
                                {this.state.contacts.map((item, index) => (
                                    <FormControl
                                        key={index}
                                        style={{
                                            margin: '0px 20px 20px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: '20%' }}
                                            onChange={(event) => {
                                                let contacts = [...this.state.contacts]
                                                contacts[index].selected_label = event.target.value.label
                                                this.setState({
                                                    contacts: contacts,
                                                })
                                            }}
                                        >
                                            <MenuItem value={'Home'}>Home</MenuItem>
                                            <MenuItem value={'Cell'}>Cell</MenuItem>
                                            <MenuItem value={'Work'}>Work</MenuItem>
                                        </Select>
                                        <TextField
                                            type="number"
                                            label="01234567890"
                                            name="contactNumber"
                                            style={{ width: '70%' }}
                                            value={item[`${item.selected_label}`]}
                                            onChange={(event) => {
                                                let contacts = [...this.state.contacts]

                                                contacts[index][`${item.selected_label}`] = event.target.value

                                                this.setState({
                                                    contacts: contacts,
                                                })
                                            }}
                                        />
                                        <RemoveCircleOutlineOutlinedIcon
                                            style={{
                                                color: Colors.primary,
                                                cursor: 'pointer',
                                                fontSize: 30,
                                            }}
                                            onClick={() => {
                                                let contacts = JSON.parse(JSON.stringify(this.state.contacts))
                                                contacts.splice(index, 1)
                                                this.setState({
                                                    contacts: contacts,
                                                })
                                            }}
                                        />
                                    </FormControl>
                                ))}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around',
                                        margin: 50,
                                    }}
                                >
                                    <Button variant="contained" className="save-button" onClick={this.Save}>
                                        Save
                                        {this.state.loading && (
                                            <span style={{ marginLeft: 10 }}>
                                                <ReactLoading type="spokes" color="#FFFFFF" width={15} height={15} />
                                            </span>
                                        )}
                                    </Button>
                                    <Button variant="contained" className="cancel-button" onClick={this.Cancel}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 0.37,
                                }}
                            >
                                <FormControl component="fieldset" style={{ margin: '20px', paddingBottom: 7 }}>
                                    <FormLabel
                                        component="legend"
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: 20,
                                        }}
                                    >
                                        Insurance & Plans (Optional)
                                    </FormLabel>
                                </FormControl>
                                <FormControl component="fieldset" style={{ margin: '10px 20px 15px' }}>
                                    <FormLabel component="legend">Preference</FormLabel>
                                    <RadioGroup
                                        aria-label="gender"
                                        name="InsInsuredEntityGender"
                                        value={this.state.preference}
                                        onChange={(event) => {
                                            this.setState({
                                                preference: event.target.value,
                                            })
                                        }}
                                        row
                                    >
                                        <FormControlLabel
                                            value="primary"
                                            control={<Radio color="primary" />}
                                            label="Primary"
                                        />
                                        <FormControlLabel
                                            value="secondary"
                                            control={<Radio color="primary" />}
                                            label="Secondary"
                                        />
                                        <FormControlLabel
                                            value="tertiary"
                                            control={<Radio color="primary" />}
                                            label="Tertiary"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl style={{ margin: '-9px 20px 10px' }}>
                                    <InputLabel id="demo-simple-select-label">Plan</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.plan}
                                        onChange={(event) =>
                                            this.setState({
                                                plan: event.target.value,
                                            })
                                        }
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    type="text"
                                    label="Contact Number"
                                    name="contactNumber"
                                    style={{ margin: '10px 20px' }}
                                />
                                <TextField
                                    type="text"
                                    label="Group Name"
                                    name="groupname"
                                    style={{ margin: '10px 20px' }}
                                />
                                <TextField type="text" label="Copay" name="copay" style={{ margin: '10px 20px' }} />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                                        <DatePicker
                                            id="EffectiveFrom-picker-dialog"
                                            label="Effective From"
                                            format="MM/dd/yyyy"
                                            value={this.state.EffectiveFrom}
                                            onChange={(date) => {
                                                this.setState({
                                                    EffectiveFrom: date,
                                                })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            style={{ margin: '10px 20px' }}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                                        <DatePicker
                                            id="ExpiresOn-picker-dialog"
                                            label="Expires On"
                                            format="MM/dd/yyyy"
                                            value={this.state.ExpiresOn}
                                            onChange={(date) => {
                                                this.setState({
                                                    ExpiresOn: date,
                                                })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            style={{ margin: '10px 20px' }}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <TextField
                                    type="text"
                                    label="First Name on Card"
                                    name="firstNameCard"
                                    style={{ margin: '10px 20px' }}
                                />
                                <TextField
                                    type="text"
                                    label="Middle Name on Card"
                                    name="middleNameCard"
                                    style={{ margin: '10px 20px' }}
                                />
                                <TextField
                                    type="text"
                                    label="Last Name on Card"
                                    name="lastNameCard"
                                    style={{ margin: '10px 20px' }}
                                />
                                <FormControl component="fieldset" style={{ margin: '10px 20px' }}>
                                    <FormLabel component="legend">Plan Image</FormLabel>
                                </FormControl>
                                <div className="plan-image" onClick={this.handlePlanImageClick}></div>
                                <input type="file" ref={this.hiddenPlanImageFileInput} style={{ display: 'none' }} />
                                <FormControl component="fieldset" style={{ margin: '10px 20px' }}>
                                    <RadioGroup
                                        aria-label="gender"
                                        name="InsInsuredEntityGender"
                                        value={this.state.InsuredEntityType}
                                        onChange={(event) => {
                                            this.setState({
                                                InsuredEntityType: event.target.value,
                                            })
                                        }}
                                        row
                                    >
                                        <FormControlLabel
                                            value="insuredperson"
                                            control={<Radio color="primary" />}
                                            label="Insured Person"
                                        />
                                        <FormControlLabel
                                            value="insuredpersonemployer"
                                            control={<Radio color="primary" />}
                                            label="Insured Person Employer"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <Autocomplete
                                    id="relationship1-autocomplete"
                                    size="small"
                                    onSelect={(event) =>
                                        this.setState({
                                            relation: event.target.value,
                                        })
                                    }
                                    options={relationdata}
                                    getOptionLabel={(option) => option.label}
                                    defaultValue={relationdata[0]}
                                    style={{ margin: '10px 20px' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Relationship"
                                            placeholder="Favorites"
                                        />
                                    )}
                                />
                                <TextField
                                    type="text"
                                    label="First Name"
                                    name="firstName1"
                                    style={{ margin: '10px 20px' }}
                                />
                                <TextField
                                    type="text"
                                    label="Middle Name"
                                    name="middleName1"
                                    style={{ margin: '10px 20px' }}
                                />
                                <TextField
                                    type="text"
                                    label="Last Name"
                                    name="lastName1"
                                    style={{ margin: '10px 20px' }}
                                />
                                <TextField
                                    type="number"
                                    label="Contact Number"
                                    name="contactNumber1"
                                    style={{ margin: '10px 20px' }}
                                />
                                <TextField type="text" label="Last 4 SSN" name="ssn" style={{ margin: '10px 20px' }} />
                                <FormControl component="fieldset" style={{ margin: '10px 20px 0' }}>
                                    <FormLabel component="legend">Gender</FormLabel>
                                    <RadioGroup
                                        aria-label="gender"
                                        name="InsInsuredEntityGender"
                                        value={this.state.InsInsuredEntityGender}
                                        onChange={(event) => {
                                            this.setState({
                                                InsInsuredEntityGender: event.target.value,
                                            })
                                        }}
                                        row
                                    >
                                        <FormControlLabel
                                            value="male"
                                            control={<Radio color="primary" />}
                                            label="Male"
                                        />
                                        <FormControlLabel
                                            value="female"
                                            control={<Radio color="primary" />}
                                            label="Female"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <LocalizationProvider dateAdapter={DateFnsUtils}>
                                    <DatePicker
                                        id="InsuredEntityDateOfBirth-picker-dialog"
                                        label="Date of Birth"
                                        format="MM/dd/yyyy"
                                        value={this.state.InsuredEntityDateOfBirth}
                                        onChange={(date) => {
                                            this.setState({
                                                InsuredEntityDateOfBirth: date,
                                            })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{ margin: '10px 20px' }}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    type="text"
                                    label="Address Line 1"
                                    name="addressLine1"
                                    style={{ margin: '10px 20px' }}
                                />
                                <TextField
                                    type="text"
                                    label="Address Line 2"
                                    name="addressLine2"
                                    style={{ margin: '10px 20px' }}
                                />
                                <Autocomplete
                                    id="country-autocomplete"
                                    size="small"
                                    options={countries}
                                    getOptionLabel={(option) => option.label}
                                    defaultValue={countries[0]}
                                    style={{ margin: '10px 20px' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Country"
                                            placeholder="Favorites"
                                        />
                                    )}
                                />
                                <FormControl style={{ margin: '-9px 20px 10px' }}>
                                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.InsuredEntityState}
                                        onChange={(event) =>
                                            this.setState({
                                                InsuredEntityState: event.target.value,
                                            })
                                        }
                                    >
                                        <MenuItem value={1}>Califonia</MenuItem>
                                        <MenuItem value={2}>Washington</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl style={{ margin: '-9px 20px 10px' }}>
                                    <InputLabel id="demo-simple-select-label">City</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.InsuredEntityCity}
                                        onChange={(event) =>
                                            this.setState({
                                                InsuredEntityCity: event.target.value,
                                            })
                                        }
                                    >
                                        <MenuItem value={'New York'}>New York</MenuItem>
                                        <MenuItem value={'Chicago'}>Chicago</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField type="text" label="County" name="county" style={{ margin: '10px 20px' }} />
                                <TextField
                                    type="number"
                                    label="Postal Code"
                                    name="postalCode"
                                    style={{ margin: '10px 20px' }}
                                />
                                <div style={{ margin: '40px 20px' }}>
                                    <PhoneInput
                                        country={'us'}
                                        value={this.state.InsuredEntityPhoneAreaCode}
                                        name="phone"
                                        onChange={(phone) =>
                                            this.setState({
                                                InsuredEntityPhoneAreaCode: phone,
                                            })
                                        }
                                        inputStyle={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateDependant
