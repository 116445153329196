import React, { useEffect, useState } from 'react'
import Tabs from 'components/Tabs/Tabs'

import { useSearchField } from 'hoc/SearchFieldProvider'
import { DependentService } from 'services/dependent.service'
import { RecordsService } from 'services/myrecords.service'
import { Button } from '@mui/material'

import { LoginService } from 'services/login.service'
import { Stack } from '@mui/material'
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined'
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined'
import NotesModal from './NotesModal'

const gap = {
    vitals: '120px',
    medications: '240px',
    procedures: '240px',
    conditions: '240px',
    labs: '120px',
    immunization: '260px',
    allergies: '220px',
    drvisitnotes: '260px',
    treatmentplan: '260px',
    myrecords: '300px',
}
const MyRecords = ({ match, history }) => {
    const { params } = match
    const { selectedDependent, setDependents, recordType, setRecordType } = useSearchField()
    const [tabs, setTabs] = useState([])
    const [tab, setTab] = useState()
    const [note, setNote] = useState()
    const [selectedProfileId, setSelectedProfileId] = useState(localStorage.getItem('profileid'))
    const [tabIndex, setIndex] = useState(0)
    const [showNotesDialog, setShowNotesDialog] = useState()
    const { tabName = 'vitals' } = params || {}
    const handleOnChange = (index) => {
        const tab = tabs[index]
        if (!tab) return
        history.push(`/home/myrecords/${tab.tabUrl}`)
    }
    const getDependents = async () => {
        const email = localStorage.getItem('email')
        if (!email) return
        const [loginData, dependentData] = await Promise.all([
            LoginService.getUser(email),
            DependentService.getAllDependents(),
        ])
        if (loginData.data && dependentData.data) {
            const payload = dependentData.data.map((option) => {
                return { id: option.id, name: `${option.firstName} ${option.lastName}` }
            })
            setDependents([
                {
                    id: loginData.data.profile.id,
                    name: `${loginData.data.profile.firstName} ${loginData.data.profile.lastName} - (Self)`,
                },
                ...payload,
            ])
        }
    }

    const getRecordTypeNote = async (id) => {
        const { data: result } = await RecordsService.getRecordTypeNote(id || selectedProfileId, tab.id)
        setNote(result?.note)
    }

    useEffect(() => {
        if (recordType.length !== 0) setTabs(recordType)
    }, [recordType])

    useEffect(() => {
        if (tabName && tabs && selectedProfileId) {
            setIndex(tabs.findIndex(({ tabUrl }) => tabUrl === tabName))
            const selectedTab = tabs.find((x) => x.tabUrl === tabName)
            setTab(selectedTab)
        }
    }, [tabs, tabName, selectedProfileId])

    useEffect(() => {
        if (selectedDependent?.id !== selectedProfileId && tab) {
            const selectedValue = selectedDependent?.id || localStorage.getItem('profileid')
            setSelectedProfileId(selectedValue)
            getRecordTypeNote(selectedValue)
        }
    }, [selectedDependent, selectedProfileId, tab, localStorage.getItem('profileid')])

    return (
        <>
            <div className="container-right">
                <div style={{ position: 'relative' }}>
                    <Stack
                        direction="row"
                        sx={{
                            position: 'absolute',
                            left: params?.tabName ? gap[tabName] : '280px',
                            top: '-85px',
                            '@media (max-width: 786px)': {
                                left: '10px',
                                top: '-45px',
                            },
                        }}
                    >
                        <Button
                            style={{ background: '#176571' }}
                            variant="contained"
                            size="small"
                            startIcon={<LibraryAddOutlinedIcon />}
                            onClick={() => setShowNotesDialog(true)}
                            endIcon={note ? <DoneAllOutlinedIcon /> : <></>}
                        >
                            Notes
                        </Button>
                    </Stack>
                </div>
                {tabs?.length ? <Tabs tabs={tabs} initialIndex={tabIndex} onChange={handleOnChange} /> : <></>}
            </div>
            {showNotesDialog && (
                <NotesModal
                    title={`${tab?.name || 'Vitals '} Notes`}
                    isOpen={showNotesDialog}
                    selectedTab={tab}
                    selectedNote={note}
                    selectedProfileId={selectedProfileId}
                    onClose={async (value) => {
                        if (value === 'updated') {
                            await getRecordTypeNote()
                        }
                        setShowNotesDialog(false)
                    }}
                />
            )}
        </>
    )
}

export default MyRecords
