import axiosInstance from 'util/axios'
export const RecordsService = {
    getAllRecords: async () => {
        try {
            const response = await axiosInstance.get(`/Providers/GetRecordType`)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    getRecordTypeNote: async (profileId, recordTypeId) => {
        try {
            const response = await axiosInstance.get(
                `/MergeEHR/RecordNote/Profile/${profileId}/RecordNote/${recordTypeId}`
            )
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    saveRecordTypeNote: async (payload) => {
        try {
            const response = await axiosInstance.post(`/Providers/AddUpdateRecordNote`, payload)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    getDiagnosticReport: async (docId) => {
        try {
            const response = await axiosInstance.get(
                `/MergeEHR/DiagnosticReportBinary?docid=${docId}`
            )
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
}
