import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import SmsIcon from '@mui/icons-material/Sms'
import IosShareIcon from '@mui/icons-material/IosShare'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ShareRecordsModal from 'components/ShareRecordModal/ShareRecordsModal'
import { useStyles } from './ShareMyRecordsCard.styles'
import CardField from '../CardField'
import Button from '../MrcButton'

const ShareMyRecordsCard = (props) => {
    const [showDialog, setShowDialog] = React.useState(false)
    const { row, type, onChat, onShare, onBook, onRemove } = props
    // const { providerName, providerLastName, providerType } = row;
    const classes = useStyles()

    return (
        <Card className={classes.wrapper} variant="outlined">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <div className={classes.head}>
                            {type === 'doctors' ? <div className="photo male" /> : <div />}
                            <IconButton color="error" onClick={() => onRemove(row)}>
                                <DeleteOutlinedIcon />
                            </IconButton>
                        </div>
                    </Grid>
                    {type === 'doctors' && (
                        <>
                            <Grid item xs={4}>
                                <CardField label="Name" value={row.providerName + ' ' + row.providerLastName} />
                            </Grid>
                            <Grid item xs={4}>
                                <CardField label="Type" value={row.providerType} />
                            </Grid>
                            <Grid item xs={4}>
                                <CardField
                                    label="Shared Profile Name"
                                    value={
                                        <ul>
                                            {row?.sharedProfile?.map((sharedProfile) => (
                                                <li style={{ fontSize: '12px' }}>{sharedProfile.name}</li>
                                            ))}
                                        </ul>
                                    }
                                />
                            </Grid>
                        </>
                    )}
                    {type === 'organization' && (
                        <>
                            <Grid item xs={4}>
                                <CardField label="Name" value={row.organizationName} />
                            </Grid>
                            <Grid item xs={4}>
                                <CardField label="Location" value={row.location} />
                            </Grid>
                            <Grid item xs={4}>
                                <CardField
                                    label="Shared Profile Name"
                                    value={
                                        <ul>
                                            {row?.sharedProfile?.map((sharedProfile) => (
                                                <li style={{ fontSize: '12px' }}>{sharedProfile.name}</li>
                                            ))}
                                        </ul>
                                    }
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardContent>
            <CardActions>
                <Grid container spacing={1}>
                    {/* <Grid item xs={4}>
                        <Button startIcon={<SmsIcon />} size="small" fullWidth onClick={() => onChat(row)}>
                            Chat
                        </Button>
                    </Grid> */}
                    <Grid item xs={12}>
                    {/* <Grid item xs={4}> */}
                        <Button startIcon={<IosShareIcon />} size="small" fullWidth onClick={() => setShowDialog(true)}>
                            Share
                        </Button>
                        <ShareRecordsModal
                            isOpen={showDialog}
                            onClose={() => setShowDialog(false)}
                            onShare={(checkedMongoTables, selectedProfileIds) =>
                                onShare(checkedMongoTables, selectedProfileIds)
                            }
                            sharedTables={row.sharedTables || []}
                            sharedProfiles={row.sharedProfile || []}
                        />
                        {/* <ShareRecordsModal
                            isOpen={showDialog}
                            onClose={() => setShowDialog(false)}
                            onShare={() => onShare(row)}
                        /> */}
                    </Grid>
                    {/* <Grid item xs={4}>
                        <Button startIcon={<EditNoteOutlinedIcon />} size="small" fullWidth onClick={() => onBook(row)}>
                            Book
                        </Button>
                    </Grid> */}
                </Grid>
            </CardActions>
        </Card>
    )
}

export default ShareMyRecordsCard
