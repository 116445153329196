import React from 'react'
import config from 'use/Config'
import Loader from 'components/Loader/Loader'

class ViewXray extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        this.setState({ loading: false })
    }

    render() {
        if (this.state.loading) {
            return <Loader pageName="viewxray" />
        }

        return (
            // Imbed external page to view x-rays
            // <div className="container-right">
            //     <div className="white-container" style={{ overflowY: 'hidden' }}>
                    
            //     </div>
            // </div>
            <iframe src={config.medaviewer.url} className="w-100 h-100" title="View Xray" />
        )
    }
}

export default ViewXray
