import React, { useState } from 'react'
import { Tab, Tabs } from '@mui/material'

import PrivacyPolicy from 'components/NewPrivacyPolicy'
import TermsAndCondition from 'components/NewTermsAndCondition'
const tabs = ['privacy', 'terms']

const UserPrivacyPolicy = () => {
    const [tab, setTab] = useState('privacy')

    return (
        <div className="container-right">
            <div className="white-container padding-28">
                <Tabs
                    value={tab}
                    onChange={(_e, value) => setTab(value)}
                    sx={{
                        px: 2,
                        pr: 5,
                    }}
                >
                    {tabs.map((tab) => (
                        <Tab key={tab} label={tab} value={tab} />
                    ))}
                </Tabs>
                {tab === 'privacy' && <PrivacyPolicy />}
                {tab === 'terms' && <TermsAndCondition />}
            </div>
        </div>
    )
}

export default UserPrivacyPolicy
