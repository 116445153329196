import { makeStyles } from '@mui/styles'

export const tabStyles = ({ theme }) => ({
    background: '#ECECEC',
    textTransform: 'none',
    minWidth: 132,
    minHeight: '44px !important ',
    maxHeight: '44px !important ',
    margin: 3,
    '&:hover': {
        background: '#CACACA',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        marginRight: 2,
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        minWidth: 80,
        minHeight: 30,
        maxHeight: 30,
        padding: 0,
    },
})

export const tabsStyles = ({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        flexWrap: 'wrap',
    },
    marginBottom: 15,
    [theme.breakpoints.down('md')]: {
        minHeight: 45,
    },
    [theme.breakpoints.down('sm')]: {
        padding: 0,
        // minHeight: 34,
        //   maxHeight: 34,
    },
    '.MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
        // width: '70px !important',
        borderRightWidth: 5,
        display: 'none',
        // flexItem sx={{ borderRightWidth: 5 }}
    },
})

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
}))

export default useStyles
