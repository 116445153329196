//const profileId = localStorage.getItem('profileId')
const configVariables = {
    mrc: {
        baseApiUrl: process.env.REACT_APP_MRC_BASE_URL,
    },
    ehrendpoints: {
        ALLENDPOINTS: process.env.REACT_APP_EHRENDPOINTALL,
        ENDPOINTBYID: process.env.REACT_APP_EHRENDPOINTBYID,
    },
    apiGateway: {
        REGION: process.env.REACT_APP_APIGATEWAY_REGION,
        URL: process.env.REACT_APP_APIGATEWAY_URL,
    },
    meditechCredentials: {
        CLIENT: {
            ID: process.env.REACT_APP_MEDITECH_ID,
            SECRET: process.env.REACT_APP_MEDITECH_SE,
        },
        REDIRECT: {
            REDIRECT_URI: process.env.REACT_APP_MEDITECH_REDIRECT_URI,
        },
    },
    athenaCredentials: {
        CLIENT: {
            ID: process.env.REACT_APP_ATHENA_ID,
            SECRET: process.env.REACT_APP_ATHENA_SE,
        },
        REDIRECT: {
            REDIRECT_URI: process.env.REACT_APP_ATHENA_REDIRECT_URI,
        },
    },
    cernerchildrensmercy: {
        CLIENT: {
            ID: process.env.REACT_APP_CERNER_CMH_ID,
            // secret: process.env.REACT_APP_ALL_SCRIPTS_CLIENT_SECRET,
        },
        AUTH: {
            TOKEN_HOST: process.env.REACT_APP_CERNER_CMH_AUTH_TOKEN_HOST,
            TOKEN_PATH: process.env.REACT_APP_CERNER_CMH_TOKEN_PATH,
            AUTHORIZE_PATH: process.env.REACT_APP_CERNER_CMH_AUTHORIZE_PATH,
        },
        OPTIONS: {
            AUTHORIZATION_METHOD: process.env.REACT_APP_CERNER_CMH_AUTHORIZATION_METHOD,
            // bodyFormat: 'form',
        },
        REDIRECT: {
            REDIRECT_URI: process.env.REACT_APP_CERNER_CMH_REDIRECT_URI,
            SCOPE: process.env.REACT_APP_CERNER_CMH_SCOPE,
            STATE: process.env.REACT_APP_CERNER_CMH_STATE,
            AUD: process.env.REACT_APP_CERNER_CMH_AUD,
        },
        DETAILS: {
            GET_TOKEN: process.env.REACT_APP_CERNER_CMH_GET_TOKEN,
        },
        DATA: {
            CONDITIONS: process.env.REACT_APP_CLEVELAND_CLINIC_CONDITIONS,
        },
    },
    bluebuttonCredentials: {
        CLIENT: {
            ID: process.env.REACT_APP_BLUE_BUTTON_ID,
            secret: process.env.REACT_APP_BLUE_BUTTON_CLIENT_SECRET,
        },
        AUTH: {
            TOKEN_HOST: process.env.REACT_APP_BLUE_BUTTON_AUTH_TOKEN_HOST,
            TOKEN_PATH: process.env.REACT_APP_BLUE_BUTTON_TOKEN_PATH,
            AUTHORIZE_PATH: process.env.REACT_APP_BLUE_BUTTON_AUTHORIZE_PATH,
        },
        OPTIONS: {
            AUTHORIZATION_METHOD: process.env.REACT_APP_BLUE_BUTTON_AUTHORIZATION_METHOD,
            // bodyFormat: 'form',
        },
        REDIRECT: {
            REDIRECT_URI: process.env.REACT_APP_BLUE_BUTTON_REDIRECT_URI,
            // SCOPE: process.env.REACT_APP_BLUE_BUTTON_SCOPE,
            STATE: process.env.REACT_APP_BLUE_BUTTON_STATE,
            // AUD: process.env.REACT_APP_BLUE_BUTTON_AUD
        },
        DETAILS: {
            GET_TOKEN: process.env.REACT_APP_BLUE_BUTTON_GET_TOKEN,
        },
        DATA: {
            GET_CLAIMS: process.env.REACT_APP_BLUE_BUTTON_GET_CLAIMS,
        },
    },
    allscriptsCredentials: {
        CLIENT: {
            ID: process.env.REACT_APP_ALL_SCRIPTS_ID,
            // secret: process.env.REACT_APP_ALL_SCRIPTS_CLIENT_SECRET,
        },
        AUTH: {
            TOKEN_HOST: process.env.REACT_APP_ALL_SCRIPTS_AUTH_TOKEN_HOST,
            TOKEN_PATH: process.env.REACT_APP_ALL_SCRIPTS_TOKEN_PATH,
            AUTHORIZE_PATH: process.env.REACT_APP_ALL_SCRIPTS_AUTHORIZE_PATH,
        },
        OPTIONS: {
            AUTHORIZATION_METHOD: process.env.REACT_APP_ALL_SCRIPTS_AUTHORIZATION_METHOD,
            // bodyFormat: 'form',
        },
        REDIRECT: {
            REDIRECT_URI: process.env.REACT_APP_ALL_SCRIPTS_REDIRECT_URI,
            SCOPE: process.env.REACT_APP_ALL_SCRIPTS_SCOPE,
            //   STATE: process.env.REACT_APP_ALL_SCRIPTS_STATE,
            //  AUD: process.env.REACT_APP_ALL_SCRIPTS_AUD
        },
        DETAILS: {
            GET_TOKEN: process.env.REACT_APP_ALL_SCRIPTS_GET_TOKEN,
        },
        DATA: {
            CONDITIONS: process.env.REACT_APP_ALL_SCRIPTS_CONDITIONS,
        },
    },
    saintlukesCredentials: {
        CLIENT: {
            ID: process.env.REACT_APP_SAINT_LUKES_ID,
            // secret: process.env.REACT_APP_ALL_SCRIPTS_CLIENT_SECRET,
        },
        AUTH: {
            TOKEN_HOST: process.env.REACT_APP_SAINT_LUKES_AUTH_TOKEN_HOST,
            TOKEN_PATH: process.env.REACT_APP_SAINT_LUKES_TOKEN_PATH,
            AUTHORIZE_PATH: process.env.REACT_APP_SAINT_LUKES_AUTHORIZE_PATH,
        },
        OPTIONS: {
            AUTHORIZATION_METHOD: process.env.REACT_APP_SAINT_LUKES_AUTHORIZATION_METHOD,
            // bodyFormat: 'form',
        },
        REDIRECT: {
            REDIRECT_URI: process.env.REACT_APP_SAINT_LUKES_REDIRECT_URI,
            SCOPE: process.env.REACT_APP_SAINT_LUKES_SCOPE,
            STATE: process.env.REACT_APP_SAINT_LUKES_STATE,
            //  AUD: process.env.REACT_APP_ALL_SCRIPTS_AUD
        },
        DETAILS: {
            GET_TOKEN: process.env.REACT_APP_SAINT_LUKES_GET_TOKEN,
        },
        DATA: {
            CONDITIONS: process.env.REACT_APP_SAINT_LUKES_CONDITIONS,
        },
    },
    kumedCredentials: {
        CLIENT: {
            ID: process.env.REACT_APP_KUMED_ID,
            // secret: process.env.REACT_APP_ALL_SCRIPTS_CLIENT_SECRET,
        },
        AUTH: {
            TOKEN_HOST: process.env.REACT_APP_KUMED_AUTH_TOKEN_HOST,
            TOKEN_PATH: process.env.REACT_APP_KUMED_TOKEN_PATH,
            AUTHORIZE_PATH: process.env.REACT_APP_KUMED_AUTHORIZE_PATH,
        },
        OPTIONS: {
            AUTHORIZATION_METHOD: process.env.REACT_APP_KUMED_AUTHORIZATION_METHOD,
            // bodyFormat: 'form',
        },
        REDIRECT: {
            REDIRECT_URI: process.env.REACT_APP_KUMED_REDIRECT_URI,
            SCOPE: process.env.REACT_APP_KUMED_SCOPE,
            STATE: process.env.REACT_APP_KUMED_STATE,
            //  AUD: process.env.REACT_APP_ALL_SCRIPTS_AUD
        },
        DETAILS: {
            GET_TOKEN: process.env.REACT_APP_KUMED_GET_TOKEN,
        },
        DATA: {
            CONDITIONS: process.env.REACT_APP_KUMED_CONDITIONS,
        },
    },
    clevelandclinicCredentials: {
        CLIENT: {
            ID: process.env.REACT_APP_CLEVELAND_CLINIC_ID,
            // secret: process.env.REACT_APP_ALL_SCRIPTS_CLIENT_SECRET,
        },
        AUTH: {
            TOKEN_HOST: process.env.REACT_APP_CLEVELAND_CLINIC_AUTH_TOKEN_HOST,
            TOKEN_PATH: process.env.REACT_APP_CLEVELAND_CLINIC_TOKEN_PATH,
            AUTHORIZE_PATH: process.env.REACT_APP_CLEVELAND_CLINIC_AUTHORIZE_PATH,
        },
        OPTIONS: {
            AUTHORIZATION_METHOD: process.env.REACT_APP_CLEVELAND_CLINIC_AUTHORIZATION_METHOD,
            // bodyFormat: 'form',
        },
        REDIRECT: {
            REDIRECT_URI: process.env.REACT_APP_CLEVELAND_CLINIC_REDIRECT_URI,
            SCOPE: process.env.REACT_APP_CLEVELAND_CLINIC_SCOPE,
            STATE: process.env.REACT_APP_CLEVELAND_CLINIC_STATE,
            //  AUD: process.env.REACT_APP_ALL_SCRIPTS_AUD
        },
        DETAILS: {
            GET_TOKEN: process.env.REACT_APP_CLEVELAND_CLINIC_GET_TOKEN,
        },
        DATA: {
            CONDITIONS: process.env.REACT_APP_CLEVELAND_CLINIC_CONDITIONS,
        },
    },
    mayoclinicCredentials: {
        CLIENT: {
            ID: process.env.REACT_APP_MAYOCLINIC_ID,
        },
        AUTH: {
            TOKEN_HOST: process.env.REACT_APP_MAYOCLINIC_AUTH_TOKEN_HOST,
            TOKEN_PATH: process.env.REACT_APP_MAYOCLINIC_TOKEN_PATH,
            AUTHORIZE_PATH: process.env.REACT_APP_MAYOCLINIC_AUTHORIZE_PATH,
        },
        OPTIONS: {
            AUTHORIZATION_METHOD: process.env.REACT_APP_MAYOCLINIC_AUTHORIZATION_METHOD,
            // bodyFormat: 'form',
        },
        REDIRECT: {
            REDIRECT_URI: process.env.REACT_APP_MAYOCLINIC_REDIRECT_URI,
            SCOPE: process.env.REACT_APP_MAYOCLINIC_SCOPE,
            STATE: process.env.REACT_APP_MAYOCLINIC_STATE,
        },
        DETAILS: {
            GET_TOKEN: process.env.REACT_APP_MAYOCLINIC_GET_TOKEN,
        },
        DATA: {
            CONDITIONS: process.env.REACT_APP_MAYOCLINIC_CONDITIONS,
        },
    },
    chmNebraskaCredentials: {
        CLIENT: {
            ID: process.env.REACT_APP_CHM_NEBRASKA_ID,
        },
        AUTH: {
            TOKEN_HOST: process.env.REACT_APP_CHM_NEBRASKA_AUTH_TOKEN_HOST,
            TOKEN_PATH: process.env.REACT_APP_CHM_NEBRASKA_TOKEN_PATH,
            AUTHORIZE_PATH: process.env.REACT_APP_CHM_NEBRASKA_AUTHORIZE_PATH,
        },
        OPTIONS: {
            AUTHORIZATION_METHOD: process.env.REACT_APP_CHM_NEBRASKA_AUTHORIZATION_METHOD,
            // bodyFormat: 'form',
        },
        REDIRECT: {
            REDIRECT_URI: process.env.REACT_APP_CHM_NEBRASKA_REDIRECT_URI,
            SCOPE: process.env.REACT_APP_CHM_NEBRASKA_SCOPE,
            STATE: process.env.REACT_APP_CHM_NEBRASKA_STATE,
        },
        DETAILS: {
            GET_TOKEN: process.env.REACT_APP_CHM_NEBRASKA_GET_TOKEN,
        },
        DATA: {
            CONDITIONS: process.env.REACT_APP_CHM_NEBRASKA_CONDITIONS,
        },
    },
    featureToggle: {
        bookTelehealth: process.env.REACT_APP_BOOK_TELEHEALTH === 'true',
    },
    veteranCredentials: {
        clientId: process.env.REACT_APP_VETERAN_CLINET_ID,
        stateCode: process.env.REACT_APP_VETERAN_STATE_CODE,
        codeChallengeMethod: process.env.REACT_APP_VETERAN_CODE_CHALLENGE_METH,
        codeChallenge: process.env.REACT_APP_VETERAN_CODE_CHALLENGE,
        redirectUrl: process.env.REACT_APP_VETERAN_REDIRECT_URL,
        baseSandboxUrl: process.env.REACT_APP_VETERAN_BASE_SANDBOX_URL,
        veteranApiKey: process.env.REACT_APP_VETERAN_VETERAN_API_KEY,
        veteranVerificationPrefix: process.env.REACT_APP_VETERAN_VETERAN_VERIFICATION_PREFIX,
        veteranConfirmationPrefix: process.env.REACT_APP_VETERAN_VETERAL_CONFIRMATION_PREFIX,
        codeVerifier: process.env.REACT_APP_VETERAN_CODE_VERIFIER,
    },
    sentry: {
        dns: process.env.REACT_APP_SENTRY_DNS,
    },
    ccda: {
        helperUrl: process.env.REACT_APP_CCDA_HELPER_URL,
    },
    medaviewer: {
        url: process.env.REACT_APP_MEDAVIEWER_URL,
    },
    opentok: {
        apiKey: process.env.REACT_APP_OPENTOK_API_KEY,
        sessionId: process.env.REACT_APP_OPENTOK_SESSION_ID,
        token: process.env.REACT_APP_OPENTOK_TOKEN,
    },
    socialMedia: {
        facebook: process.env.REACT_APP_FACEBOOK_ACC,
        instagram: process.env.REACT_APP_INSTAGRAM_ACC,
        twitter: process.env.REACT_APP_TWITTER_ACC,
    },
}

export default configVariables
