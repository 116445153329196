export const ImportFolderIcon = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="2234 447 10 10">
        <g data-name="CompositeLayer">
            <path
                d="M2243.508 449.996c.2.283-.038.629-.384.629h-8.624a.5.5 0 0 1-.5-.5v-.915c0-1.22.99-2.21 2.21-2.21h1.16c.815 0 1.07.265 1.395.7l.7.93c.155.205.175.23.465.23h1.395c.902 0 1.7.448 2.183 1.136Z"
                fill={color}
                fillRule="evenodd"
                data-name="Path 126738"
            />
            <path
                d="M2243.492 451.375a.5.5 0 0 1 .5.498l.008 2.452c0 1.475-1.2 2.675-2.675 2.675h-4.65a2.678 2.678 0 0 1-2.675-2.675v-2.45a.5.5 0 0 1 .5-.5h8.992Z"
                fill={color}
                fillRule="evenodd"
                data-name="Path 126739"
            />
        </g>
    </svg>
)
