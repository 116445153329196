import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useState } from 'react'
import { ImportButton } from 'components/BlueImportButton'
import { Grid, MenuItem, TextField, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { genderData, languageData, relationData } from 'use/Const'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const AddDependentModal = ({ title, isOpen, onClose, onAdd, selectedDependent }) => {
    const [error, setError] = useState(null)
    const defaultFields = {
        firstName: selectedDependent?.firstName || '',
        lastName: selectedDependent?.lastName || '',
        gender: selectedDependent?.gender || 'male',
        // preferredLanguage: 'English',
        relation: selectedDependent?.relationship,
        birthDate: selectedDependent?.birthDate || new Date(),
    }
    const [fieldValue, setFieldValue] = useState(defaultFields)

    const fields = [
        {
            type: 'text',
            stateKey: 'firstName',
            label: 'First name',
            value: fieldValue.firstName,
            helperText: !fieldValue.firstName?.length > 3 ? 'Please enter a valid last name' : '',
            onChange: (e) => setFieldValue({ ...fieldValue, firstName: e.target.value }),
            isRequired: true,
        },
        {
            type: 'text',
            stateKey: 'lastName',
            label: 'Last name',
            value: fieldValue.lastName,
            helperText: !fieldValue.lastName?.length > 3 ? 'Please enter a valid last name' : '',
            onChange: (e) => setFieldValue({ ...fieldValue, lastName: e.target.value }),
            isRequired: true,
        },
        {
            stateKey: 'birthDate',
            label: 'DOB',
            value: fieldValue.birthDate,
            helperText: !fieldValue.birthDate ? 'Please enter a valid birth date' : '',
            onChange: (e) => setFieldValue({ ...fieldValue, birthDate: e }),
            inputProps: { max: new Date().toISOString().split('T')[0] },
            isDate: true,
            isRequired: false,
        },
        {
            stateKey: 'gender',
            label: 'Gender',
            value: fieldValue.gender,
            helperText: !fieldValue.gender ? 'Please enter a valid gender' : '',
            onChange: (e) => setFieldValue({ ...fieldValue, gender: e.target.value }),
            childrenItems: genderData.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            )),
        },
        // {
        //     stateKey: 'preferredLanguage',
        //     label: 'Preferred Language',
        //     value: fieldValue.preferredLanguage,
        //     isRequired: true,
        //     onChange: (e) => setFieldValue({ ...fieldValue, preferredLanguage: e.target.value }),
        //     helperText: !fieldValue.preferredLanguage ? 'Please enter a valid Language' : '',
        //     childrenItems: languageData.map((option) => (
        //         <MenuItem key={option} value={option}>
        //             {option}
        //         </MenuItem>
        //     )),
        // },
        {
            stateKey: 'relation',
            label: 'Relation',
            isRequired: true,
            value: fieldValue.relation,
            onChange: (e) => setFieldValue({ ...fieldValue, relation: e.target.value }),
            childrenItems: relationData
                .filter((x) => x.value !== 'self')
                .map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                )),
        },
    ]
    const handleAdd = () => {
        setError(null)
        if (!fieldValue.firstName) {
            setError(`First Name is Required `)
            return
        }
        if (!fieldValue.lastName) {
            setError(`Last Name is Required `)
            return
        }
        if (!fieldValue.birthDate) {
            setError(`BirthDate is Required `)
            return
        }
        setError(null)
        onAdd(fieldValue)
        onClose()
    }
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Grid item xs={12} md={12} lg={12} sx={{ m: 2 }}>
                    <div className="py-3">
                        {fields.map(({ secondaryText, isDate, ...textFields }) =>
                            isDate ? (
                                <div className="py-3">
                                    <DatePicker
                                        stateKey="birthDate"
                                        label="DOB"
                                        value={fieldValue.birthDate}
                                        onChange={(e) => setFieldValue({ ...fieldValue, birthDate: e })}
                                        inputFormat="MM/dd/yyyy"
                                        maxDate={new Date()}
                                        renderInput={(params) => <TextField {...params} />}
                                        inputProps={{ max: new Date().toISOString().split('T')[0] }}
                                    />
                                </div>
                            ) : (
                                <TextField
                                    error={textFields.error}
                                    select={!!textFields.childrenItems}
                                    required
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    {...textFields}
                                >
                                    {textFields.childrenItems}
                                </TextField>
                            )
                        )}
                    </div>
                    {/* <div className="py-3">
                        <DatePicker
                            stateKey="birthDate"
                            label="DOB"
                            value={fieldValue.birthDate}
                            onChange={(e) => setFieldValue({ ...fieldValue, birthDate: e })}
                            required
                            inputFormat="MM/dd/yyyy"
                            renderInput={(params) => <TextField required {...params} />}
                            inputProps={{ max: new Date().toISOString().split('T')[0] }}
                        />
                    </div> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                {error ? <Typography style={{ color: 'red' }}>{error}</Typography> : <></>}
                <Button
                    style={{ color: '#176571' }}
                    onClick={() => {
                        onClose()
                    }}
                >
                    Cancel
                </Button>
                <ImportButton startIcon={<Add />} autoFocus variant="contained" color="primary" onClick={handleAdd}>
                    {selectedDependent ? 'Update' : 'Add'}
                </ImportButton>
            </DialogActions>
        </Dialog>
    )
}

export default AddDependentModal
