import React, { useState } from 'react'
import { Button, Grid, Typography, TextField } from '@mui/material'
import { ContactUsService } from '../../services/contact-us.service'
import ReactLoading from 'react-loading'

const ContactUsForm = () => {
    const email = localStorage.getItem('email')
    const name = localStorage.getItem('fullName')
    const [loading, setLoading] = useState(false)
    const [messageSent, setMessageSent] = useState(false)

    const [state, setState] = useState({
        email: email,
        name: name,
        message: '',
    })

    const handleSubmit = async () => {
        setLoading(true)
        console.log('state', state)
        try {
            await ContactUsService.support(state)
            setState({ ...state, message: '' })
            setMessageSent(true)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    return (
        <div>
            <Typography variant="h6" align="center" gutterBottom>
                {'Contact Form'.toUpperCase()}
            </Typography>
            <Grid container direction="column" justify="center" alignItems="normal">
                <Grid item>
                    <Grid item display="flex" direction="row" gap={1}>
                        <TextField
                            fullWidth
                            required
                            id="name"
                            label="Name"
                            name="userName"
                            defaultValue={state.name}
                            onChange={(e) => {
                                setMessageSent(false)
                                setState({ ...state, name: e.target.value })
                            }}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            required
                            id="email"
                            label="Email"
                            name="email"
                            disabled
                            defaultValue={state.email}
                            onChange={(e) => {
                                setMessageSent(false)
                                setState({ ...state, email: e.target.value })
                            }}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth
                            multiline
                            minRows={4}
                            id="note"
                            type="message"
                            label="Message"
                            inputProps={{ readOnly: false }}
                            onChange={(e) => {
                                setMessageSent(false)
                                setState({ ...state, message: e.target.value })
                            }}
                        />
                    </Grid>
                    {messageSent && (
                        <Typography style={{ color: 'green', marginTop: '8px' }}>
                            Thanks for submitting your message. We will reach out to you soon.
                        </Typography>
                    )}
                    <Grid container direction="row" spacing={2} sx={{ mt: '1px' }}>
                        <Grid item>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={!state.message || !state.name || !state.email}
                            >
                                {loading && <ReactLoading width={20} height={20} type="spokes" color="rgb(64 60 60)" />}
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default ContactUsForm
