import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import { pageTitles } from 'use/pageTitles'

import './Loader.scss'

const Loader = ({ pageName }) => {
    return (
        <div className="container-right">
            <div className="white-container">
                <div className="loader">
                    <CircularProgress />
                </div>
            </div>
        </div>
    )
}

export default Loader
