import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { useStyles } from './MobileTableCard.styles'
import CardField from '../CardField'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'

const getComponentDisplayName = (component) => component?.type?.name || component?.type?.type?.render?.displayName
const isRightButton = (value) => {
    const componentName = getComponentDisplayName(value)

    return ['ErrorOutlineOutlinedIcon', 'CancelIcon'].includes(componentName)
}

const isButton = (value) => {
    const componentName = getComponentDisplayName(value)

    return ['CcdaHelper', 'Button'].includes(componentName)
}

const MobileTableCard = (props) => {
    const { row, columns } = props

    const classes = useStyles()

    const { buttonList, tableItems, rightButton } = React.useMemo(() => {
        const buttonList = []
        const tableItems = []
        let rightButton = null

        columns.forEach(({ field, renderCell, headerName }) => {
            let value = row[field]

            if (renderCell) {
                value = renderCell({ row, value, id: field })
            }

            if (rightButton === null && isRightButton(value)) {
                rightButton = value
            } else if (isButton(value)) {
                buttonList.push({
                    ...value,
                    props: {
                        ...value.props,
                        buttonProps: {
                            className: classes.customButtonStyles,
                            startIcon: <VisibilityOutlinedIcon />,
                            size: 'small',
                            fullWidth: true,
                        },
                    },
                })
            } else {
                tableItems.push({
                    label: headerName,
                    value,
                })
            }
        })

        return {
            buttonList,
            tableItems,
            rightButton,
        }
    }, [classes.customButtonStyles, columns, row])

    const buttonSize = React.useMemo(() => {
        if (buttonList.length >= 3) {
            return 4
        }

        if (buttonList.length === 2) {
            return 6
        }

        return 12
    }, [buttonList.length])

    return (
        <Card className={classes.wrapper} variant="outlined">
            <CardContent>
                <Grid container spacing={1}>
                    {rightButton && (
                        <Grid item xs={12}>
                            <div className={classes.head}>
                                {/* do not delete this empty div */}
                                <div />
                                {rightButton}
                            </div>
                        </Grid>
                    )}

                    {tableItems.map(({ label, value }) => (
                        <Grid item xs={6}>
                            <CardField label={label} value={value || <span>--</span>} />
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
            <CardActions>
                <Grid container spacing={1}>
                    {buttonList.map((button) => (
                        <Grid item xs={buttonSize}>
                            {button}
                        </Grid>
                    ))}
                </Grid>
            </CardActions>
        </Card>
    )
}

export default MobileTableCard
