import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
    logo: {
        padding: '2rem 0',
        width: '75%',
        margin: '0 auto',
    },
    drawer: {
        '& .MuiPaper-root ': {
            backgroundColor: theme.palette.primary.main,
            maxWidth: '300px',  
        }
    },
}))
