import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/lab/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import CompassCalibrationOutlinedIcon from '@mui/icons-material/CompassCalibrationOutlined'
import ImportButton from 'components/BlueImportButton'
import './AddYourDoctor.scss'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { GetProvidersAPI } from 'services/Services'
import popupNotification from 'components/Notification/popupNotification'
import { FieldBox } from './AddYourDoctor.styles'
import { OrganizationService } from 'services/organization.service'
import { useHistory } from 'react-router-dom'

const AddOrganization = () => {
    const history = useHistory()
    const [organizationState, setOrganizationState] = useState({
        org: '',
        providers: '',
        loading: true,
        provider: {},
        errors: {},
    })

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const data = await GetProvidersAPI()
        if (data) {
            if (data.message === 'No Data Found') {
                setOrganizationState({
                    ...organizationState,
                    providers: [],
                })
            } else {
                setOrganizationState({
                    ...organizationState,
                    providers: data,
                })
            }
        }
        setOrganizationState({
            ...organizationState,
            loading: false,
        })
    }

    const handleAddOrganization = async () => {
        if (Object.keys(organizationState.provider).length === 0) {
            setOrganizationState({
                ...organizationState,
                errors: {
                    ...errors,
                    connectOrganization: 'Please select a organization',
                },
            })
            return
        }
        setOrganizationState({
            ...organizationState,
            loading: true,
        })

        const payload = {
            status: '1',
            providerId: organizationState?.provider?.providerId,
            profileId: localStorage.getItem('profileid'),
        }

        try {
            const { data: response } = await OrganizationService.addOrganization(payload)
            if (response) {
                if (response.message === 'Organization already added for this profileId') {
                    popupNotification('Organization already added for this profileId', false)
                }
                if (response.message === 'Organization added successfully') {
                    popupNotification('Organization added successfully', true)
                }
                if (
                    response.message !== 'Organization added successfully' &&
                    response.message !== 'Organization already added for this profileId'
                ) {
                    popupNotification('Add failed', false)
                }
                setOrganizationState({
                    ...organizationState,
                    loading: false,
                })
                history.push(`/home/share-records`)
            }
        } catch (error) {
            setOrganizationState({
                ...organizationState,
                loading: false,
            })

            popupNotification('Something went wrong', false)
        }
    }

    // Function to change org state to user selected organization (doesn't actually achieve anything right now)
    const organizationChange = (organization) => {
        setOrganizationState({
            ...organizationState,
            org: organization.value,
        })
    }
    const { errors } = organizationState

    return (
        <>
            {organizationState.loading ? (
                <div className="container-right">
                    <div className="main-title with-icon">
                        <Button startIcon={<ChevronLeftIcon />} onClick={() => history.push('/home/share-records')}>
                            Return
                        </Button>
                    </div>
                    <div className="white-container">
                        <div className="loader">
                            <CircularProgress />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container-right">
                    {/* Page title with clickable icon to return to share my records page */}
                    <div className="main-title with-icon">
                        <Button startIcon={<ChevronLeftIcon />} onClick={() => history.push('/home/share-records')}>
                            Return
                        </Button>
                    </div>

                    <Grid container style={{ marginTop: 30 }}>
                        <Grid item xs={12} md={8} lg={6}>
                            <Box style={{ marginBottom: 20 }}>
                                <FieldBox>
                                    <Autocomplete
                                        style={{ flex: 1 }}
                                        options={organizationState.providers}
                                        getOptionLabel={(option) => option.providerName + ' ' + option.providerLastName}
                                        getOptionSelected={(option) => option.providerId}
                                        onChange={(_, value) => organizationChange(value)}
                                        error={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Search Organization"
                                                variant="outlined"
                                                error={Boolean(errors.connectOrganization)}
                                                helperText={errors.connectOrganization}
                                            />
                                        )}
                                    />
                                    <ImportButton
                                        startIcon={<CompassCalibrationOutlinedIcon />}
                                        onClick={handleAddOrganization}
                                    >
                                        Connect
                                    </ImportButton>
                                </FieldBox>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    )
}

export default AddOrganization
