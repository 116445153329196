import oauth2 from 'simple-oauth2'

import config from 'use/Config'

const bluebuttonCredentials = {
    client: {
        id: config.bluebuttonCredentials.CLIENT.ID,
        secret: config.bluebuttonCredentials.CLIENT.secret,
    },
    auth: {
        tokenHost: config.bluebuttonCredentials.AUTH.TOKEN_HOST,
        tokenPath: config.bluebuttonCredentials.AUTH.TOKEN_PATH,
        authorizePath: config.bluebuttonCredentials.AUTH.AUTHORIZE_PATH,
    },
    options: {
        authorizationMethod: config.bluebuttonCredentials.OPTIONS.AUTHORIZATION_METHOD,
        // bodyFormat: 'form',
    },
}

const oauthTodoistbluebutton = oauth2.create(bluebuttonCredentials)

export const bluebuttonAuthorizationUri = oauthTodoistbluebutton.authorizationCode.authorizeURL({
    redirect_uri: config.bluebuttonCredentials.REDIRECT.REDIRECT_URI,
    // scope: config.bluebuttonCredentials.REDIRECT.SCOPE,
    state: config.bluebuttonCredentials.REDIRECT.STATE,
    //aud: config.bluebuttonCredentials.REDIRECT.AUD
})

export const organizations = [
    {
        id: 0,
        value: 'Medicare',
    },
]
