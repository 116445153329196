import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import Drawer from '@mui/material/Drawer'
import MenuItem from '@mui/material/MenuItem'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import SideBar from 'components/layout/SideBar/SideBar'
import HeaderDateChip from 'components/HeaderDateChip'
import FollowUs from 'components/FollowUs'
import { useStyles } from './Header.styles'
import './Header.scss'

import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Stack from '@mui/material/Stack'
import logo from 'assets/logos/MatchRiteCare_logo_3Color_White.png'
import { showSearchFieldInPages } from 'use/pageTitles'
import SearchField from 'components/SearchField'

const Wrapper = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    marginBottom: 20,

    [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
    },
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    '&.MuiToolbar-root': {
        paddingLeft: 0,
        paddingRight: 0,
    },
}))

function Header(props) {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
    const theme = useTheme()
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles()

    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const logOut = async () => {
        try {
            // await Auth.signOut()
            localStorage.clear()
            window.location.reload()
        } catch (e) {
            console.error(e)
        }
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setIsDrawerOpen(open)
    }

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* <MenuItem onClick={handleMenuClose}>Settings</MenuItem> */}
            <MenuItem onClick={logOut}>Logout</MenuItem>
        </Menu>
    )

    const mobileMenuId = 'primary-search-account-menu-mobile'
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/* <MenuItem>
                <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem> */}
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    )

    return (
        <Wrapper>
            <AppBar position="static" style={{ background: 'white', boxShadow: 'none' }}>
                <StyledToolbar>
                    {!isMediumScreen && (
                        <React.Fragment>
                            <IconButton
                                size="large"
                                edge="start"
                                aria-label="open drawer"
                                sx={{
                                    mr: 2,
                                    backgroundColor: '#176571',
                                    // backgroundColor: '#2a7cce',
                                    color: 'white',
                                    borderRadius: '30%',
                                    '&:hover': {
                                        backgroundColor: '#53a9f3',
                                    },
                                }}
                                onClick={toggleDrawer(true)}
                            >
                                <MenuIcon />
                            </IconButton>

                            <Drawer
                                anchor="left"
                                open={isDrawerOpen}
                                onClose={toggleDrawer(false)}
                                className={classes.drawer}
                            >
                                <img alt="Matchrite logo" className={classes.logo} src={logo} />

                                <Grid
                                    container
                                    flex={1}
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <SideBar
                                        onItemClick={(item) => {
                                            if (item !== 'myrecords') {
                                                setIsDrawerOpen(false)
                                            }
                                        }}
                                    />
                                    <FollowUs />
                                    {/* <Footer /> */}
                                </Grid>
                            </Drawer>
                        </React.Fragment>
                    )}

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {showSearchFieldInPages(props.location.pathname) && (
                            <SearchField pathname={props.location.pathname} />
                        )}
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'flex' } }}>
                        <Stack direction="row" alignItems="center">
                            <HeaderDateChip />
                        </Stack>
                        {/* <IconButton size="large" aria-label="show 17 new notifications" sx={{ padding: { xs: '8px' } }}>
                            <Badge color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton> */}
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            sx={{ padding: { xs: '8px' } }}
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                    {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box> */}
                </StyledToolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, mt: 2 }}>
                {showSearchFieldInPages(props.location.pathname) && <SearchField pathname={props.location.pathname} />}
            </Box>
        </Wrapper>
    )
}

export default withRouter(Header)
