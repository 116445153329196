import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginBottom: 10,
        background: 'linear-gradient(180deg, white, #dddddd) !important',
    },
    head: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10,

        '& .photo': {
            maxWidth: 40,
            maxHeight: 40,
            margin: '0 !important',
        }
    }
}))
