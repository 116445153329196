import React from 'react'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import MrcButton from 'components/MrcButton'
import ShareRecordsModal from 'components/ShareRecordModal/ShareRecordsModal'

const Share = ({ data, handleShareSave }) => {
    const { sharedProfile, sharedTables } = data
    const [showDialog, setShowDialog] = React.useState(false)
    // const handleShareSave = async (items) => {
    //     const payload = {
    //         profileId: localStorage.getItem('profileid'),
    //         // mongoDBTables: [
    //         //     'Epatients',
    //         //     'EAllergyIntolerance',
    //         //     'EDiagnosticReport',
    //         //     'ECondition',
    //         //     'EMedicationStatement',
    //         //     'EEncounter',
    //         //     'EObservation',
    //         //     'EClaims',
    //         // ],
    //         mongoDBTables: items,
    //         providers: ['' + providerId + ''],
    //     }
    //     try {
    //         const { data: response } = await DoctorService.shareProfile(payload)
    //         if (response) {
    //             popupNotification('Record Successfully Shared', true)
    //         }
    //     } catch (error) {
    //         this.setState({ loading: false })
    //         popupNotification('Something went wrong', false)
    //     }
    // }
    return (
        <div>
            <MrcButton
                startIcon={<IosShareOutlinedIcon />}
                aria-haspopup="true"
                onClick={() => setShowDialog(true)}
                aria-controls="simple-menu"
            >
                Share
            </MrcButton>
            <ShareRecordsModal
                isOpen={showDialog}
                onClose={() => setShowDialog(false)}
                onShare={(checkedMongoTables, selectedProfileIds) =>
                    handleShareSave(checkedMongoTables, selectedProfileIds)
                }
                sharedTables={sharedTables || []}
                sharedProfiles={sharedProfile || []}
            />
        </div>
    )
}

export default Share
