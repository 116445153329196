import axiosInstance from 'util/axios'
export const OrganizationService = {
    getAllOrganizations: async () => {
        try {
            const response = await axiosInstance.get(`/Organization/get-all-organizations`)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    getSharedOrganizations: async () => {
        try {
            const response = await axiosInstance.get(`/Organization/get-shared-organizations`)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    addOrganization: async (body) => {
        try {
            const response = await axiosInstance.post(`/Organization/add-organization`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    shareOrganization: async (body) => {
        try {
            const response = await axiosInstance.post(`/Organization/share-profile-to-organization`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
    removeOrganization: async (body) => {
        try {
            const response = await axiosInstance.delete(
                `/Organization/remove-organization?profileId=${body.profileId}&organizationId=${body.organizationId}`
            )
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
}
