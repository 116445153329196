import { styled } from '@mui/material/styles'
import MrcButton from 'components/MrcButton'

export const ImportButton = styled(MrcButton)(({ theme }) => ({
    minHeight: '56px',
    width: 110,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: '#176571',
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    ':hover': {
        backgroundColor: '#176571',
    },
}))

const BlueImportButton = ({ children, ...props }) => {
    return <ImportButton {...props}>{children}</ImportButton>
}

export default BlueImportButton
