import React from 'react'
import Menu from '@mui/material/Menu'
import WorkIcon from '@mui/icons-material/Work'
import MenuItem from '@mui/material/MenuItem'
import 'react-vertical-timeline-component/style.min.css'
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import femaleIcon from 'assets/profiles/female.png'
import maleIcon from 'assets/profiles/male.png'
import Colors from 'use/Color'
import { getDate } from 'use/Helper'
import { pageTitles } from 'use/pageTitles'
import Loader from 'components/Loader/Loader'
import { EncounterByProfileIdAPI } from 'services/Services'

import './Encounters.scss'

class Encounters extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visits: [{}],
            anchorEl: null,
            person: null,
            loading: true,
        }
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    componentDidMount() {
        this.encounterByProfileId()
    }

    encounterByProfileId = async () => {
        let profileid = localStorage.getItem('profileid')
        const data = await EncounterByProfileIdAPI(profileid)

        if (data) {
            if (data.message === 'No Data Found') {
                await this.setState({ person: [], loading: false })
            } else {
                await this.setState({ person: data, loading: false })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="container-right">
                    <div className="main-title">{pageTitles['encounters']}</div>
                    <div>
                        <Loader />
                    </div>
                </div>
            )
        }

        return (
            <div className="container-right">
                <div className="main-title">{pageTitles['encounters']}</div>
                <div className="encounters">
                    {this.state.visits &&
                    this.state.visits.length > 0 &&
                    this.state.person &&
                    this.state.person.length > 0 ? (
                        <VerticalTimeline>
                            {this.state.visits.map((item, index) => (
                                <div key={index}>
                                    {this.state.person.encounter.map((pety, i) => (
                                        <VerticalTimelineElement
                                            key={i}
                                            className="vertical-timeline-element--work"
                                            contentArrowStyle={{
                                                borderRight: '7px solid  #FFFFFF',
                                            }}
                                            iconStyle={{
                                                background: Colors.secondary,
                                                color: '#fff',
                                            }}
                                            icon={<WorkIcon />}
                                        >
                                            <div
                                                className="white-card"
                                                onClick={(e) => {
                                                    if (e.target.id !== 'share-icon')
                                                        this.props.history.push('/home/encounterdetail')
                                                }}
                                            >
                                                {item.gender === 'female' ? (
                                                    <img
                                                        src={femaleIcon}
                                                        alt="provider"
                                                        style={{
                                                            width: 80,
                                                            height: 80,
                                                            borderRadius: 40,
                                                            resizeMode: 'cover',
                                                        }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={maleIcon}
                                                        alt="provider"
                                                        style={{
                                                            width: 80,
                                                            height: 80,
                                                            borderRadius: 40,
                                                            resizeMode: 'cover',
                                                        }}
                                                    />
                                                )}
                                                <div
                                                    style={{
                                                        marginLeft: 20,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: 5,
                                                            fontWeight: 500,
                                                            fontSize: 20,
                                                        }}
                                                    >
                                                        {pety.doctorName}
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginTop: 5,
                                                            fontSize: 12,
                                                            color: 'grey',
                                                        }}
                                                    >
                                                        {pety.reasonForVisit}
                                                    </div>
                                                    {pety.period !== null ? (
                                                        <div
                                                            style={{
                                                                marginTop: 5,
                                                                fontSize: 14,
                                                                color: 'black',
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            {getDate(pety.date)}
                                                        </div>
                                                    ) : null}

                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            right: 50,
                                                            bottom: 5,
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/icons/shareIcon.png"
                                                            alt="provider"
                                                            id="share-icon"
                                                            onClick={this.handleClick}
                                                            style={{
                                                                width: 30,
                                                                height: 30,
                                                                resizeMode: 'cover',
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            right: 10,
                                                            bottom: 1,
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <MailOutlineOutlinedIcon
                                                            style={{
                                                                fontSize: 30,
                                                                color: Colors.secondary,
                                                            }}
                                                            className="icon-on-card"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </VerticalTimelineElement>
                                    ))}
                                </div>
                            ))}
                        </VerticalTimeline>
                    ) : null}
                    <Menu
                        id="share-icon-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                    >
                        <MenuItem
                            onClick={() => {
                                this.handleClose()
                                this.props.history.push('/home/share-records')
                            }}
                        >
                            My Doctors
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                this.handleClose()
                                this.props.history.push('/home/findprovider')
                            }}
                        >
                            Find a Doctor
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        )
    }
}

export default Encounters
