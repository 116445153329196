import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useEffect, useState } from 'react'
import { ImportButton } from 'components/BlueImportButton'
import { FieldBox } from './AddYourDoctor.styles'
import { Autocomplete, CircularProgress, Grid, TextField } from '@mui/material'
import CompassCalibrationOutlinedIcon from '@mui/icons-material/CompassCalibrationOutlined'
import { GetProvidersAPI } from 'services/Services'
import { OrganizationService } from 'services/organization.service'

const AddDoctorOrganizationModal = ({ title, label, isOpen, onClose, onAdd }) => {
    const [selectedValue, setSelectedValue] = useState(null)
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)
    const [loader, setLoader] = useState(false)

    const handleAdd = () => {
        if (selectedValue === null || selectedValue?.length <= 0) {
            setError(`Please select a ${label}`)
            return
        }
        setError(null)
        onAdd(selectedValue)
        onClose()
    }
    const getSelectedData = async () => {
        setLoader(true)
        if (label === 'Doctor') {
            const result = await GetProvidersAPI()
            setLoader(false)
            const payload = result.map((option) => {
                return { id: option.providerId, name: `${option.providerName} ${option.providerLastName}` }
            })
            setData(payload)
        } else {
            const { data: result } = await OrganizationService.getAllOrganizations()
            setLoader(false)
            const payload = result.map((option) => {
                return { id: option.id, name: option.name }
            })
            setData(payload)
        }
    }

    useEffect(() => {
        getSelectedData()
    }, [label])
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Grid item xs={12} md={12} lg={12} sx={{ m: 2 }}>
                    {loader ? (
                        <div className="white-container">
                            <div className="loader">
                                <CircularProgress />
                            </div>
                        </div>
                    ) : (
                        <FieldBox>
                            <Autocomplete
                                style={{ flex: 1 }}
                                options={data}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option) => option.id}
                                onChange={(_, value) => {
                                    setSelectedValue(value)
                                    setError(null)
                                }}
                                error={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`Search ${label}`}
                                        variant="outlined"
                                        error={Boolean(error)}
                                        helperText={error}
                                    />
                                )}
                            />
                        </FieldBox>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose()
                        setSelectedValue(null)
                    }}
                >
                    Cancel
                </Button>
                <ImportButton
                    startIcon={<CompassCalibrationOutlinedIcon />}
                    autoFocus
                    variant="contained"
                    color="primary"
                    onClick={handleAdd}
                >
                    Add
                </ImportButton>
            </DialogActions>
        </Dialog>
    )
}

export default AddDoctorOrganizationModal
