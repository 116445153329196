import axiosInstance from 'util/axios'
export const ExceptionService = {
    getAllExceptionLogs: async (body) => {
        try {
            const response = await axiosInstance.get(`/Logs/get-logs`, body)
            return response
        } catch (error) {
            console.error('An error occurred:', error)
            throw error
        }
    },
}
