export const rowStyles = (({ theme }) => ({
  '&:nth-of-type': {
    backgroundColor: theme.palette.action.white,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const paginationStyles = (() => ({
  borderBottom: 0,

  '& .MuiTablePagination-displayedRows': {
    margin: 0,
  },
}))
