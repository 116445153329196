import infoIcon from 'assets/icons/inf-icn.png'

export const NewToolTipIcon = ({ top = 7, width = 25, height = 25 }) => {
    return (
        <img
            src={infoIcon}
            alt="info"
            style={{
                width,
                height,
                position: 'relative',
                top,
                resizeMode: 'cover',
            }}
        />
    )
}
