import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import { rowStyles, paginationStyles } from './MobileListTable.styles'

const StyledTableRow = styled(TableRow)(rowStyles)
const StyledTablePagination = styled(TablePagination)(paginationStyles)

export default function CustomizedTables(props) {
    const { items, rowsPerPage, currentPage, showPagination, totalEntries, onPageChange } = props
    return (
        <TableContainer style={{ paddingBottom: 100 }}>
            <Table aria-label="list table" style={{ overflowWrap: 'break-word' }}>
                <TableHead>
                    <TableRow>
                        <TableCell width="100%" style={{ border: 0 }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!items.length && (
                        <TableRow>
                            <Typography textAlign="center">No records found</Typography>
                        </TableRow>
                    )}
                    {items.map((row) => (
                        <StyledTableRow key={row.id}>{row.value || '--'}</StyledTableRow>
                    ))}
                </TableBody>
                {showPagination ? (
                    <TableFooter>
                        <TableRow>
                            <StyledTablePagination
                                rowsPerPageOptions={[]}
                                count={totalEntries}
                                rowsPerPage={rowsPerPage}
                                page={currentPage}
                                showFirstButton
                                showLastButton
                                onPageChange={onPageChange}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                ) : null}
            </Table>
        </TableContainer>
    )
}

CustomizedTables.propTypes = {
    onPageChange: PropTypes.func,
    rowsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    totalEntries: PropTypes.number,
    showPagination: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any.isRequired,
            value: PropTypes.array,
        })
    ),
}
