import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import Divider from '@mui/material/Divider'
import SideBar from 'components/layout/SideBar/SideBar'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded'
import maleIcon from 'assets/profiles/male.png'

class Sam extends React.Component {
    render() {
        return (
            <div className="_container">
                <SideBar title="treatmentplan" />
                <div className="container-right">
                    <div
                        className="main-title"
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ArrowBackIcon
                            style={{ cursor: 'pointer', marginRight: 10 }}
                            onClick={this.props.history.goBack}
                        />
                        <div>Sam Anderson Appiontments</div>
                    </div>
                    <div className="white-container">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img
                                                src={maleIcon}
                                                className="profile-pic"
                                                alt="profile-pic"
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h1 className="patient-name">Dr Ashley Koiosky</h1>
                                                    <h5 className="physician">Physician</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <h1 className="patient-name">09:00 AM</h1>
                                                    <h5 className="physician">24 July,2019</h5>
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 className="problem">Problem of Visit</h5>
                                                    <h4 className="physician">Essential Hypertention</h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icons-align">
                                                        <PhoneForwardedIcon className="light-blue" />
                                                        <MailOutlineIcon className="mail" />
                                                        <LocationOnIcon className="light-blue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img
                                                src={maleIcon}
                                                className="profile-pic"
                                                alt="profile-pic"
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h1 className="patient-name">Dr Sherley Abraham</h1>
                                                    <h5 className="physician">Physician</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <h1 className="patient-name">09:00 AM</h1>
                                                    <h5 className="physician">24 July,2019</h5>
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 className="problem">Problem of Visit</h5>
                                                    <h4 className="physician">Severe Hypertention</h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icons-align">
                                                        <PhoneForwardedIcon className="light-blue" />
                                                        <MailOutlineIcon className="mail" />
                                                        <LocationOnIcon className="light-blue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img
                                                src={maleIcon}
                                                className="profile-pic"
                                                alt="profile-pic"
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h1 className="patient-name">Turgut Alagoz</h1>
                                                    <h5 className="physician">Physician</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <h1 className="patient-name">09:00 AM</h1>
                                                    <h5 className="physician">24 July,2019</h5>
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 className="problem">Problem of Visit</h5>
                                                    <h4 className="physician">Essential Hypertention</h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icons-align">
                                                        <PhoneForwardedIcon className="light-blue" />
                                                        <MailOutlineIcon className="mail" />
                                                        <LocationOnIcon className="light-blue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img
                                                src={maleIcon}
                                                className="profile-pic"
                                                alt="profile-pic"
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h1 className="patient-name">Dr Ashley Koiosky</h1>
                                                    <h5 className="physician">Physician</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <h1 className="patient-name">09:00 AM</h1>
                                                    <h5 className="physician">24 July,2019</h5>
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 className="problem">Problem of Visit</h5>
                                                    <h4 className="physician">Essential Hypertention</h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icons-align">
                                                        <PhoneForwardedIcon className="light-blue" />
                                                        <MailOutlineIcon className="mail" />
                                                        <LocationOnIcon className="light-blue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img
                                                src={maleIcon}
                                                className="profile-pic"
                                                alt="profile-pic"
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h1 className="patient-name">Dr Sherley Abraham</h1>
                                                    <h5 className="physician">Physician</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <h1 className="patient-name">09:00 AM</h1>
                                                    <h5 className="physician">24 July,2019</h5>
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 className="problem">Problem of Visit</h5>
                                                    <h4 className="physician">Severe Hypertention</h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icons-align">
                                                        <PhoneForwardedIcon className="light-blue" />
                                                        <MailOutlineIcon className="mail" />
                                                        <LocationOnIcon className="light-blue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img
                                                src={maleIcon}
                                                className="profile-pic"
                                                alt="profile-pic"
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h1 className="patient-name">Turugut Alagoz</h1>
                                                    <h5 className="physician">Physician</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <h1 className="patient-name">09:00 AM</h1>
                                                    <h5 className="physician">24 July,2019</h5>
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 className="problem">Problem of Visit</h5>
                                                    <h4 className="physician">Essential Hypertention</h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="icons-align">
                                                        <PhoneForwardedIcon className="light-blue" />
                                                        <MailOutlineIcon className="mail" />
                                                        <LocationOnIcon className="light-blue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sam
